import React, { createContext, useContext, useState } from "react";

const ViewUserDropdownStateContext = createContext(undefined);
const ViewUserDropdownDispatchContext = createContext(undefined);

const ViewUserDropdownContextProvider = ({ children }) => {
  const [viewUserDropdown, setViewUserDropdown] = useState(false);

  return (
    <ViewUserDropdownStateContext.Provider value={viewUserDropdown}>
      <ViewUserDropdownDispatchContext.Provider value={setViewUserDropdown}>
        {children}
      </ViewUserDropdownDispatchContext.Provider>
    </ViewUserDropdownStateContext.Provider>
  );
};

export default ViewUserDropdownContextProvider;

export const useViewUserDropdownContext = () => {
  const viewUserDropdown = useContext(ViewUserDropdownStateContext);
  const setViewUserDropdown = useContext(ViewUserDropdownDispatchContext);

  if (viewUserDropdown === undefined || setViewUserDropdown === undefined)
    throw new Error("useViewUserDropdownContext must be used within the ViewUserDropdownContextProvider");

  return { viewUserDropdown, setViewUserDropdown };
};
