import React, { createContext, useContext, useState, useEffect } from "react";

import { onSnapshot, podsCollection } from "../firebase";
import { useAuthContext } from "./auth-context";

const PodsStateContext = createContext(undefined);
const PodsDispatchContext = createContext(undefined);

const PodsContextProvider = ({ children }) => {
  const { allUsers } = useAuthContext();
  const [pods, setPods] = useState(null);

  useEffect(() => {
    onSnapshot(podsCollection, (snapshot) => {
      const tempPods = [];
      snapshot.docs.map((doc) => {
        return tempPods.push(doc.data());
      });
      setPods(tempPods);
    });
  }, [allUsers]);

  return (
    <PodsStateContext.Provider value={pods}>
      <PodsDispatchContext.Provider value={setPods}>
        {children}
      </PodsDispatchContext.Provider>
    </PodsStateContext.Provider>
  );
};

export default PodsContextProvider;

export const usePodsContext = () => {
  const pods = useContext(PodsStateContext);
  const setPods = useContext(PodsDispatchContext);

  if (pods === undefined || setPods === undefined)
    throw new Error(
      "usePodsContext must be used within the PodsContextProvider"
    );

  return { pods, setPods };
};
