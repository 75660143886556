import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import Spinner from "../../Spinner";
import { FiDownload } from "react-icons/fi";
import { useAuthContext } from "../../../context/auth-context";
import { httpsCallable, functions } from "../../../firebase";
import { formatPriceNoDecimal, plansPriceObj } from "../../../helper";

const MainContainer = styled.div`
  width: 100%;
  height: 310px;
  background-color: white;
  text-align: left;
  padding: 24px;
  box-sizing: border-box;
`;

const SecTitleRow = styled.div`
  font-size: 16px;
  color: #3b3d50;
  opacity: 0.5;
  border-bottom: 1px solid #e6e8f2;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const SecTitle = styled.div``;

const SecTitleLink = styled.div`
  color: #3b3d50;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #1a1c29;
  }
`;

const MainContent = styled.div``;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e8f2;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const DateCol = styled.div`
  width: 100px;
`;

const PlanCol = styled.div`
  width: 150px;
`;

const AmountCol = styled.div`
  width: 90px;
`;

const DownloadCol = styled.div`
  width: 80px;
  text-align: right;
`;

const Icon = styled.div`
  font-size: 1.1rem;
  height: 21px;

  a {
    color: black;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { margin: 30 },
  header: { fontSize: 18, fontWeight: "bold", marginBottom: 20 },
  sectionTitle: { fontSize: 12, fontWeight: "bold" },
  topSec: {
    width: 220,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
});

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const Invoices = () => {
  const { user } = useAuthContext();

  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const history = useHistory();

  const formatDate = (tsVal) => {
    const date = new Date(tsVal * 1000);
    const dateStr = date.toDateString();
    return dateStr.slice(4, dateStr.length);
  };

  const getPlan = (price) => {
    const name = plansPriceObj[price].planName;
    const interval = plansPriceObj[price].interval;

    return name + " " + interval;
  };

  useEffect(() => {
    if (!user || !user.stripeCustomerId) return;

    const unsubscribe = async () => {
      try {
        const listInvoices = httpsCallable(functions, "listInvoices");

        const invoicesData = await listInvoices({
          customerId: user.stripeCustomerId,
        });

        setInvoices(invoicesData.data.invoices.data);

        const invoiceCount =
          invoicesData.data.invoices.data.length >= 3
            ? 3
            : invoicesData.data.invoices.data.length;

        let tempArr = [];

        for (let i = 0; i < invoiceCount; i++) {
          if (!invoicesData.data.invoices.data[i]) return;

          tempArr.push(invoicesData.data.invoices.data[i]);
        }

        setFilteredInvoices(tempArr);

        return { invoicesData: invoicesData.data };
      } catch (error) {
        console.log(error.message);
      }
    };

    unsubscribe();
  }, [user]);

  const MyDoc = ({ invoice }) => (
    <>
      {invoice && filteredInvoices[0] ? (
        <Document>
          <Page size="A4" style={styles.page}>
            <View>
              <Text
                style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}
              >
                Invoice
              </Text>
              <View
                style={{
                  width: 400,
                  flexDirection: "row",
                  marginBottom: 15,
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      marginBottom: 5,
                    }}
                  >
                    Invoice Number:
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      marginBottom: 5,
                    }}
                  >
                    Date:
                  </Text>
                </View>
                <View style={{ textAlign: "left" }}>
                  <Text style={{ fontSize: 14 }}>{invoice.number}</Text>
                  <Text style={{ fontSize: 14 }}>
                    {formatDate(invoice.status_transitions.paid_at)}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: 400,
                  flexDirection: "row",
                  marginBottom: 20,
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      marginBottom: 5,
                    }}
                  >
                    ContentHub
                  </Text>
                  <Text style={{ fontSize: 14 }}>12345 The Street</Text>
                  <Text style={{ fontSize: 14 }}>Los Angeles, CA 92010</Text>
                  <Text style={{ fontSize: 14 }}>United States</Text>
                  <Text style={{ fontSize: 14 }}>+1 800-700-6655</Text>
                  <Text style={{ fontSize: 14 }}>email.mail.com</Text>
                </View>

                <View style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      marginBottom: 5,
                    }}
                  >
                    Bill to
                  </Text>
                  <Text style={{ fontSize: 14 }}>{user.fullname}</Text>
                  <Text style={{ fontSize: 14 }}>{user.email}</Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 5,
                  marginBottom: 5,
                  borderBottomWidth: 2,
                  borderBottomStyle: "solid",
                  borderBottomColor: "gray",
                }}
              >
                <View>
                  <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                    Description
                  </Text>
                </View>

                <View>
                  <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                    Amount
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <View>
                  <Text style={{ fontSize: 14 }}>{getPlan(invoice.total)}</Text>
                </View>

                <View>
                  <Text style={{ fontSize: 14 }}>{`$${formatPriceNoDecimal(
                    invoice.total.toString()
                  )}`}</Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      ) : undefined}
    </>
  );

  return (
    <MainContainer>
      <SecTitleRow>
        <SecTitle>Invoices</SecTitle>
        <SecTitleLink onClick={() => history.push("/invoices")}>
          See All
        </SecTitleLink>
      </SecTitleRow>

      <MainContent>
        <ItemRow>
          <DateCol>Date</DateCol>
          <PlanCol>Plan</PlanCol>
          <AmountCol>Amount</AmountCol>
          <DownloadCol>Download</DownloadCol>
        </ItemRow>

        {filteredInvoices && filteredInvoices[0] ? (
          filteredInvoices.map((invoice) => {
            return (
              <ItemRow key={invoice.id}>
                <DateCol>
                  {formatDate(invoice.status_transitions.paid_at)}
                </DateCol>

                <PlanCol>
                  {user.subscriptionHistory.filter(
                    (item) => item.subscriptionId === invoice.subscription
                  )[0]
                    ? `${
                        user.subscriptionHistory.filter(
                          (item) => item.subscriptionId === invoice.subscription
                        )[0].planName
                      }  ${invoice.lines.data[0].plan.interval}ly`
                    : getPlan(invoice.total)}
                </PlanCol>

                <AmountCol>{`USD ${formatPriceNoDecimal(
                  invoice.total.toString()
                )}`}</AmountCol>
                <DownloadCol>
                  <Icon>
                    <PDFDownloadLink
                      document={<MyDoc invoice={invoice} />}
                      fileName={`invoice-${invoice.number}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "" : <FiDownload />
                      }
                    </PDFDownloadLink>
                  </Icon>
                </DownloadCol>
              </ItemRow>
            );
          })
        ) : (
          <LoadingContainer>
            <Spinner color="grey" height={50} width={50} />
          </LoadingContainer>
        )}
      </MainContent>
    </MainContainer>
  );
};

export default Invoices;
