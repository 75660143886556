import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { GrUpload } from "react-icons/gr";
import { useAuthContext } from "../../context/auth-context";
import { storage, setDoc, doc, usersCollection } from "../../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "@firebase/storage";

const MainContainer = styled.div`
  width: 100%;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const FileInput = styled.input`
  display: none;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 98px;
  cursor: pointer;
`;

const ImageLabel = styled.label``;

const UploadBtn = styled.div`
  background-color: #e6e8f2;
  width: 100%;
  height: 34px;
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  cursor: pointer;
  border-radius: 5px;
`;

const ImageNameBox = styled.div`
  width: 100%;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.div`
  font-size: 14px;
  color: #3b3d50;
  margin-right: 7px;
  transform: translateY(2px);
`;

const ProfileImage = ({setViewUpdateModal}) => {
  const { user } = useAuthContext();

  const [userImageUrl, setUserImageUrl] = useState(
    "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5"
  );
  const [fileName, setFileName] = useState();
  const [tempFileName, setTempFileName] = useState("-");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!user) return;
    if (!user.imageUrl) {
      setUserImageUrl(
        "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5"
      );
    } else {
      setUserImageUrl(user.imageUrl);
    }
  }, [user]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    setTempFileName(file.name);
  };

  const handleImageUpload = () => {
    const target = document.querySelector("#profileimage");

    const file = target.files[0];

    if (!file) return;

    const name =
      file.name.split(".")[0] +
      "-" +
      new Date().getTime() +
      "." +
      file.name.split(".")[1];

    setFileName(name);
    uploadImage(file);
  };

  const uploadImage = (file) => {
    const name =
      file.name.split(".")[0] +
      "-" +
      new Date().getTime() +
      "." +
      file.name.split(".")[1];

    if (!file) return;
    const storageRef = ref(storage, `/images/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    if (user.imageName) {
      // Create a reference to the file to delete
      const oldImageRef = ref(storage, `images/${user.imageName}`);

      // Delete the file
      deleteObject(oldImageRef)
        .then(() => {
          // File deleted successfully
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              setProgress(prog);
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                setProgress(0);
                document.querySelector("#profileimage").value = "";

                // add URL to the user

                const userRef = doc(usersCollection, user.id);
                await setDoc(
                  userRef,
                  {
                    imageUrl: url,
                    imageName: name,
                  },
                  { merge: true }
                );

                setTempFileName("-");
                setFileName("");
                setViewUpdateModal(true)
              });
            }
          );
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    } else {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgress(prog);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            setProgress(0);
            document.querySelector("#profileimage").value = "";

            // add URL to the user

            const userRef = doc(usersCollection, user.id);

            await setDoc(
              userRef,
              {
                imageUrl: url,
                imageName: name,
              },
              { merge: true }
            );

            setTempFileName("-");
            setFileName("");
            setViewUpdateModal(true)
          });
        }
      );
    }
  };

  return (
    <MainContainer>
      <SecTitle>Profile image</SecTitle>
      <FileInput type="file" id="profileimage" onChange={handleImageChange} />
      <ImageLabel htmlFor="profileimage">
        <ProfileImg src={userImageUrl} />
        <ImageNameBox>{tempFileName}</ImageNameBox>
      </ImageLabel>
      <UploadBtn onClick={handleImageUpload}>
        <Icon>
          <GrUpload />
        </Icon>
        Upload
      </UploadBtn>
    </MainContainer>
  );
};

export default ProfileImage;
