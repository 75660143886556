import React, { createContext, useContext, useState, useEffect } from "react";

import {
  onAuthStateChanged,
  auth,
  onSnapshot,
  doc,
  usersCollection,
  adminCollection,
  associatesCollection,
} from "../firebase";

const AuthContext = createContext(undefined);

function AuthContextProvider({ children }) {
  const [authUser, setAuthUser] = useState(null);
  const [user, setUser] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [allAssociates, setAllAssociates] = useState(null);
  const [adminDocs, setAdminDocs] = useState(null);
  const [logged, setLogged] = useState(true);
  const [associateLogged, setAssociateLogged] = useState(false);

  // Listen to auth state change (Firebase Auth)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        setAuthUser(user);
      },
      (error) => {
        console.log(error);
        setAuthUser(null);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  // Listen to user info update (Firestore)
  // Query user info
  useEffect(() => {
    if (!authUser) {
      setUser(null);
      return;
    }

    if (authUser) {
      setLogged(true);
    }

    const unsubscribe = onSnapshot(
      doc(usersCollection, authUser.uid),
      (doc) => {
        if (doc.exists()) {
          const userData = doc.data();

          setUser({
            id: doc.id,
            fullname: userData.name,
            company: userData.company,
            email: userData.email,
            stripeCustomerId: userData.stripeCustomerId,
            status: userData.status,
            price: userData.price,
            planName: userData.planName,
            interval: userData.interval,
            audiences: userData.audiences,
            role: userData.role,
            imageUrl: userData.imageUrl,
            imageName: userData.imageName,
            website: userData.website,
            address: userData.address,
            smAcc: userData.smAcc,
            moodBoard: userData.moodBoard,
            branding: userData.branding,
            subscriptionId: userData.subscriptionId,
            subscriptionHistory: userData.subscriptionHistory,
            assigned: userData.assigned,
          });
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [authUser]);

  useEffect(() => {
    if (!user) return;

    setAssociateLogged(false);

    if (user.role !== "client") {
      onSnapshot(usersCollection, (snapshot) => {
        const tempUsers = [];
        snapshot.docs.map((doc) => {
          return tempUsers.push(doc.data());
        });
        setAllUsers(tempUsers);
      });

      onSnapshot(associatesCollection, (snapshot) => {
        const tempAssociates = [];
        snapshot.docs.map((doc) => {
          return tempAssociates.push(doc.data());
        });
        setAllAssociates(tempAssociates);
      });
    }
  }, [user]);

  useEffect(() => {
    onSnapshot(adminCollection, (snapshot) => {
      const tempDocs = [];
      snapshot.docs.map((doc) => {
        return tempDocs.push(doc.data());
      });
      setAdminDocs(tempDocs);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        adminDocs,
        allUsers,
        allAssociates,
        authUser,
        user,
        setAuthUser,
        setUser,
        logged,
        setLogged,
        setAssociateLogged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;

export function useAuthContext() {
  const authContext = useContext(AuthContext);

  if (authContext === undefined)
    throw new Error("useAuthContext must be used within AuthContextProvider.");

  return { ...authContext };
}
