import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import { storage, setDoc, doc, usersCollection } from "../../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "@firebase/storage";
import { HiPlus } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";

const MainContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
`;

const FileTypeNames = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const SecRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }
`;

// ---------------------

const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  position: relative;
`;

const Img = styled.img`
  width: 200px;
  height: 200px;
  cursor: pointer;
`;

const ImgOverlayContainer = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
`;

const ImgOverLeftCol = styled.div`
  width: 50%;
`;

const ImgOverLeftColContent = styled.label`
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgOverRightCol = styled.div`
  width: 50%;
`;

const ImgOverRightColContent = styled.label`
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OverlayAddIcon = styled.div`
  font-size: 4rem;
  color: white;
  transform: translateY(3px);
`;

const OverlayRemoveIcon = styled.div`
  font-size: 3.2rem;
  color: red;
  transform: translateY(3px);
`;

const FileInput = styled.input`
  display: none;
`;

const MoodBoard = ({setViewUpdateModal}) => {
  const [imagesObj, setImagesObj] = useState({
    mbi1: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
    mbi2: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
    mbi3: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
    mbi4: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
    mbi5: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
    mbi6: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
    mbi7: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
    mbi8: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
  });
  const [progress, setProgress] = useState("");
  const [overlay, setOverlay] = useState({
    mbi1: {
      add: false,
      remove: false,
    },
    mbi2: {
      add: false,
      remove: false,
    },
    mbi3: {
      add: false,
      remove: false,
    },
    mbi4: {
      add: false,
      remove: false,
    },
    mbi5: {
      add: false,
      remove: false,
    },
    mbi6: {
      add: false,
      remove: false,
    },
    mbi7: {
      add: false,
      remove: false,
    },
    mbi8: {
      add: false,
      remove: false,
    },
  });

  const { user } = useAuthContext();

  useEffect(() => {
    if (!user) return;

    if (user && !user.moodBoard) {
      setImagesObj({
        mbi1: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
        mbi2: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
        mbi3: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
        mbi4: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
        mbi5: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
        mbi6: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
        mbi7: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
        mbi8: "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5",
      });
    } else if (user && user.moodBoard) {
      const imageItemList = [
        "mbi1",
        "mbi2",
        "mbi3",
        "mbi4",
        "mbi5",
        "mbi6",
        "mbi7",
        "mbi8",
      ];
      let tempImageObj = {};
      imageItemList.forEach((item) => {
        if (user.moodBoard[item] && user.moodBoard[item].thumbnail) {
          tempImageObj[item] = user.moodBoard[item].thumbnail;
        } else {
          tempImageObj[item] =
            "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5";
        }
      });
      setImagesObj({
        ...tempImageObj,
      });
    }
  }, [user, user.moodBoard]);

  const handleImageChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];

    const fileTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!file || !fileTypes.includes(file.type)) return;

    uploadFile(file, name);
  };

  const uploadFile = (file, name) => {
    if (!file || !user) return;

    const filename =
      file.name.split(".")[0] +
      "-" +
      new Date().getTime() +
      "." +
      file.name.split(".")[1];

    const fileType = file.name.split(".")[1];

    const storageRef = ref(storage, `/mbfiles/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    if (!user.moodBoard) {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgress(prog);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            setProgress(0);
            document.querySelector(`#${name}`).value = "";

            // add URL to the moodBoard

            const userRef = doc(usersCollection, user.id);

            await setDoc(
              userRef,
              {
                moodBoard: {
                  [name]: {
                    thumbnail:
                      fileType === "pdf"
                        ? "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fpdf.png?alt=media&token=811d3ae9-a36c-4516-8b26-dd949ca9be01"
                        : url,
                    imageUrl: url,
                    imageName: filename,
                  },
                },
              },
              { merge: true }
            );
            setViewUpdateModal(true)
          });
        }
      );
    } else if (
      (user.moodBoard && !user.moodBoard[name]) ||
      (user.moodBoard[name] && !user.moodBoard[name].imageName)
    ) {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgress(prog);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            setProgress(0);
            document.querySelector(`#${name}`).value = "";

            // add URL to the moodBoard

            const userRef = doc(usersCollection, user.id);

            await setDoc(
              userRef,
              {
                moodBoard: {
                  ...user.moodBoard,
                  [name]: {
                    thumbnail:
                      fileType === "pdf"
                        ? "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fpdf.png?alt=media&token=811d3ae9-a36c-4516-8b26-dd949ca9be01"
                        : url,
                    imageUrl: url,
                    imageName: filename,
                  },
                },
              },
              { merge: true }
            );
            setViewUpdateModal(true)
          });
        }
      );
    } else if (
      user.moodBoard &&
      user.moodBoard[name] &&
      user.moodBoard[name].imageName
    ) {
      // Create a reference to the file to delete
      const oldImageRef = ref(
        storage,
        `mbfiles/${user.moodBoard[name].imageName}`
      );

      // Delete the file
      deleteObject(oldImageRef)
        .then(() => {
          // File deleted successfully
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              setProgress(prog);
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                setProgress(0);
                document.querySelector(`#${name}`).value = "";

                // add URL to the user

                const userRef = doc(usersCollection, user.id);
                await setDoc(
                  userRef,
                  {
                    moodBoard: {
                      ...user.moodBoard,
                      [name]: {
                        thumbnail:
                          fileType === "pdf"
                            ? "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fpdf.png?alt=media&token=811d3ae9-a36c-4516-8b26-dd949ca9be01"
                            : url,
                        imageUrl: url,
                        imageName: filename,
                      },
                    },
                  },
                  { merge: true }
                );
                setViewUpdateModal(true)
              });
            }
          );
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    }
  };

  const handleRemoveImage = (itemName) => {
    const moodboard = user.moodBoard;

    if (!moodboard || !moodboard[itemName] || !moodboard[itemName].imageName)
      return;

    // Create a reference to the file to delete
    const oldImageRef = ref(
      storage,
      `mbfiles/${user.moodBoard[itemName].imageName}`
    );

    // Delete the file
    deleteObject(oldImageRef)
      .then(async () => {
        // File deleted successfully
        // remove item from moodboard object

        const userRef = doc(usersCollection, user.id);
        await setDoc(
          userRef,
          {
            moodBoard: {
              ...user.moodBoard,
              [itemName]: {},
            },
          },
          { merge: true }
        );
        setViewUpdateModal(true)
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.error(error);
      });
  };

  return (
    <MainContainer>
      <SecTitle htmlFor="mbi1">mood board</SecTitle>
      <FileTypeNames>*(JPEG, PNG, PDF)</FileTypeNames>

      <SecRow>
        <FileInput
          type="file"
          id="mbi1"
          name="mbi1"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi1} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi1: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi1: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi1.add ? (
                <ImgOverLeftColContent htmlFor="mbi1">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi1: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi1: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi1.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi1")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>

        <FileInput
          type="file"
          id="mbi2"
          name="mbi2"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi2} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi2: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi2: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi2.add ? (
                <ImgOverLeftColContent htmlFor="mbi2">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi2: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi2: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi2.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi2")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>

        <FileInput
          type="file"
          id="mbi3"
          name="mbi3"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi3} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi3: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi3: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi3.add ? (
                <ImgOverLeftColContent htmlFor="mbi3">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi3: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi3: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi3.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi3")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>

        <FileInput
          type="file"
          id="mbi4"
          name="mbi4"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi4} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi4: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi4: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi4.add ? (
                <ImgOverLeftColContent htmlFor="mbi4">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi4: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi4: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi4.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi4")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>
      </SecRow>

      <SecRow>
        <FileInput
          type="file"
          id="mbi5"
          name="mbi5"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi5} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi5: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi5: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi5.add ? (
                <ImgOverLeftColContent htmlFor="mbi5">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi5: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi5: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi5.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi5")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>

        <FileInput
          type="file"
          id="mbi6"
          name="mbi6"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi6} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi6: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi6: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi6.add ? (
                <ImgOverLeftColContent htmlFor="mbi6">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi6: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi6: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi6.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi6")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>

        <FileInput
          type="file"
          id="mbi7"
          name="mbi7"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi7} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi7: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi7: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi7.add ? (
                <ImgOverLeftColContent htmlFor="mbi7">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi7: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi7: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi7.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi7")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>

        <FileInput
          type="file"
          id="mbi8"
          name="mbi8"
          onChange={handleImageChange}
        />

        <ImageContainer>
          <Img src={imagesObj.mbi8} />
          <ImgOverlayContainer>
            <ImgOverLeftCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi8: {
                    add: true,
                    remove: false,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi8: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi8.add ? (
                <ImgOverLeftColContent htmlFor="mbi8">
                  <OverlayAddIcon>
                    <HiPlus />
                  </OverlayAddIcon>
                </ImgOverLeftColContent>
              ) : undefined}
            </ImgOverLeftCol>

            <ImgOverRightCol
              onMouseEnter={() =>
                setOverlay({
                  ...overlay,
                  mbi8: {
                    add: false,
                    remove: true,
                  },
                })
              }
              onMouseLeave={() =>
                setOverlay({
                  ...overlay,
                  mbi8: {
                    add: false,
                    remove: false,
                  },
                })
              }
            >
              {overlay.mbi8.remove ? (
                <ImgOverRightColContent
                  onClick={() => handleRemoveImage("mbi8")}
                >
                  <OverlayRemoveIcon>
                    <FaTimes />
                  </OverlayRemoveIcon>
                </ImgOverRightColContent>
              ) : undefined}
            </ImgOverRightCol>
          </ImgOverlayContainer>
        </ImageContainer>
      </SecRow>
    </MainContainer>
  );
};

export default MoodBoard;
