import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function Checkout({ user, sub }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url:
        //   "https://contentdrive-6147b.web.app/subscriptionconfirmation",
        return_url: "http://localhost:3000/subscriptionupdateconfirmation",
      },
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    }

    setLoading(false);
  }

  return (
    <div className="checkout">
      <form id="update-payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />

        <button disabled={loading || !stripe || !elements} id="submit">
          <span id="button-text">
            {loading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Subscribe"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
