import React, { useState, useEffect } from "react";

import styled from "styled-components";
import Spinner from "../components/Spinner";
import CompanyNav from "../components/nav/CompanyNav";
import { useAuthContext } from "../context/auth-context";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import { httpsCallable, functions } from "../firebase";
import PaymentUpdateConfirmation from "../components/update-subscription/PaymentUpdateConfirmation";

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4px);
  margin-top: 20px;
`;

const PageLeftCol = styled.div`
  width: 300px;
  height: calc(100vh - 80px);
  position: relative;
`;

const PageRightCol = styled.div`
  width: calc(100% - 300px);
  margin: 15px;

  @media (max-width: 990px) {
    margin-left: 20px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  text-align: center;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const UpdatePaymentConfirmation = () => {
  const { user } = useAuthContext();
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const [setupIntentId, setSetupIntentId] = useState();
  const [paymentUpdated, setPaymentUpdated] = useState(false);

  setViewAccNav(true);
  setViewAdminNav(false);

  // Retrieve the "setup_intent" query parameter appended to
  // return_url by Stripe.js
  useEffect(() => {
    const setupIntent = new URLSearchParams(window.location.search).get(
      "setup_intent"
    );

    if (setupIntent) {
      setSetupIntentId(setupIntent);

      // // Remove query string
      // window.history.pushState({}, "", "/updatepaymentconfirmation");
    }
  }, []);

  // Update subscription once the setup intent id is available
  useEffect(() => {
    if (setupIntentId) {
      const updateMethodSubscribtion = httpsCallable(
        functions,
        "updateMethodSubscribtion"
      );

      updateMethodSubscribtion({
        setupIntentId,
        customerId: user.stripeCustomerId,
      })
        .then((result) => {
          setPaymentUpdated(true);
        })
        .catch((error) => {
          console.log("error: -->", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupIntentId]);

  return (
    <PageContainer>
      <PageLeftCol>
        <CompanyNav />
      </PageLeftCol>
      <PageRightCol>
        <ContentContainer>
          {paymentUpdated ? (
            <PaymentUpdateConfirmation />
          ) : (
            <LoadingContainer>
              <Spinner color="grey" height={50} width={50} />
            </LoadingContainer>
          )}
        </ContentContainer>
      </PageRightCol>
    </PageContainer>
  );
};

export default UpdatePaymentConfirmation;
