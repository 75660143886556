import React from "react";

import styled from "styled-components";

const PageMainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  overflow: scroll;
  overflow-x: hidden;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  text-align: left;
  padding: 24px;
  box-sizing: border-box;

`;

const ContentContainer = styled.div`
  width: 100%;

  max-width: 820px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 990px) {
    max-width: 410px;
    grid-template-columns: 1fr;
  }
`

const TopRow = styled.div`
  width: 100%;
  height: 100px;
`;

const BottomRow = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const ItemBox = styled.div`
width: 400px;
height: 150px;
display: flex;
justify-content: center;
align-items: center;
color: #6371de;
  border: 1px solid #e6e8f2;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e6e8f2;
    color: #f17855;
    box-shadow: 0px 0px 20px rgba(59, 61, 80, 0.16);
  }
`
const ItemTitle = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 27px;
  text-transform: uppercase;

`

const AudienceIntro = ({setCurrentView}) => {

  return (
    <PageMainContainer>
      <MainContainer>
        <TopRow>
        <SecTitle>audiences</SecTitle>
        </TopRow>
        <BottomRow>
        <ContentContainer>
        <ItemBox onClick={() => setCurrentView('newAudience')}>
          <ItemTitle>New Audience</ItemTitle>

        </ItemBox>

        <ItemBox onClick={() => setCurrentView('savedAudience')}>
          <ItemTitle>Saved Audiences</ItemTitle>

        </ItemBox>
        </ContentContainer>
        </BottomRow>
      </MainContainer>


    </PageMainContainer>
  );
};

export default AudienceIntro;
