import React, { useState, useEffect } from "react";

import axios from "axios";
import styled from "styled-components";
import { storage, setDoc, doc, ordersCollection } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { useOrdersContext } from "../../context/orders-context";
import { FiDownload } from "react-icons/fi";
import { IoCaretBack } from "react-icons/io5";
import { submissionsArrayReversed } from "../../helper";

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const BackBtn = styled.div`
  width: 90px;
  height: 35px;
  background-color: #f17855;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 20px;
`;

const BtnText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
`;

const BackIcon = styled.div`
  display: inline-block;
  transform: translateY(2px);
`;

const DescriptionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftCol = styled.div`
  width: 50%;
  padding: 15px;
`;

const RightCol = styled.div`
  width: 50%;
  padding: 15px;
  border-left: 1px solid #6371de;
`;

const OrderDescription = styled.div`
  width: 100%;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  margin-bottom: 5px;

  button {
    width: 100%;
    height: 35px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6371de;
    border-radius: 5px;
    color: white;
    cursor: pointer;

    :hover {
      border: 1px solid #6371de;
      background-color: white;
      color: black;
    }
  }
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 15px;
`;

const TitleValue = styled.span`
  font-weight: normal;
`;

const UploadForm = styled.form`
  button {
    margin-top: 15px;
  }
`;

const FileInput = styled.input`
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const ProgNote = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
`;

const AdminNoteContainer = styled.div`
  width: 30vw;
  min-width: 500px;
  margin: 0 auto;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgb(50 50 93 / 10%),
    0px 2px 5px 0px rgb(50 50 93 / 10%), 0px 1px 1.5px 0px rgb(0 0 0 / 7%);
  border-radius: 7px;
  padding: 40px;
`;

const SubmissionItemRow = styled.div`
  border-bottom: 1px solid #e6e8f2;
  margin-bottom: 10px;

  &:nth-last-child(1) {
    border: none;
  }
`;

const SubDetailContainer = styled.div`
  width: calc((100% - 30px) / 2);
  text-align: left;
`;

const SubDetailRightContainer = styled.div`
  width: 30px;
  text-align: right;
`;

const FileTypeSub = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

const Icon = styled.div`
  font-size: 1.3rem;

  .downloadicon {
    cursor: pointer;
  }
`;

const AdminNote = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #e4e6f2;
  border-radius: 5px;
`;

const BtnContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const SubBtn = styled.div`
  width: 100%;
  height: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const AdminOrderDetail = ({
  orderId,
  company,
  currentAssociateId,
  setHubView,
}) => {
  const { orders } = useOrdersContext();
  const [orderSubmissions, setOrderSubmissions] = useState([]);
  const [isAssigned, setIsAssigned] = useState(true);

  const [order, setOrder] = useState();
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [adminNote, setAdminNote] = useState("");
  const [adminUploadNote, setAdminUploadNote] = useState("");

  useEffect(() => {
    if (!orders || !orderId) return;

    const filteredOrder = orders.filter((item) => item.id === orderId);

    setOrder(filteredOrder[0]);
  }, [orders, orderId]);

  const formHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];

    if (!file) return;

    const name = file.name.split(".")[0] + "-" + new Date().getTime();
    setFileName(name);
    uploadFile(file);
  };

  const uploadFile = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/files/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          setProgress(0);
          document.querySelector("#fileinput").value = "";

          // add URL to the order

          const orderRef = doc(ordersCollection, order.id);
          await setDoc(
            orderRef,
            {
              submissions: [
                ...order.submissions,
                {
                  submittedBy: "admin",
                  type: "file",
                  name: fileName,
                  url: url,
                  uploadNote: adminUploadNote,
                  submittedAt: new Date(),
                },
              ],
              status: "delivered",
              updatedAt: new Date(),
            },
            { merge: true }
          );

          setAdminUploadNote("");
          setFileName("");
        });
      }
    );
  };

  useEffect(() => {
    if (!order) return;

    setOrderSubmissions(submissionsArrayReversed(order.submissions));
  }, [order]);

  const handleDownload = (url, name) => {
    axios({
      url: `${url}`,
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `submission.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleNoteChange = (e) => {
    setAdminNote(e.target.value);
  };

  const handleUploadNoteChange = (e) => {
    setAdminUploadNote(e.target.value);
  };

  const handleAddNote = async () => {
    const orderRef = doc(ordersCollection, order.id);
    await setDoc(
      orderRef,
      {
        submissions: [
          ...order.submissions,
          {
            submittedBy: "admin",
            type: "adminnote",
            note: adminNote,
            submittedAt: new Date(),
          },
        ],
        updatedAt: new Date(),
      },
      { merge: true }
    );

    setAdminNote("");
  };

  useEffect(() => {
    if (
      !company ||
      !company.assigned ||
      !company.assigned.associates ||
      !currentAssociateId
    )
      return;

    setIsAssigned(false);
    setIsAssigned(company.assigned.associates.includes(currentAssociateId));
  }, [company, currentAssociateId]);

  return (
    <MainContainer>
      <BackBtn onClick={() => setHubView("orders")}>
        <BtnText>
          <BackIcon>
            <IoCaretBack />
          </BackIcon>
          Back
        </BtnText>
      </BackBtn>

      {/* Order Details */}
      <OrderDescription>
        <DescriptionRow>
          <LeftCol>
            <Title>
              Topic: <TitleValue>{!order ? false : order.topic}</TitleValue>
            </Title>
            <Title>
              Description:{" "}
              <TitleValue>{!order ? false : order.description}</TitleValue>
            </Title>
            <Title>
              Type: <TitleValue>{!order ? false : order.type}</TitleValue>
            </Title>
          </LeftCol>

          <RightCol>
            <Title>
              Status:{" "}
              <TitleValue>
                {!order
                  ? false
                  : order.status === "inprogress"
                  ? "In Progress"
                  : "Delivered"}
              </TitleValue>
            </Title>
            <Title>
              Date Created:{" "}
              <TitleValue>
                {!order ? false : order.createdAt.toDate().toDateString()}
              </TitleValue>
            </Title>
            <Title>
              Last Updated:{" "}
              <TitleValue>
                {!order || !order.updatedAt
                  ? false
                  : order.updatedAt.toDate().toDateString()}
              </TitleValue>
            </Title>
            <Title>
              Client: <TitleValue>{!order ? false : order.client}</TitleValue>
            </Title>
            <Title>
              Audience Name:{" "}
              <TitleValue>
                {!order ? false : order.audience.audiencename}
              </TitleValue>
            </Title>
            <Title>
              Audience Age:{" "}
              <TitleValue>
                {!order
                  ? false
                  : order.audience.age.map((agegroup) => {
                      return (
                        <TitleValue key={agegroup}>{agegroup} </TitleValue>
                      );
                    })}
              </TitleValue>
            </Title>
            <Title>
              Industry:{" "}
              <TitleValue>
                {!order ? false : order.audience.industry}
              </TitleValue>
            </Title>
            <Title>
              Departments:{" "}
              <TitleValue>
                {!order ? false : order.audience.departments}
              </TitleValue>
            </Title>
            <Title>
              Seniority:{" "}
              <TitleValue>
                {!order ? false : order.audience.seniority}
              </TitleValue>
            </Title>
            <Title>
              Job Title:{" "}
              <TitleValue>
                {!order ? false : order.audience.jobtitle}
              </TitleValue>
            </Title>
          </RightCol>
        </DescriptionRow>
      </OrderDescription>

      {/* Upload Doc */}

      {isAssigned ? (
        <OrderDescription>
          <UploadForm onSubmit={formHandler}>
            <Title>Choose File: (PDF)</Title>
            <FileInput type="file" id="fileinput" />

            <Title>Note:</Title>

            <AdminNote
              onChange={handleUploadNoteChange}
              value={adminUploadNote}
            />

            <button type="submit">Upload</button>

            <ProgNote>Uploaded {progress}%</ProgNote>
          </UploadForm>
        </OrderDescription>
      ) : undefined}

      {/* Add Admin Note */}

      {isAssigned ? (
        <OrderDescription>
          <AdminNoteContainer>
            <Title>Note:</Title>

            <AdminNote onChange={handleNoteChange} value={adminNote} />

            <BtnContainer>
              <SubBtn onClick={handleAddNote}>Submit</SubBtn>
            </BtnContainer>
          </AdminNoteContainer>
        </OrderDescription>
      ) : undefined}

      {/* Listing Submissions */}

      {!order ? (
        false
      ) : orderSubmissions.length > 0 ? (
        <OrderDescription>
          {orderSubmissions.map((subItem) => {
            if (subItem.type === "file") {
              return (
                <SubmissionItemRow>
                  <FileTypeSub key={subItem.name}>
                    <Title>
                      Submitted By:{" "}
                      <TitleValue>{subItem.submittedBy}</TitleValue>
                    </Title>
                    <Title>
                      Date:{" "}
                      <TitleValue>
                        {subItem.submittedAt.toDate().toDateString()}
                      </TitleValue>
                    </Title>
                    <Icon>
                      <FiDownload
                        className="downloadicon"
                        onClick={() =>
                          handleDownload(subItem.url, subItem.name)
                        }
                      />
                    </Icon>
                  </FileTypeSub>
                  <FileTypeSub>
                    <Title>
                      Note: <TitleValue>{subItem.uploadNote}</TitleValue>
                    </Title>
                  </FileTypeSub>
                </SubmissionItemRow>
              );
            } else if (subItem.type === "revision") {
              return (
                <SubmissionItemRow>
                  <FileTypeSub key={subItem.name}>
                    <SubDetailContainer>
                      <Title>
                        Submitted By:{" "}
                        <TitleValue>{subItem.submittedBy}</TitleValue>
                      </Title>
                    </SubDetailContainer>
                    <SubDetailContainer>
                      <Title>
                        Date:{" "}
                        <TitleValue>
                          {subItem.submittedAt.toDate().toDateString()}
                        </TitleValue>
                      </Title>
                    </SubDetailContainer>

                    <SubDetailRightContainer>
                      <Icon>
                        {/* <FiDownload
                      className="downloadicon"
                      onClick={() => handleDownload(subItem.url, subItem.name)}
                    /> */}
                        <div className="downloadicon"></div>
                      </Icon>
                    </SubDetailRightContainer>
                  </FileTypeSub>
                  <FileTypeSub>
                    <Title>
                      Note: <TitleValue>{subItem.note}</TitleValue>
                    </Title>
                  </FileTypeSub>
                </SubmissionItemRow>
              );
            } else if (subItem.type === "adminnote") {
              return (
                <SubmissionItemRow>
                  <FileTypeSub key={subItem.name}>
                    <SubDetailContainer>
                      <Title>
                        Submitted By:{" "}
                        <TitleValue>{subItem.submittedBy}</TitleValue>
                      </Title>
                    </SubDetailContainer>
                    <SubDetailContainer>
                      <Title>
                        Date:{" "}
                        <TitleValue>
                          {subItem.submittedAt.toDate().toDateString()}
                        </TitleValue>
                      </Title>
                    </SubDetailContainer>

                    <SubDetailRightContainer>
                      <Icon>
                        {/* <FiDownload
                      className="downloadicon"
                      onClick={() => handleDownload(subItem.url, subItem.name)}
                    /> */}
                        <div className="downloadicon"></div>
                      </Icon>
                    </SubDetailRightContainer>
                  </FileTypeSub>
                  <FileTypeSub>
                    <Title>
                      Note: <TitleValue>{subItem.note}</TitleValue>
                    </Title>
                  </FileTypeSub>
                </SubmissionItemRow>
              );
            }
          })}
        </OrderDescription>
      ) : undefined}
    </MainContainer>
  );
};

export default AdminOrderDetail;
