import React from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { AiFillPlusCircle } from "react-icons/ai";

const MainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const MainRow = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const RequestBtn = styled.div`
  width: 176px;
  height: 50px;
  background-color: #f17855;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  cursor: pointer;
`;

const BtnText = styled.div`
  color: white;
  font-weight: bold;
  font-size: 16px;
`;

const Icon = styled.div`
  font-size: 24px;
  color: white;
  transform: translateY(2px);
`;

const LeftCol = styled.div``;

const RightCol = styled.div``;

const NewRequestRow = () => {
  const history = useHistory();

  return (
    <MainContainer>
      <MainRow>
        <LeftCol></LeftCol>
        <RightCol>
          <RequestBtn onClick={() => history.push('/newrequest')}>
            <Icon>
              <AiFillPlusCircle />
            </Icon>
            <BtnText>New Request</BtnText>
          </RequestBtn>
        </RightCol>
      </MainRow>
    </MainContainer>
  );
};

export default NewRequestRow;
