import React from "react";

import styled from "styled-components";
import Charts from "../components/dashboard-sections/Charts";
import SignedOutDashboard from "../components/signed-out-dashboard/SignedOutDashboard";
import TestForm from "../components/signed-out-dashboard/TestForm";

const Back = styled.div`
  position: relative;
  opacity: 0.3;
`;

const Front = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Test = () => {
  return (
    <>
      {/* <Back>
        <SignedOutDashboard />
      </Back>
      <Front>
        <TestForm />
      </Front> */}

      <Charts />
    </>
  );
};

export default Test;
