import React, { useCallback, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";

import {
  getAuth,
  createUserWithEmailAndPassword,
  serverTimestamp,
  doc,
  setDoc,
  associatesCollection,
  adminCollection,
} from "../../firebase";

const FormContainer = styled.div`
  width: 95%;
  max-width: 600px;
  background-color: white;
  padding: 50px;
  border-radius: 5px;
  margin: auto;
  border: 1px solid #e4e6f2;

  form {
    min-width: 100%;
    box-sizing: border-box;
    box-shadow: none;
  }
`;

const FormTitle = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  color: #6371de;
  margin-bottom: 40px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  form {
    padding-top: 20px;
  }
`;

const Input = styled.input`
  height: 37px;
  width: 100%;
  max-width: 520px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: 1px solid gray;
`;

const MNItemGetStartedContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MNItemGetStarted = styled.button`
  width: 183px;
  height: 50px;
  background-color: #6371de;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  border: none;
  margin-top: 10px;
  cursor: pointer;
`;

const LoginLinkContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const LoginText = styled.div``;

const LoginLink = styled.span`
  margin-left: 5px;
  text-decoration: underline;
  color: #6371de;
  cursor: pointer;
`;

const SignupForm = ({ setSignView }) => {
  const { adminDocs } = useAuthContext();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { email, password, fullname } = e.target.elements;

    const adminRolesDocs = adminDocs[0].roles;

    if (adminRolesDocs.filter((role) => role.email === email.value).length < 1)
      return;

    const pendingAssociate = adminRolesDocs.filter(
      (role) => role.email === email.value
    )[0];

    const auth = getAuth();

    try {
      await createUserWithEmailAndPassword(
        auth,
        email.value,
        password.value
      ).then(async (userCredential) => {
        const user = userCredential.user;

        if (user) {
          // Add user to firestore

          try {
            const associatesRef = doc(associatesCollection, user.uid);
            await setDoc(associatesRef, {
              id: userCredential.user.uid,
              name: fullname.value,
              email: email.value,
              createdAt: serverTimestamp(),
              role: pendingAssociate.role,
              assigned: [],
            });

            if (associatesRef.id) {
              // updated adminDoc item status

              pendingAssociate.status = "active";
              pendingAssociate.id = associatesRef.id;
              const rolesRef = doc(adminCollection, "roles");
              await setDoc(
                rolesRef,
                {
                  roles: [...adminRolesDocs],
                },
                { merge: true }
              );
            }

            setSignView("signin");
          } catch (error) {
            alert(error.message);
          }
        }
      });
    } catch (e) {
      alert(e.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContainer>
      <FormTitle>Create Account</FormTitle>

      <FormContent>
        <form onSubmit={handleSubmit}>
          <Input name="fullname" placeholder="Full Name*" type="text" />
          <Input name="email" placeholder="Email*" type="email" />
          <Input name="password" placeholder="Password*" type="password" />
          <MNItemGetStartedContainer>
            <MNItemGetStarted type="submit">Create Account</MNItemGetStarted>
          </MNItemGetStartedContainer>
        </form>
      </FormContent>

      <LoginLinkContainer>
        <LoginText>
          Have an account?
          <LoginLink
            onClick={() => {
              setSignView("signin");
            }}
          >
            Login
          </LoginLink>
        </LoginText>
      </LoginLinkContainer>
    </FormContainer>
  );
};

export default SignupForm;
