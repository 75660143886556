import React from "react";

import styled from "styled-components";

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: #f3f3f338;
`;

const MainContent = styled.div`
  position: fixed;
  top: calc(100vh / 2 - 70px);
  left: calc(100vw / 2 - 79px);
  margin-top: 20px;
  padding: 20px;
  margin: auto;
  background-color: #6371de;
  color: white;
  font-weight: bold;
  border-radius: 5px;
`;

const ProfileUpdated = ({ tilte }) => {
  return (
    <MainContainer>
      <MainContent>Profile Updated!</MainContent>
    </MainContainer>
  );
};

export default ProfileUpdated;
