import React, { useState, useEffect } from "react";

import styled from "styled-components";

const ProfileImage = ({ logoUrl }) => {
  const [userImageUrl, setUserImageUrl] = useState(
    "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5"
  );

  useEffect(() => {
    if (!logoUrl) {
      setUserImageUrl(
        "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5"
      );
    } else {
      setUserImageUrl(logoUrl);
    }
  }, [logoUrl]);

  const ProfileImg = styled.img`
    width: 100px;
    height: 98px;
    cursor: pointer;
  `;

  return <ProfileImg src={userImageUrl} />;
};

export default ProfileImage;
