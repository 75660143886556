import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import Spinner from "../../Spinner";
import { useAuthContext } from "../../../context/auth-context";
import { httpsCallable, functions } from "../../../firebase";
import { formatPriceNoDecimal } from "../../../helper";

const MainContainer = styled.div`
  width: 100%;
  height: 310px;
  background-color: white;
  text-align: center;
  padding: 24px;
  box-sizing: border-box;
`;

const SecTitleRow = styled.div`
  font-size: 16px;
  color: #3b3d50;
  opacity: 0.5;
  border-bottom: 1px solid #e6e8f2;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const SecTitle = styled.div``;

const SecSubTitle = styled.div`
  font-size: 16px;
  color: #3b3d50;
  text-align: left;
  margin-bottom: 15px;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const ItemTitle = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  opacity: 0.5;
`;

const ItemValue = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  opacity: 0.5;
`;

const UpdateBtn = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #f17855;
  border-radius: 5px;
  cursor: pointer;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const PaymentCard = () => {
  const { user } = useAuthContext();

  const [currentCard, setCurrentCard] = useState();


  const history = useHistory();



  useEffect(() => {
    if (!user || !user.stripeCustomerId) return;

    const unsubscribe = async () => {
      try {
        const listPaymentMethods = httpsCallable(
          functions,
          "listPaymentMethods"
        );

        const paymentMethods = await listPaymentMethods({
          customerId: user.stripeCustomerId,
        });

        setCurrentCard(paymentMethods.data.paymentMethods.data[0].card);

        return {
          paymentMethods: paymentMethods.data.paymentMethods.data[0].card,
        };
      } catch (error) {
        console.log(error.message);
      }
    };

    unsubscribe();
  }, [user]);


  return (
    <MainContainer>
      <SecTitleRow>
        <SecTitle>Billing Info</SecTitle>
      </SecTitleRow>
      <SecSubTitle>Current Card</SecSubTitle>
      {currentCard ? (
        <>
          {" "}
          <ItemRow>
            <ItemTitle>Type of card:</ItemTitle>
            <ItemValue>{currentCard.brand}</ItemValue>
          </ItemRow>
          <ItemRow>
            <ItemTitle>Last 4 digits:</ItemTitle>
            <ItemValue>{currentCard.last4}</ItemValue>
          </ItemRow>
          <ItemRow>
            <ItemTitle>Expiration:</ItemTitle>
            <ItemValue>{`${currentCard.exp_month} / ${currentCard.exp_year}`}</ItemValue>
          </ItemRow>
          <ItemRow>
            <ItemTitle>Country:</ItemTitle>
            <ItemValue>{currentCard.country}</ItemValue>
          </ItemRow>
          <ItemRow>
            <UpdateBtn onClick={() => history.push('/updatepayment')}>Update Billing Info</UpdateBtn>
          </ItemRow>{" "}
        </>
      ) : (
        <LoadingContainer>
          <Spinner color="grey" height={50} width={50} />
        </LoadingContainer>
      )}
    </MainContainer>
  );
};

export default PaymentCard;
