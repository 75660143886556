import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { BiSave } from "react-icons/bi";
import { industries } from "../../helper";
import { usersCollection, doc, setDoc } from "../../firebase";
import { useAuthContext } from "../../context/auth-context";

const PageMainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  height: 760px;
  overflow: scroll;
  overflow-x: hidden;
`;

const MainContainer = styled.div`
  width: 100%;
  background-color: white;
  text-align: left;
  padding: 24px;
  box-sizing: border-box;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const RequestBtn = styled.div`
  width: 176px;
  height: 50px;
  background-color: #f17855;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  cursor: pointer;

  &.disabledAddAudience {
    background-color: gray;
    cursor: not-allowed;
  }
`;

const BtnText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
`;

const LabelItem = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
`;

const InputItem = styled.input`
  height: 35px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
  color: #3b3d50;
`;

const AgeRow = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const AgeItem = styled.div`
  display: flex;
  margin-right: 20px;
`;

const AgeCheckboxContainer = styled.div``;

const AgeCheckbox = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
`;

const AgeCheckboxSpan = styled.span``;

const AgeLabel = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

const SelectInput = styled.select`
  height: 35px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  font-size: 14px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
  color: #3b3d50;
  cursor: pointer;
`;

const ErrContainer = styled.div`
  margin: auto;
  margin-top: -15px;
  margin-bottom: 15px;
  height: 20px;
  text-align: right;
`;

const ErrMsg = styled.div`
  margin: auto;
  color: red;

  &.hide {
    display: none;
  }
`;

const SaveBtnContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const SaveBtn = styled.div`
  width: 184px;
  height: 50px;

  background: #6371de;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  cursor: pointer;
`;

const SaveBtnText = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
`;

const SaveBtnIcon = styled.div`
  color: white;
  font-size: 1.2rem;
  transform: translateY(2px);
`;

const EditAudienceView = ({
  setCurrentView,
  savedAudienceData,
  setSavedAudienceData,
}) => {
  const { user } = useAuthContext();
  const [newAudience, setNewAudience] = useState({
    id: +new Date(),
    audiencename: "",
    age: [],
    departments: "",
    industry: "",
    jobtitle: "",
    seniority: "",
  });

  const [formErr, setFormErr] = useState(false);
  const [formUpdateErr, setFormUpdateErr] = useState(false);

  const handleChange = (e) => {
    setNewAudience({
      ...newAudience,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgeSelect = () => {
    let ageArr = [];

    document.querySelectorAll(".audAge").forEach((age) => {
      if (age.checked) {
        ageArr.push(age.name);
      }
    });

    setNewAudience({
      ...newAudience,
      age: ageArr,
    });
  };

  const clearAllValues = () => {
    setNewAudience({
      id: +new Date(),
      audiencename: "",
      age: [],
      departments: "",
      industry: "",
      jobtitle: "",
      seniority: "",
    });

    setSavedAudienceData();

    document.querySelectorAll(".audAge").forEach((ageItem) => {
      ageItem.checked = false;
    });
  };

  const updateAudience = async () => {
    if (!savedAudienceData) return;

    if (
      !newAudience.id ||
      !newAudience.audiencename ||
      newAudience.age.length < 1 ||
      !newAudience.departments ||
      !newAudience.industry ||
      !newAudience.jobtitle ||
      !newAudience.seniority
    ) {
      setFormErr(true);
      return;
    } else if (
      newAudience.age.length === savedAudienceData.age.length &&
      newAudience.age.length ===
        newAudience.age.filter((element) =>
          savedAudienceData.age.includes(element)
        ).length &&
      newAudience.audiencename === savedAudienceData.audiencename &&
      newAudience.departments === savedAudienceData.departments &&
      newAudience.industry === savedAudienceData.industry &&
      newAudience.jobtitle === savedAudienceData.jobtitle &&
      newAudience.seniority === savedAudienceData.seniority
    ) {
      setFormUpdateErr(true);
      return;
    } else {
      const oldAudienceArr = user.audiences;

      oldAudienceArr.splice(
        oldAudienceArr.findIndex((item) => item.id === savedAudienceData.id),
        1
      );

      const userRef = doc(usersCollection, user.id);

      await setDoc(
        userRef,
        {
          audiences: [...oldAudienceArr, newAudience],
        },
        { merge: true }
      );

      clearAllValues();
      setCurrentView("savedAudience");
    }
  };

  useEffect(() => {
    if (!savedAudienceData) return;

    setNewAudience({
      id: savedAudienceData.id,
      audiencename: savedAudienceData.audiencename,
      age: savedAudienceData.age,
      departments: savedAudienceData.departments,
      industry: savedAudienceData.industry,
      jobtitle: savedAudienceData.jobtitle,
      seniority: savedAudienceData.seniority,
    });

    document.querySelectorAll(".audAge").forEach((ageItem) => {
      ageItem.checked = false;
    });

    if (savedAudienceData.age.length > 0) {
      savedAudienceData.age.forEach((ageItem) => {
        document.getElementById(ageItem).checked = true;
      });
    }
  }, [savedAudienceData]);

  useEffect(() => {
    if (formErr) {
      document.querySelector("#error").classList.remove("hide");
      setTimeout(() => {
        document.querySelector("#error").classList.add("hide");
        setFormErr(false);
      }, 3000);
    }
  }, [formErr]);

  useEffect(() => {
    if (formUpdateErr) {
      document.querySelector("#updateerror").classList.remove("hide");
      setTimeout(() => {
        document.querySelector("#updateerror").classList.add("hide");
        setFormUpdateErr(false);
      }, 3000);
    }
  }, [formUpdateErr]);

  return (
    <PageMainContainer>
      <MainContainer>
        <TopRow>
          <SecTitle>edit audience</SecTitle>
          <RequestBtn
            onClick={() => {
              clearAllValues();
              setCurrentView("savedAudience");
            }}
          >
            <BtnText>Cancel</BtnText>
          </RequestBtn>
        </TopRow>

        <ContentContainer>
          <LabelItem>Audience Name</LabelItem>
          <InputItem
            onChange={handleChange}
            name="audiencename"
            value={newAudience.audiencename}
          />

          <LabelItem>Age</LabelItem>
          <AgeRow>
            <AgeItem>
              <AgeCheckboxContainer>
                <AgeCheckbox
                  onChange={handleAgeSelect}
                  className="audAge"
                  name="18-24"
                  id="18-24"
                  type="checkbox"
                />
                <AgeCheckboxSpan className="span"></AgeCheckboxSpan>
              </AgeCheckboxContainer>
              <AgeLabel>18-24</AgeLabel>
            </AgeItem>
            <AgeItem>
              <AgeCheckboxContainer>
                <AgeCheckbox
                  onChange={handleAgeSelect}
                  className="audAge"
                  name="25-34"
                  id="25-34"
                  type="checkbox"
                />
                <AgeCheckboxSpan></AgeCheckboxSpan>
              </AgeCheckboxContainer>
              <AgeLabel>25-34</AgeLabel>
            </AgeItem>
            <AgeItem>
              <AgeCheckbox
                onChange={handleAgeSelect}
                className="audAge"
                name="35-44"
                id="35-44"
                type="checkbox"
              />
              <AgeLabel>35-44</AgeLabel>
            </AgeItem>
            <AgeItem>
              <AgeCheckbox
                onChange={handleAgeSelect}
                className="audAge"
                name="45-54"
                id="45-54"
                type="checkbox"
              />
              <AgeLabel>45-54</AgeLabel>
            </AgeItem>
            <AgeItem>
              <AgeCheckbox
                onChange={handleAgeSelect}
                className="audAge"
                name="55-64"
                id="55-64"
                type="checkbox"
              />
              <AgeLabel>55-64</AgeLabel>
            </AgeItem>
            <AgeItem>
              <AgeCheckbox
                onChange={handleAgeSelect}
                className="audAge"
                name="65-74"
                id="65-74"
                type="checkbox"
              />
              <AgeLabel>65-74</AgeLabel>
            </AgeItem>
          </AgeRow>

          <LabelItem>Industry</LabelItem>
          <SelectInput
            name="industry"
            onChange={handleChange}
            value={newAudience.industry}
          >
            <option value="" selected="selected" disabled="disabled">
              -
            </option>

            {industries.map((item, i) => {
              return (
                <option key={i * 2} value={item}>
                  {item}
                </option>
              );
            })}
          </SelectInput>

          <LabelItem>Departments</LabelItem>
          <InputItem
            name="departments"
            onChange={handleChange}
            value={newAudience.departments}
          />

          <LabelItem>Seniority</LabelItem>
          <InputItem
            name="seniority"
            onChange={handleChange}
            value={newAudience.seniority}
          />

          <LabelItem>Job Title</LabelItem>
          <InputItem
            name="jobtitle"
            onChange={handleChange}
            value={newAudience.jobtitle}
          />
        </ContentContainer>

        <ErrContainer>
          <ErrMsg className="hide" id="error">
            All fields are required!
          </ErrMsg>
        </ErrContainer>

        <ErrContainer>
          <ErrMsg className="hide" id="updateerror">
            No value has been changed!
          </ErrMsg>
        </ErrContainer>

        <SaveBtnContainer>
          <SaveBtn onClick={updateAudience}>
            <SaveBtnIcon>
              <BiSave />{" "}
            </SaveBtnIcon>
            <SaveBtnText>Save Audience</SaveBtnText>
          </SaveBtn>
        </SaveBtnContainer>
      </MainContainer>
    </PageMainContainer>
  );
};

export default EditAudienceView;
