import React from "react";
import { useLocation, Redirect } from "react-router-dom";

import Spinner from "../components/Spinner";
import { useAuthContext } from "../context/auth-context";

const ProtectedRoutes = ({ children }) => {
  const { authUser, user, logged, setLogged } = useAuthContext();

  const location = useLocation();

    if (logged && (!authUser || !user))
      return <Spinner color="gray" width={50} height={50} />;


  if (!authUser || !logged) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: {
            from: location.pathname,
          },
        }}
      />
    );
  }

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { user })
      )}
    </>
  );
};

export default ProtectedRoutes;
