import React from "react";

import styled from "styled-components";
import PodItem from "./PodItem";
import { usePodsContext } from "../../context/pods-context";

const MainContainer = styled.div``;

const MainContent = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #6371de;
  margin-bottom: 10px;
`;

const Pods = () => {
  const { pods } = usePodsContext();

  return (
    <MainContainer>
      <ItemTitle>pods</ItemTitle>
      <MainContent>
        {pods &&
          pods.map((item) => {
            return <PodItem key={item.title} pod={item} />;
          })}
      </MainContent>
    </MainContainer>
  );
};

export default Pods;
