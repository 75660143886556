import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  getDoc,
  doc,
  usersCollection,
} from "../../firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { deleteUser } from "firebase/auth";

import styled from "styled-components";

import { useAuthContext } from "../../context/auth-context";
import { BsGoogle } from "react-icons/bs";

const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  min-width: 310px;
  background-color: white;
  padding: 50px;
  border-radius: 5px;
  transform: translateY(-90px);

  form {
    min-width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    padding-top: 20px;
  }
`;

const FormTitle = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  color: #6371de;
  margin-bottom: 40px;
`;

const SocialRow = styled.div`
  width: 82%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 20px;
`;

const Icon = styled.div`
  color: white;
  font-size: 18px;
  transform: translateY(2.5px);
  margin-right: 8px;
`;

const GBtn = styled.div`
  width: 100%;
  height: 50px;
  background: #cd5642;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Or = styled.div`
  text-align: center;
  color: #3b3d50;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const Input = styled.input`
  height: 37px;
  width: 100%;
  max-width: 520px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: 1px solid gray;
`;

const MNItemGetStartedContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 520px;
`;

const MNItemGetStarted = styled.button`
  width: 183px;
  height: 50px;
  background-color: #6371de;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  border: none;
  margin-top: 10px;
  cursor: pointer;
`;

const FormBottomLinkContainer = styled.div`
  text-align: center;
  padding: 15px;
`;

const FormBottomLinkMsg = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
`;

const FormBottomLinkLink = styled.div`
  color: #6371de;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
`;

const ErrContainer = styled.div`
  height: 20px;
`;

const ErrMsg = styled.div`
  color: red;
  text-align: center;
  font-size: 12px;
`;

const SignupForm = () => {
  const { authUser, user } = useAuthContext();

  const [logError, setLogError] = useState("No Account Found!");

  const history = useHistory();

  // Check if user is authenticated
  useEffect(() => {
    if (!authUser || !user) return;
    // I assume "isAuthenticated" is a boolean that you check user authentication in auth context
    if (user.role === "client") {
      history.push("/dashboard");
    } else {
      history.push("/admin-dashboard");
    }
  }, [user, history]);

  const handleLogError = () => {
    document.querySelector("#logError").classList.remove("hide");
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
      if (user) history.push("/dashboard");
    } catch (e) {
      alert(e.message);
    }
  }, []);

  const handleGoogleLogin = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        const userRef = doc(usersCollection, user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
        } else {
          deleteUser(user)
            .then(() => {
              // User deleted.
              handleLogError();
            })
            .catch((error) => {
              // An error ocurred
              // ...
            });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <FormContainer>
      <FormTitle>Login</FormTitle>
      <SocialRow>
        <GBtn onClick={handleGoogleLogin}>
          {" "}
          <Icon>
            <BsGoogle />
          </Icon>{" "}
          Google
        </GBtn>
      </SocialRow>
      <Or>or</Or>
      <FormContent>
        <form onSubmit={handleSubmit}>
          <Input name="email" placeholder="Email*" type="email" />
          <Input name="password" placeholder="Password*" type="password" />
          <MNItemGetStartedContainer>
            <MNItemGetStarted type="submit">Login</MNItemGetStarted>
          </MNItemGetStartedContainer>
        </form>
      </FormContent>
      {/* <FormBottomLinkContainer>
        <FormBottomLinkMsg>
          Don't have an account?{" "}
          <FormBottomLinkLink onClick={() => history.push("/signup")}>
            Sign up
          </FormBottomLinkLink>
        </FormBottomLinkMsg>
      </FormBottomLinkContainer> */}
      <ErrContainer>
        <ErrMsg id="logError" className="hide">
          {logError}
        </ErrMsg>
      </ErrContainer>
    </FormContainer>
  );
};

export default SignupForm;
