import React, { useState, useEffect } from "react";

import styled from "styled-components";
import axios from "axios";

const ThumbImg = styled.img`
  width: 100px;
  height: 98px;
  cursor: pointer;
`;

const MoodboardImageItem = ({ mediaItem }) => {
  const [media, setMedia] = useState({});

  useEffect(() => {
    if (!mediaItem) return;

    const tempObj = {
      name: mediaItem.imageName.split(".")[0],
      type: mediaItem.imageName.split(".")[1],
      thumbnail: mediaItem.thumbnail,
      url: mediaItem.imageUrl,
    };

    setMedia(tempObj);
  }, [mediaItem]);

  const handleDownload = () => {
    axios({
      url: `${media.url}`,
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;

      if (media.type === "pdf") {
        link.setAttribute("download", `brand-inspiration.pdf`);
      } else {
        link.setAttribute("download", `brand-inspiration.jpeg`);
      }

      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      {media.thumbnail && (
        <ThumbImg src={mediaItem.thumbnail} onClick={handleDownload} />
      )}
    </>
  );
};

export default MoodboardImageItem;
