import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions, db } from "../../firebase";
import { BsCheckLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { useSubContext } from "../../context/sub-context";
import { useSubFormViewContext } from "../../context/sub-form-view-context";
import { useAuthContext } from "../../context/auth-context";
import PaymentForm from "./PaymentForm";
import Spinner from "../Spinner";
import { formatPrice, planPricing, formatPriceNoDecimal } from "../../helper";

const stripePromise = loadStripe(
  "pk_test_51JLWFxBn4vAMMpLKbydJ9EPbm3hgM8r9wcSn5an5GB37wMmEurmvrhcaKdWNliVxq5TZcTvKEkGzS30shLlt9TgB00wSeBu6jg"
);

const SubPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const PageTitle = styled.h1`
  margin-bottom: 15px;
`;

const PageSubTitle = styled.div`
  font-size: 0.9rem;
  margin-bottom: 20px;
`;

const IntervalContainer = styled.div`
  border: 1px solid #2632381a;
  width: 320px;
  height: 66px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const IntervalItem = styled.div`
  height: 58px;
  width: 152px;
  border-radius: 5px;
  background: #f4f6ff;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;

  &.selectedIntervalStyle {
    background-color: #6371de;
    color: white;
  }
`;

const PlansContainer = styled.div`
  width: 90%;
  max-width: 1100px;
  min-width: 800px;
  height: 923px;
  box-shadow: 0px 0px 30px 0px #2632381a;
`;
const TableRow = styled.div`
  display: flex;
`;

const TableWO = styled.div`
  width: calc(100% / 3);
  height: 50px;
  display: flex;
  justify-content: center;
`;

const TableWOShort = styled.div`
  width: calc(100% / 3);
  height: 30px;
  display: flex;
  justify-content: center; ;
`;

const TableWRight = styled.div`
  width: calc(100% / 3);
  height: 50px;
  border-right: 1px solid #e9eaeb; ;
`;

const TableWRightShort = styled.div`
  width: calc(100% / 3);
  height: 30px;
  border-right: 1px solid #e9eaeb; ;
`;

const TableWRightWCenter = styled.div`
  width: calc(100% / 3);
  height: 50px;
  border-right: 1px solid #e9eaeb;
  display: flex;
  justify-content: center; ;
`;

const TableWRightBottom = styled.div`
  width: calc(100% / 3);
  height: 50px;
  border-right: 1px solid #e9eaeb;
  border-bottom: 1px solid #e9eaeb; ;
`;

const TableWBottom = styled.div`
  width: calc(100% / 3);
  height: 50px;
  border-bottom: 1px solid #e9eaeb; ;
`;

const TextCol = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #6371de;
`;

const SubPrice = styled.div`
  font-size: 40px;
  color: #6371de;
  font-weight: lighter;
  text-align: center;
`;

const SubFeatureTitle = styled.div`
  font-weight: bold;
  font-size: 40px;
  color: #3b3d50;
  opacity: 0.3;
  padding-left: 30px;
`;

const SubFeatureItem = styled.div`
  font-size: 16px;
  color: #3b3d50;
  padding: 15px;
  padding-left: 30px;
`;

const CheckMark = styled.div`
  text-align: center;
  margin-top: 15px;
  color: #58c9ae;
`;

const XMark = styled.div`
  text-align: center;
  margin-top: 15px;
  color: #e05780;
`;

const SubBtn = styled.div`
  width: 165px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const Heading = styled.h1`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SubHeading = styled.div`
  margin-bottom: 50px;
`;

const ConfirmationContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  width: 80%;
  min-width: 310px;
  max-width: 800px;
`;

const SummaryContainer = styled.div`
  width: 85%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(38, 50, 56, 0.1);
  border-radius: 5px;
  margin: auto;
  margin-bottom: 50px;
`;

const SummaryTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 60px;
  margin: auto;
  border-bottom: 1px solid #e9eaeb;
`;

const SummaryBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 60px;
  margin: auto;
`;
const SammurayTitle = styled.div`
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-left: 5px;
  margin-right: 5px;
`;

const SammurayDescription = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
  margin-left: 5px;
  margin-right: 5px;
`;

const SubscriptionForm = () => {
  const { user } = useAuthContext();
  const { sub, setSub } = useSubContext();
  const { subFormView, setSubFormView } = useSubFormViewContext();

  const [clientSecret, setClientSecret] = useState("");
  const [setLoading] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState("yearly");
  const [discountSecretKey, setDiscountSecretKey] = useState("");
  const [displayDiscount, setDisplayDiscount] = useState(false);
  const [currentDiscount, setCurrentDiscount] = useState('0');

  const handleIntervalChange = (type) => {
    setSelectedInterval(type);
  };

  const handleConfirmPlan = async (sub) => {
    if (!user) return;

    try {
      // setLoading(true);

      // Call cloud function to create a subscription
      const subscribe = httpsCallable(functions, "createSubscription");
      const result = await subscribe({
        userId: user.id, // If firebase auth is implemented the userId will be obtained in the function directly, no need to send over from frontend
        sub,
      });

      if (!result) {
        alert("Unexpected error occured");
      }

      const data = result.data;

      setClientSecret(data.clientSecret);

      // setLoading(false);
      setSub(null);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const handleSubscribe = (interval, planName) => {
    setSub({
      interval,
      price: planPricing[planName][`${interval}ly`],
      planName,
    });

    const subData = {
      interval,
      price: planPricing[planName][`${interval}ly`],
      planName,
    };

    handleConfirmPlan(subData);

    setSubFormView("paymentForm");
  };

  useEffect(() => {
    if (user) {
      const status = new URLSearchParams(window.location.search).get(
        "redirect_status"
      );

      if (status === "succeeded") {
        // Remove query string
        window.history.pushState({}, "", "/");

        // Update user's subsription status in Firestore
        return setDoc(
          doc(db, "users", user.id),
          {
            status: "subscribed",
          },
          { merge: true }
        );
      }
    }
  }, [user]);

  const handleDiscountChange = (e) => {
    setCurrentDiscount(e.target.value)
  }

  const keyPressValue = (e) => {
    setDiscountSecretKey(discountSecretKey + e.key)
  }

  useEffect(() => {
    document.addEventListener('keypress', keyPressValue)

    return () => {
      document.removeEventListener('keypress', keyPressValue)
    }
  })

  useEffect(() => {
    if(discountSecretKey === '$looK') {
      setDisplayDiscount(true)
    }
  }, [discountSecretKey])

  return (
    <>
      {subFormView === "subscriptionDetail" ? (
        <>
        <SubPageContainer>
          <PageTitle>Confirm Your Subscription</PageTitle>
          <PageSubTitle>Review your plan details below</PageSubTitle>

          <IntervalContainer>
            <IntervalItem
              className={
                selectedInterval === "monthly" ? "selectedIntervalStyle" : ""
              }
              onClick={() => handleIntervalChange("monthly")}
            >
              Monthly
            </IntervalItem>
            <IntervalItem
              className={
                selectedInterval === "yearly" ? "selectedIntervalStyle" : ""
              }
              onClick={() => handleIntervalChange("yearly")}
            >
              Yearly (20% Off)
            </IntervalItem>
          </IntervalContainer>

          <PlansContainer>
            <TableRow>
              <TableWRight></TableWRight>
              <TableWRight></TableWRight>
              <TableWO></TableWO>
            </TableRow>

            <TableRow>
              <TableWRight>
                <SubFeatureTitle>Included Contents</SubFeatureTitle>
              </TableWRight>
              <TableWRight>
                <SubTitle>Standard</SubTitle>
              </TableWRight>
              <TableWO>
                <SubTitle>Business</SubTitle>
              </TableWO>
            </TableRow>

            <TableRow>
              <TableWRight></TableWRight>
              <TableWRight>
                <SubPrice>{`$${formatPriceNoDecimal(
                  planPricing.standard[selectedInterval]
                )}/${
                  selectedInterval === "monthly" ? "Month" : "Year"
                }`}</SubPrice>
              </TableWRight>
              <TableWO>
                <SubPrice>{`$${formatPriceNoDecimal(
                  planPricing.business[selectedInterval]
                )}/${
                  selectedInterval === "monthly" ? "Month" : "Year"
                }`}</SubPrice>
              </TableWO>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem></SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom></TableWRightBottom>
              <TableWBottom></TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Turnaround Time</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <TextCol>2-3 Business Days</TextCol>
              </TableWRightBottom>
              <TableWBottom>
                <TextCol>2-5 Business Days</TextCol>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Blog</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Social Media</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Email</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Article</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Website Copy</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>eBook</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <XMark>
                  <FaTimes />
                </XMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Case Study</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <XMark>
                  <FaTimes />
                </XMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Market Research</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <XMark>
                  <FaTimes />
                </XMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Market Trends</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <XMark>
                  <FaTimes />
                </XMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Press Release</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <XMark>
                  <FaTimes />
                </XMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightBottom>
                <SubFeatureItem>Design Package</SubFeatureItem>
              </TableWRightBottom>
              <TableWRightBottom>
                <XMark>
                  <FaTimes />
                </XMark>
              </TableWRightBottom>
              <TableWBottom>
                <CheckMark>
                  <BsCheckLg />
                </CheckMark>
              </TableWBottom>
            </TableRow>

            <TableRow>
              <TableWRightShort></TableWRightShort>
              <TableWRightShort></TableWRightShort>
              <TableWOShort></TableWOShort>
            </TableRow>

            <TableRow>
              <TableWRight></TableWRight>
              <TableWRightWCenter>
                <SubBtn
                  onClick={() =>
                    handleSubscribe(
                      selectedInterval === "yearly" ? "year" : "month",
                      currentDiscount === '0' ? "standard" : `standard${currentDiscount}`
                    )
                  }
                >
                  Subscribe
                </SubBtn>
              </TableWRightWCenter>
              <TableWO>
                <SubBtn
                  onClick={() =>
                    handleSubscribe(
                      selectedInterval === "yearly" ? "year" : "month",
                      currentDiscount === '0' ? "business" : `business${currentDiscount}`
                    )
                  }
                >
                  Subscribe
                </SubBtn>
              </TableWO>
            </TableRow>

            <TableRow>
              <TableWRightShort></TableWRightShort>
              <TableWRightShort></TableWRightShort>
              <TableWOShort></TableWOShort>
            </TableRow>
          </PlansContainer>
        </SubPageContainer>
        {displayDiscount ? <select onChange={handleDiscountChange}>
          <option value="0">0%</option>
          <option value="10">10%</option>
          <option value="50">50%</option>
          <option value="100">100%</option>
        </select> : undefined}
        </>
      ) : subFormView === "paymentForm" && clientSecret ? (
        <>
          <ConfirmationContainer>
            <Heading>Payment Summary</Heading>
            <SubHeading>
              Please review the following details for this transaction.
            </SubHeading>

            <SummaryContainer>
              <SummaryTop>
                <SammurayTitle>Description</SammurayTitle>
                {!user.interval ? (
                  false
                ) : (
                  <SammurayDescription>{`${user.interval}ly`}</SammurayDescription>
                )}
              </SummaryTop>

              <SummaryBottom>
                <SammurayTitle>Total</SammurayTitle>
                {!user.price ? (
                  false
                ) : (
                  <SammurayDescription>{`$${formatPrice(
                    user.price
                  )}`}</SammurayDescription>
                )}
              </SummaryBottom>
            </SummaryContainer>
          </ConfirmationContainer>

          <Heading>Billing Information</Heading>
          <SubHeading>Enter your payment details below.</SubHeading>

          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: "stripe",
              },
            }}
            stripe={stripePromise}
          >
            <PaymentForm />
          </Elements>
        </>
      ) : (
        <>
          <ConfirmationContainer>
            <Heading>Payment Summary</Heading>
            <SubHeading>
              Please review the following details for this transaction.
            </SubHeading>

            <SummaryContainer>
              <SummaryTop>
                <SammurayTitle>Description</SammurayTitle>
                <SammurayDescription>{`${sub.interval}ly`}</SammurayDescription>
              </SummaryTop>

              <SummaryBottom>
                <SammurayTitle>Total</SammurayTitle>
                <SammurayDescription>{`$${formatPrice(
                  sub.price
                )}`}</SammurayDescription>
              </SummaryBottom>
            </SummaryContainer>
          </ConfirmationContainer>
          <Spinner color="grey" height={50} width={50} />
        </>
      )}
    </>
  );
};

export default SubscriptionForm;
