import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MdEdit } from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { RiLockPasswordFill } from "react-icons/ri";

import styled from "styled-components";

const CompanyNavContainer = styled.div`
  width: 288px;
  height: calc(100vh - 80px);
  background-color: white;
  transform: translateX(2px);
`;

const NavTitleRow = styled.div`
  padding: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  border-bottom: 1px solid #e6e8f2;
  box-sizing: border-box;
`;

const NavContentRow = styled.div``;

const MenuItem = styled.div`
  box-sizing: border-box;
  padding: 24px;
  font-size: 16px;
  line-height: 18px;

  color: #3b3d50;
  border-bottom: 1px solid #e6e8f2;

  &.cp-selected {
    color: #6371de;
    background-color: #fafbfe;
  }
`;

const MenuText = styled.div`
  cursor: pointer;
  display: inline;
  display: flex;
`;

const NavIcon = styled.div`
  font-size: 19px;
  transform: translateY(-1px);
  margin-right: 15px;
`;

const CompanyNav = () => {
  const [currentPath, setCurrentPath] = useState("companyprofile");

  const location = useLocation();
  const history = useHistory()

  useEffect(() => {
    setCurrentPath(location.pathname.split("/")[1]);
  }, [location]);

  return (
    <CompanyNavContainer>
      <NavTitleRow>company profile</NavTitleRow>
      <NavContentRow className="compNavContainer">
        <MenuItem
          className={
            currentPath === "companyprofile" ? "cp-selected" : undefined
          } onClick={() => history.push('/companyprofile')}
        >
          <MenuText>
            <NavIcon>
              <MdEdit />
            </NavIcon>
            Edit Profile
          </MenuText>
        </MenuItem>
        <MenuItem
          className={
            currentPath === "editsubscription" ? "cp-selected" : undefined
          } onClick={() => history.push('/editsubscription')}
        >
          <MenuText>
            <NavIcon>
              <RiExchangeDollarLine />
            </NavIcon>
            Billing & Subscription
          </MenuText>
        </MenuItem>
        <MenuItem
          className={
            currentPath === "updatepassword" ? "cp-selected" : undefined
          } onClick={() => history.push('/updatepassword')}
        >
          <MenuText>
            <NavIcon>
              <RiLockPasswordFill />
            </NavIcon>
            Password
          </MenuText>
        </MenuItem>
      </NavContentRow>
    </CompanyNavContainer>
  );
};

export default CompanyNav;
