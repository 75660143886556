import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { departments, industries, senioritis } from "../../helper";

const ContentContainer = styled.div`
  width: 100%;
  text-align: left;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
`;

const LabelItem = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
`;

const InputItem = styled.input`
  height: 50px;
  width: calc(100% - 40px);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #263238;
  color: #3b3d50;
`;

const AgeRow = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const AgeItem = styled.div`
  display: flex;
  margin-right: 20px;
`;

const AgeCheckboxContainer = styled.div``;

const AgeCheckbox = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
`;

const AgeCheckboxSpan = styled.span``;

const AgeLabel = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

const SelectInput = styled.select`
  height: 50px;
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #263238;
  color: #3b3d50;
  cursor: pointer;
`;

const AudienceItem = ({ setNewOrder, newAudience, setNewAudience }) => {
  const handleChange = (e) => {
    setNewAudience({
      ...newAudience,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgeSelect = () => {
    let ageArr = [];

    document.querySelectorAll(".audAge").forEach((age) => {
      if (age.checked) {
        ageArr.push(age.name);
      }
    });

    setNewAudience({
      ...newAudience,
      age: ageArr,
    });
  };

  return (
    <ContentContainer>
      <LabelItem>Audience Name</LabelItem>
      <InputItem onChange={handleChange} name="audiencename" />

      <LabelItem>Age</LabelItem>
      <AgeRow>
        <AgeItem>
          <AgeCheckboxContainer>
            <AgeCheckbox
              onChange={handleAgeSelect}
              className="audAge"
              name="18-24"
              type="checkbox"
            />
            <AgeCheckboxSpan className="span"></AgeCheckboxSpan>
          </AgeCheckboxContainer>
          <AgeLabel>18-24</AgeLabel>
        </AgeItem>
        <AgeItem>
          <AgeCheckboxContainer>
            <AgeCheckbox
              onChange={handleAgeSelect}
              className="audAge"
              name="25-34"
              type="checkbox"
            />
            <AgeCheckboxSpan></AgeCheckboxSpan>
          </AgeCheckboxContainer>
          <AgeLabel>25-34</AgeLabel>
        </AgeItem>
        <AgeItem>
          <AgeCheckbox
            onChange={handleAgeSelect}
            className="audAge"
            name="35-44"
            type="checkbox"
          />
          <AgeLabel>35-44</AgeLabel>
        </AgeItem>
        <AgeItem>
          <AgeCheckbox
            onChange={handleAgeSelect}
            className="audAge"
            name="45-54"
            type="checkbox"
          />
          <AgeLabel>45-54</AgeLabel>
        </AgeItem>
        <AgeItem>
          <AgeCheckbox
            onChange={handleAgeSelect}
            className="audAge"
            name="55-64"
            type="checkbox"
          />
          <AgeLabel>55-64</AgeLabel>
        </AgeItem>
        <AgeItem>
          <AgeCheckbox
            onChange={handleAgeSelect}
            className="audAge"
            name="65-74"
            type="checkbox"
          />
          <AgeLabel>65-74</AgeLabel>
        </AgeItem>
      </AgeRow>

      <LabelItem>Industry</LabelItem>
      <SelectInput name="industry" onChange={handleChange}>
        <option value="" selected="selected" disabled="disabled">
          -
        </option>

        {industries.map((item, i) => {
          return (
            <option key={i * 2} value={item}>
              {item}
            </option>
          );
        })}
      </SelectInput>

      <LabelItem>Seniority</LabelItem>
      <SelectInput name="seniority" onChange={handleChange}>
        <option value="" selected="selected" disabled="disabled">
          -
        </option>

        {senioritis.map((item, i) => {
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </SelectInput>

      <LabelItem>Departments</LabelItem>
      <SelectInput name="departments" onChange={handleChange}>
        <option value="" selected="selected" disabled="disabled">
          -
        </option>

        {departments.map((item, i) => {
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </SelectInput>

      <LabelItem>Job Title</LabelItem>
      <InputItem name="jobtitle" onChange={handleChange} />
    </ContentContainer>
  );
};

export default AudienceItem;
