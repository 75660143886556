import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { orderTypes } from "../../helper";

const OrderDescriptionContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  width: 80%;
  min-width: 310px;
  max-width: 800px;
`;

const Heading = styled.h1`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SubHeading = styled.div`
  margin-bottom: 50px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(38, 50, 56, 0.1);
  border-radius: 5px;
`;

const SelectInput = styled.select`
  height: 50px;
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #263238;
  color: #3b3d50;
  cursor: pointer;
`;

const TopicInput = styled.input`
  height: 50px;
  width: 95%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #263238;
  color: #3b3d50;
`;

const DescriptionInput = styled.textarea`
  height: 240px;
  width: 95%;
  padding: 15px 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #263238;
  color: #3b3d50;
`;

const ErrContainer = styled.div`
  margin: auto;
  margin-top: -15px;
  margin-bottom: 15px;
  height: 20px;
`;

const ErrMsg = styled.div`
  margin: auto;
  color: red;

  &.hide {
    display: none;
  }
`;

const BtnContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubBtn = styled.div`
  width: 165px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const OrderDescription = ({ setNewOrder, setOrderView }) => {
  const [orderDescription, setOrderDescription] = useState({
    requestname: "",
    orderType: "",
    topic: "",
    description: "",
  });
  const [formErr, setFormErr] = useState(false);

  const handleInputChange = (e) => {
    setOrderDescription({
      ...orderDescription,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitDescription = () => {
    if (
      !orderDescription.topic ||
      !orderDescription.requestname ||
      !orderDescription.description ||
      !orderDescription.orderType
    ) {
      setFormErr(true);
    } else {
      setNewOrder(orderDescription);
      setOrderView("audience");
    }
  };

  useEffect(() => {
    if (formErr) {
      document.querySelector("#error").classList.remove("hide");
      setTimeout(() => {
        document.querySelector("#error").classList.add("hide");
        setFormErr(false);
      }, 3000);
    }
  }, [formErr]);

  return (
    <OrderDescriptionContainer>
      <Heading>Order Description</Heading>
      <SubHeading>Add a title and description below.</SubHeading>

      <DescriptionContainer>
        <TopicInput
          name="requestname"
          onChange={handleInputChange}
          placeholder="Request Name"
        />

        <SelectInput name="orderType" onChange={handleInputChange}>
          <option value="" selected="selected" disabled="disabled">
            Type
          </option>

          {orderTypes.map((item, i) => {
            return (
              <option key={i * 2} value={item}>
                {item}
              </option>
            );
          })}
        </SelectInput>

        <TopicInput
          name="topic"
          onChange={handleInputChange}
          placeholder="Topic"
        />

        <DescriptionInput
          name="description"
          onChange={handleInputChange}
          placeholder="Description"
        />

        <ErrContainer>
          <ErrMsg className="hide" id="error">
            All fields are required!
          </ErrMsg>
        </ErrContainer>

        <BtnContainer>
          <SubBtn onClick={handleSubmitDescription}>Next</SubBtn>
        </BtnContainer>
      </DescriptionContainer>
    </OrderDescriptionContainer>
  );
};

export default OrderDescription;
