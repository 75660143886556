import React from "react";

import styled from "styled-components";
import { BsCheckLg } from "react-icons/bs";
import { useAuthContext } from "../../../context/auth-context";
import { planFeatures } from "../../../helper";

const MainContainer = styled.div`
  width: 100%;
  height: 460px;
  background-color: white;
  text-align: left;
  padding: 24px;
  box-sizing: border-box;
`;

const SecTitleDescriptive = styled.div`
  font-size: 16px;
  color: #3b3d50;
  opacity: 0.5;
  border-bottom: 1px solid #e6e8f2;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const ItemRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ItemName = styled.div`
  font-size: 16px;
  color: #3b3d50;
`;

const CheckMark = styled.div`
  color: #58c9ae;
  font-size: 0.8rem;
  margin-right: 20px;
`;

const PlanFeatures = () => {
  const { user } = useAuthContext();

  const getPlanName = (name) => {
    if (user.planName.includes("standard")) {
      return "standard";
    } else if (user.planName.includes("business")) {
      return "business";
    }
  };

  return (
    <MainContainer>
      <SecTitleDescriptive>Your Standart Plan Includes</SecTitleDescriptive>
      {planFeatures(getPlanName(user.planName)).map((item) => {
        return (
          <ItemRow key={item}>
            <CheckMark>
              <BsCheckLg />
            </CheckMark>
            <ItemName>{item}</ItemName>
          </ItemRow>
        );
      })}
    </MainContainer>
  );
};

export default PlanFeatures;
