import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import { podsCollection, usersCollection, doc, setDoc } from "../../firebase";
import AssociateCompanyItem from "./AssociateCompanyItem";

const ItemContainer = styled.div`
  width: 300px;
  min-height: 62px;
  border: 2px solid #6371de;
  margin-bottom: 10px;
  padding: 5px 10px;
`;

const ItemTitle = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  color: #6371de;
  margin-top: 3px;
  margin-bottom: 5px;
`;

const Associate = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
`;

const CompanyItem = ({ company }) => {
  const [assignedAssociates, setAssignedAssociates] = useState([]);

  const { allAssociates } = useAuthContext();

  useEffect(() => {
    if (!company || !allAssociates) return;

    const associatesArr = company.assigned.associates;
    const tempFilteredAssociates = [];

    for (let i = 0; i < associatesArr.length; i++) {
      const filteredItem = allAssociates.filter(
        (associate) => associate.id === associatesArr[i]
      )[0];
      tempFilteredAssociates.push(filteredItem);
    }

    setAssignedAssociates(tempFilteredAssociates);
  }, [company, allAssociates]);

  return (
    <ItemContainer>
      <ItemTitle>{company.company}</ItemTitle>

      {assignedAssociates &&
        assignedAssociates.map((item) => {
          return (
            <AssociateCompanyItem
              key={item.id}
              name={item.role}
              associateId={item.id}
              companyId={company.id}
            />
          );
        })}
    </ItemContainer>
  );
};

export default CompanyItem;
