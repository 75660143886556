import React from "react";

import styled from "styled-components";
import { getAuth, signOut } from "firebase/auth";

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  color: #6371de;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 45px;
margin-bottom: 35px;
`;

const SignoutBtn = styled.div`
  width: 160px;
  height: 35px;
  background-color: #6371de;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`;

const SignoutBtnText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
`;

const Signout = ({ setSignView }) => {
  return (
    <MainContainer>
      <MainContent>
        <Header>Are you an associate?</Header>
        <SignoutBtn
          onClick={() => {
            signOut(getAuth());
            setSignView("signup");
          }}
        >
          <SignoutBtnText>Yes!</SignoutBtnText>
        </SignoutBtn>
      </MainContent>
    </MainContainer>
  );
};

export default Signout;
