import React from "react";
import { useLocation } from "react-router-dom";

import styled, { ThemeProvider } from "styled-components";
import MainNav from "../components/nav/MainNav";
import AccountNav from "./nav/AccountNav";
import AdminNav from "./nav/AdminNav";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";

const theme = {
  colors: {
    darkgreen: "#013243",
  },
};

const MainContainer = styled.div`
  min-height: calc(100vh - 170px);
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ACCContainer = styled.div`
  min-height: calc(100vh - 170px);
  display: flex;
  justify-content: space-between;
`;

const ACCLeft = styled.div``;

const ACCRight = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Layout = ({ children }) => {
  const { viewAccNav } = useViewAccNavContext();
  const { viewAdminNav } = useViewAdminNavContext();

  const location = useLocation();

  return (
    <>
      <ThemeProvider theme={theme}>
        {location.pathname !== "/" &&
        location.pathname !== "/login" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/createsubscription" &&
        location.pathname !== "/subscriptionconfirmation" &&
        location.pathname !== "/neworder" ? (
          <MainNav />
        ) : undefined}

        {!viewAccNav && !viewAdminNav ? (
          <MainContainer>{children}</MainContainer>
        ) : (
          <ACCContainer>
            <ACCLeft>
              {viewAccNav && <AccountNav />}
              {viewAdminNav && <AdminNav />}
            </ACCLeft>
            <ACCRight>{children}</ACCRight>
          </ACCContainer>
        )}

        {/* <Footer /> */}
      </ThemeProvider>
    </>
  );
};

export default Layout;
