import React from "react";
import { useLocation, Redirect } from "react-router-dom";

import { useAuthContext } from "../context/auth-context";

const ClientRoutes = ({ children }) => {
  const { user } = useAuthContext();

  const location = useLocation();

  if (!user.role === "client") return <Redirect to="/admin-dashboard" />;
  //   if(!user.role === 'client') return <Redirect
  //   to={{
  //     pathname: "/admin-dashboard",
  //     state: {
  //       from: location.pathname,
  //     },
  //   }}
  // />

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { user })
      )}
    </>
  );
};

export default ClientRoutes;
