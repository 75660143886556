import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import styled from "styled-components";
import axios from "axios";
import { useViewAccNavContext } from "../../context/view-acc-nav-context";
import { useOrdersContext } from "../../context/orders-context";
import { setDoc, ordersCollection, doc } from "../../firebase";
import { FiDownload } from "react-icons/fi";
import { submissionsArrayReversed } from "../../helper";

const PageMainContainer = styled.div`
  width: 95%;
  background-color: white;
  margin-top: 40px;
  text-align: center;
  padding: 20px;
  margin-bottom: 30px;
`;

const OrderItemContainer = styled.div`
  width: 100%;
  background: #fafbfe;
  border: 1px solid #e4e6f2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  &.descriptionContainer {
    display: block;
    text-align: left;
  }
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
  text-align: left;
`;

const OrderItemLeft = styled.div`
  text-align: left;
`;

const OrderItemRight = styled.div`
  text-align: right;
`;

const ItemTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ItemTitleValue = styled.span`
  font-size: 15px;
  font-weight: normal;
`;

const ItemStatus = styled.div`
  display: flex;
  line-height: 18px;
`;

const GreenDot = styled.div`
  background-color: #58c9ae;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  transform: translateY(7px);
`;

const BlueDot = styled.div`
  background-color: #35a6f8;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  transform: translateY(7px);
`;

const RevisionNote = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #e4e6f2;
  border-radius: 5px;
`;

const BtnContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 15px;
`;

const SubBtn = styled.div`
  width: 100px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const OrderDescription = styled.div`
  width: 100%;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
`;

const SubmissionItemRow = styled.div`
  border-bottom: 1px solid #e6e8f2;
  margin-bottom: 10px;

  &:nth-last-child(1) {
    border: none;
  }
`;

const SubDetailContainer = styled.div`
  width: calc((100% - 30px) / 2);
  text-align: left;
`;

const SubDetailRightContainer = styled.div`
  width: 30px;
  text-align: right;
`;

const FileTypeSub = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.div`
  font-size: 1.3rem;

  .downloadicon {
    cursor: pointer;
  }
`;

const OrderDetail = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { orders } = useOrdersContext();

  const [currentOrder, setCurrentOrder] = useState(null);
  const [revisionNote, setRevisionNote] = useState("");
  const [orderSubmissions, setOrderSubmissions] = useState([]);

  setViewAccNav(true);
  const location = useLocation();

  useEffect(() => {
    const filteredOrder = orders.filter(
      (order) => order.id === location.pathname.split("/")[2]
    );

    setCurrentOrder(filteredOrder[0]);
  }, [location, orders]);

  const handleNoteChange = (e) => {
    setRevisionNote(e.target.value);
  };

  const handleRevision = async () => {
    const orderRef = doc(ordersCollection, currentOrder.id);
    await setDoc(
      orderRef,
      {
        submissions: [
          ...currentOrder.submissions,
          {
            submittedBy: "client",
            type: "revision",
            note: revisionNote,
            submittedAt: new Date(),
          },
        ],
        status: "inprogress",
        updatedAt: new Date(),
      },
      { merge: true }
    );

    setRevisionNote("");
  };

  const handleDownload = (url, name) => {
    axios({
      url: `${url}`,
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `submission.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    if (!currentOrder) return;

    setOrderSubmissions(submissionsArrayReversed(currentOrder.submissions));
  }, [currentOrder]);

  return (
    <PageMainContainer>
      <Heading>request details</Heading>

      {/* Order Details Top Row */}

      <OrderItemContainer>
        <OrderItemLeft>
          <ItemTitle>
            Request ID:{" "}
            <ItemTitleValue>
              {!currentOrder ? false : currentOrder.orderNumber}
            </ItemTitleValue>
          </ItemTitle>
          <ItemTitle>
            Date Created:{" "}
            <ItemTitleValue>
              {!currentOrder
                ? false
                : currentOrder.createdAt.toDate().toDateString()}
            </ItemTitleValue>
          </ItemTitle>
          <ItemTitle>
            Request Name:{" "}
            <ItemTitleValue>
              {!currentOrder ? false : currentOrder.requestname}
            </ItemTitleValue>
          </ItemTitle>
          <ItemTitle>
            Request Type:{" "}
            <ItemTitleValue>
              {!currentOrder ? false : currentOrder.type}
            </ItemTitleValue>
          </ItemTitle>
          <ItemTitle>
            Topic:{" "}
            <ItemTitleValue>
              {!currentOrder ? false : currentOrder.topic}
            </ItemTitleValue>
          </ItemTitle>
          <ItemTitle>
            Description:{" "}
            <ItemTitleValue>
              {!currentOrder ? false : currentOrder.description}
            </ItemTitleValue>
          </ItemTitle>
        </OrderItemLeft>
        <OrderItemRight>
          <ItemTitleValue>
            {!currentOrder ? (
              false
            ) : currentOrder.status === "inprogress" ? (
              <ItemStatus>
                <BlueDot />
                In Progress
              </ItemStatus>
            ) : (
              <ItemStatus>
                <GreenDot />
                Delivered
              </ItemStatus>
            )}
          </ItemTitleValue>
        </OrderItemRight>
      </OrderItemContainer>

      {/* Revision Request */}

      {currentOrder && currentOrder.status === "delivered" ? (
        <OrderItemContainer className="descriptionContainer">
          <ItemTitle>Revision Request:</ItemTitle>

          <RevisionNote onChange={handleNoteChange} value={revisionNote} />

          <BtnContainer>
            <SubBtn onClick={handleRevision}>Submit</SubBtn>
          </BtnContainer>
        </OrderItemContainer>
      ) : undefined}

      {/* listing all submissions */}

      {!currentOrder ? (
        false
      ) : orderSubmissions.length > 0 ? (
        <OrderDescription>
          {orderSubmissions.map((subItem) => {
            if (subItem.type === "file") {
              return (
                <SubmissionItemRow>
                  <FileTypeSub key={subItem.name}>
                    <SubDetailContainer>
                      <ItemTitle>
                        Submitted By:{" "}
                        <ItemTitleValue>{subItem.submittedBy}</ItemTitleValue>
                      </ItemTitle>
                    </SubDetailContainer>
                    <SubDetailContainer>
                      <ItemTitle>
                        Date:{" "}
                        <ItemTitleValue>
                          {subItem.submittedAt.toDate().toDateString()}
                        </ItemTitleValue>
                      </ItemTitle>
                    </SubDetailContainer>
                    <SubDetailRightContainer>
                      <Icon>
                        <FiDownload
                          className="downloadicon"
                          onClick={() =>
                            handleDownload(subItem.url, subItem.name)
                          }
                        />
                      </Icon>
                    </SubDetailRightContainer>
                  </FileTypeSub>
                  <FileTypeSub>
                    <ItemTitle>
                      Note:{" "}
                      <ItemTitleValue>{subItem.uploadNote}</ItemTitleValue>
                    </ItemTitle>
                  </FileTypeSub>
                </SubmissionItemRow>
              );
            } else if (subItem.type === "revision") {
              return (
                <SubmissionItemRow>
                  <FileTypeSub key={subItem.name}>
                    <SubDetailContainer>
                      <ItemTitle>
                        Submitted By:{" "}
                        <ItemTitleValue>{subItem.submittedBy}</ItemTitleValue>
                      </ItemTitle>
                    </SubDetailContainer>
                    <SubDetailContainer>
                      <ItemTitle>
                        Date:{" "}
                        <ItemTitleValue>
                          {subItem.submittedAt.toDate().toDateString()}
                        </ItemTitleValue>
                      </ItemTitle>
                    </SubDetailContainer>

                    <SubDetailRightContainer>
                      <Icon>
                        {/* <FiDownload
                      className="downloadicon"
                      onClick={() => handleDownload(subItem.url, subItem.name)}
                    /> */}
                        <div className="downloadicon"></div>
                      </Icon>
                    </SubDetailRightContainer>
                  </FileTypeSub>
                  <FileTypeSub>
                    <ItemTitle>
                      Note: <ItemTitleValue>{subItem.note}</ItemTitleValue>
                    </ItemTitle>
                  </FileTypeSub>
                </SubmissionItemRow>
              );
            } else if (subItem.type === "adminnote") {
              return (
                <SubmissionItemRow>
                  <FileTypeSub key={subItem.name}>
                    <SubDetailContainer>
                      <ItemTitle>
                        Submitted By:{" "}
                        <ItemTitleValue>{subItem.submittedBy}</ItemTitleValue>
                      </ItemTitle>
                    </SubDetailContainer>
                    <SubDetailContainer>
                      <ItemTitle>
                        Date:{" "}
                        <ItemTitleValue>
                          {subItem.submittedAt.toDate().toDateString()}
                        </ItemTitleValue>
                      </ItemTitle>
                    </SubDetailContainer>

                    <SubDetailRightContainer>
                      <Icon>
                        {/* <FiDownload
                      className="downloadicon"
                      onClick={() => handleDownload(subItem.url, subItem.name)}
                    /> */}
                        <div className="downloadicon"></div>
                      </Icon>
                    </SubDetailRightContainer>
                  </FileTypeSub>
                  <FileTypeSub>
                    <ItemTitle>
                      Note: <ItemTitleValue>{subItem.note}</ItemTitleValue>
                    </ItemTitle>
                  </FileTypeSub>
                </SubmissionItemRow>
              );
            }
          })}
        </OrderDescription>
      ) : undefined}
    </PageMainContainer>
  );
};

export default OrderDetail;
