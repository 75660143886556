import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import CompanyItem from "./CompanyItem";

const MainContainer = styled.div`
  width: calc(40% - 5px);
  min-width: 310px;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ItemTitle = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  color: #6371de;
  margin-bottom: 5px;
`;

const Companies = () => {
  const { allUsers } = useAuthContext();

  return (
    <MainContainer>
      <ItemTitle>Companies</ItemTitle>
      <MainContent>
        {allUsers &&
          allUsers.map((company) => {
            return <CompanyItem key={company.id} company={company} />;
          })}
      </MainContent>
    </MainContainer>
  );
};

export default Companies;
