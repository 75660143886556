import React from "react";

import styled from "styled-components";

const Audiences = ({ audiences }) => {
  const MainContainer = styled.div`
    margin-top: 50px;
    width: 100%;
  `;

  const MainContent = styled.div`
    width: 100%;
    box-sizing: border-box;
  `;

  const SecTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    text-transform: uppercase;
    color: #6371de;
    margin-bottom: 20px;
  `;

  const AudItem = styled.div`
    &:not(:last-child) {
      /* border-bottom: 1px solid black; */
      margin-bottom: 20px;
    }
  `;

  const LineItem = styled.div`
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
  `;

  const ItemTitle = styled.div`
    font-weight: bold;
    margin-right: 10px;
    width: 150px;
  `;

  const ItemDescription = styled.div`
    margin-right: 5px;
  `;

  return (
    <MainContainer>
      <SecTitle>Audiences</SecTitle>
      <MainContent>
        {audiences.map((aud) => {
          return (
            <AudItem>
              <LineItem>
                <ItemTitle>Audience Name:</ItemTitle>
                <ItemDescription>{aud.audiencename}</ItemDescription>
              </LineItem>

              <LineItem>
                <ItemTitle>Age:</ItemTitle>
                {aud.age.map((audage) => {
                  return <ItemDescription>{audage}</ItemDescription>;
                })}
              </LineItem>

              <LineItem>
                <ItemTitle>Industry:</ItemTitle>
                <ItemDescription>{aud.industry}</ItemDescription>
              </LineItem>

              <LineItem>
                <ItemTitle>Departments:</ItemTitle>
                <ItemDescription>{aud.departments}</ItemDescription>
              </LineItem>

              <LineItem>
                <ItemTitle>Job Title:</ItemTitle>
                <ItemDescription>{aud.jobtitle}</ItemDescription>
              </LineItem>

              <LineItem>
                <ItemTitle>Seniority:</ItemTitle>
                <ItemDescription>{aud.seniority}</ItemDescription>
              </LineItem>
            </AudItem>
          );
        })}
      </MainContent>
    </MainContainer>
  );
};

export default Audiences;
