import React, { useEffect } from "react";

import styled from "styled-components";
import ConfirmedPayment from "../components/update-subscription/ConfirmedPayment";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import CompanyNav from "../components/nav/CompanyNav";
import { useAuthContext } from "../context/auth-context";
import { setDoc, doc, db } from "../firebase";

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4px);
  margin-top: 20px;
`;

const PageLeftCol = styled.div`
  width: 300px;
  height: calc(100vh - 80px);
  position: relative;
`;

const PageRightCol = styled.div`
  width: calc(100% - 300px);
  margin: 15px;

  @media (max-width: 990px) {
    margin-left: 20px;
  }
`;

const SubscriptionUpdateConfirmation = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();
  const { user } = useAuthContext();

  setViewAccNav(true);
  setViewAdminNav(false);

  useEffect(() => {
    if (user) {
      const status = new URLSearchParams(window.location.search).get(
        "redirect_status"
      );

      if (status === "succeeded") {
        // Remove query string
        window.history.pushState({}, "", "/");

        // Update user's subsription status in Firestore
        return setDoc(
          doc(db, "users", user.id),
          {
            status: "subscribed",
          },
          { merge: true }
        );
      }
    }
  }, [user]);

  return (
    <PageContainer>
      <PageLeftCol>
        <CompanyNav />
      </PageLeftCol>
      <PageRightCol>
        <ConfirmedPayment />
      </PageRightCol>
    </PageContainer>
  );
};

export default SubscriptionUpdateConfirmation;
