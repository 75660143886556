import React from 'react';

import styled from "styled-components";



const MainContainer = styled.div`
  width: 100%;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 8px;
`;


const TestForm = () => {
  return (
    <MainContainer>
    <form action="">
      <input type="text" />
      <input type="text" />
      <input type="text" />
      <input type="text" />
      <input type="text" />
      <button>submit</button>
    </form>

    </MainContainer>
  );
};

export default TestForm;
