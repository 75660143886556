import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";

import styled from "styled-components";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import { useAuthContext } from "../context/auth-context";
import Pods from "../components/pods-assignment/Pods";
import Businesses from "../components/pods-assignment/Businesses";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 95%;
  min-height: calc(100vh - 130px);
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  background-color: white;
  padding: 20px 15px;
`;

const MainContent = styled.div`
  display: flex;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 40px;
`;

const PodsAssignment = () => {
  const { user } = useAuthContext();
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const location = useLocation();

  setViewAccNav(false);
  setViewAdminNav(true);

  useEffect(() => {
    if (!user) return;

    if (user.role !== "admin")
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <MainContainer>
      <Heading>Pods Assignment</Heading>
      <MainContent>
        <Pods />
        <Businesses />
      </MainContent>
    </MainContainer>
  );
};

export default PodsAssignment;
