import React from "react";
import { Redirect } from "react-router-dom";

import styled from "styled-components";
import Charts from "../components/dashboard-sections/Charts";
import ContentCenter from "../components/dashboard-sections/ContentCenter";
import ControlOverview from "../components/dashboard-sections/ControlOverview";
import NewRequestRow from "../components/dashboard-sections/NewRequestRow";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";

const PageMainContainer = styled.div`
  width: 95%;
  margin-top: 40px;
  text-align: center;
  height: calc(100vh - 120px);
`;

const PageTopContainer = styled.div`
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 2px;
`;

const PageBottomContainer = styled.div`
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
`;

const ChartRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
`;

const PageMainContent = styled.div`
  padding: 10px;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Col24 = styled.div`
  width: 24%;
`;

const Col74 = styled.div`
  width: 74%;
`;

const Dashboard = ({ authUser, user }) => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  setViewAccNav(true);
  setViewAdminNav(false);

  if (!authUser) {
    return <Redirect to="/" />;
  }

  if (user.role !== "client") {
    return <Redirect to="/admin-dashboard" />;
  }

  if (user.role === "client" && user.status !== "subscribed") {
    return <Redirect to="/createsubscription" />;
  }

  return (
    <PageMainContainer>
      <PageTopContainer>
        <NewRequestRow />
      </PageTopContainer>
      <PageBottomContainer>
        <PageMainContent>
          <ContentRow>
            <Col74>
              <ControlOverview />
            </Col74>
            <Col24>
              <ContentCenter />
            </Col24>
          </ContentRow>
        </PageMainContent>
        <ChartRow>
          <Charts />
        </ChartRow>
      </PageBottomContainer>
    </PageMainContainer>
  );
};

export default Dashboard;
