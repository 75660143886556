import React from "react";

import styled from "styled-components";
import axios from "axios";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const LineItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
`;

const ItemDescription = styled.div`
  margin-right: 5px;
  text-transform: capitalize;

  &.fontlink {
    cursor: pointer;
  }
`;

const Branding = ({ branding, fonts, colorCode }) => {
  const handleDownload = (fontname, fonturl) => {
    axios({
      url: fonturl,
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${fontname}`);

      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <MainContainer>
      <SecTitle>Branding</SecTitle>
      <MainContent>
        <LineItem>
          <ItemTitle>Title Font:</ItemTitle>
          {fonts.titlefont.fontUrl ? (
            <ItemDescription
              className="fontlink"
              onClick={() =>
                handleDownload(
                  fonts.titlefont.fontName,
                  fonts.titlefont.fontUrl
                )
              }
            >
              {fonts.titlefont.fontName}
            </ItemDescription>
          ) : (
            <ItemDescription>{`Google Font: ${fonts.titlefont.fontName}`}</ItemDescription>
          )}
        </LineItem>
        <LineItem>
          <ItemTitle>Body Font:</ItemTitle>
          {fonts.bodyfont.fontUrl ? (
            <ItemDescription
              className="fontlink"
              onClick={() =>
                handleDownload(fonts.bodyfont.fontName, fonts.bodyfont.fontUrl)
              }
            >
              {fonts.bodyfont.fontName}
            </ItemDescription>
          ) : (
            <ItemDescription>{`Google Font: ${fonts.bodyfont.fontName}`}</ItemDescription>
          )}
        </LineItem>
        <LineItem>
          <ItemTitle>Color Codes:</ItemTitle>
          <ItemDescription>{colorCode}</ItemDescription>
        </LineItem>
        <LineItem>
          <ItemTitle>Branding Book:</ItemTitle>
          <ItemDescription
            className="fontlink"
            onClick={() =>
              handleDownload(branding.brandingName, branding.brandingUrl)
            }
          >
            {branding.brandingName}
          </ItemDescription>
        </LineItem>
      </MainContent>
    </MainContainer>
  );
};

export default Branding;
