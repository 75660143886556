import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import AssociatesListItem from "./AssociatesListItem";

const MainContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const ColTitleRow = styled.div`
  display: flex;
`;

const EmailCol = styled.div`
  width: calc(50% - 5px);
`;

const RoleCol = styled.div`
  width: calc(30% - 5px);
  text-align: center;
`;

const StatusCol = styled.div`
  width: 20%;
  text-align: right;
`;

const ItemTitle = styled.div`
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #6371de;
  margin-bottom: 5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const AssociatesList = () => {
  const [roleDocs, setRoleDocs] = useState();

  const { adminDocs } = useAuthContext();

  useEffect(() => {
    if (!adminDocs || !adminDocs[0]) return;

    setRoleDocs(adminDocs[0].roles);
  }, [adminDocs]);

  return (
    <MainContainer>
      <ColTitleRow>
        <EmailCol>
          <ItemTitle>Email</ItemTitle>
        </EmailCol>
        <RoleCol>
          <ItemTitle>Role</ItemTitle>
        </RoleCol>
        <StatusCol>
          <ItemTitle>Status</ItemTitle>
        </StatusCol>
      </ColTitleRow>

      <Divider />

      {roleDocs &&
        roleDocs.map((role) => {
          return <AssociatesListItem key={role.email} role={role} />;
        })}
    </MainContainer>
  );
};

export default AssociatesList;
