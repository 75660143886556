import React from "react";

import styled from "styled-components";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const LeftCol = styled.div``;

const RightCol = styled.div``;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const LineItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
`;

const ItemDescription = styled.div`
  margin-right: 5px;
  text-transform: capitalize;
`;

const SocialMedia = ({ smAcc }) => {
  return (
    <MainContainer>
      <SecTitle>Social Media Accounts</SecTitle>
      <MainContent>
        <LeftCol>
          <LineItem>
            <ItemTitle>Facebook:</ItemTitle>
            <ItemDescription>{smAcc.facebook}</ItemDescription>
          </LineItem>
          <LineItem>
            <ItemTitle>Instagram:</ItemTitle>
            <ItemDescription>{smAcc.instagram}</ItemDescription>
          </LineItem>
          <LineItem>
            <ItemTitle>Twitter:</ItemTitle>
            <ItemDescription>{smAcc.twitter}</ItemDescription>
          </LineItem>
        </LeftCol>

        <RightCol>
          <LineItem>
            <ItemTitle>YouTube:</ItemTitle>
            <ItemDescription>{smAcc.youtube}</ItemDescription>
          </LineItem>
          <LineItem>
            <ItemTitle>Linkedin:</ItemTitle>
            <ItemDescription>{smAcc.linkedin}</ItemDescription>
          </LineItem>
          <LineItem>
            <ItemTitle>TikTok:</ItemTitle>
            <ItemDescription>{smAcc.tiktok}</ItemDescription>
          </LineItem>
        </RightCol>
      </MainContent>
    </MainContainer>
  );
};

export default SocialMedia;
