import React from "react";

import styled from "styled-components";
import SignupForm from "../components/signForms/SignupForm";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import SignedOutDashboard from "../components/signed-out-dashboard/SignedOutDashboard";

const Back = styled.div`
  position: relative;
  opacity: 0.3;
`;

const Front = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SignUp = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  setViewAccNav(false);
  setViewAdminNav(false);

  return (
    <>
      <Back>
        <SignedOutDashboard />
      </Back>
      <Front>
        <SignupForm />
      </Front>
    </>
  );
};

export default SignUp;
