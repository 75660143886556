import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  onSnapshot,
  doc,
  associatesCollection,
} from "../../firebase";

import styled from "styled-components";

import { useAuthContext } from "../../context/auth-context";

const FormContainer = styled.div`
  width: 95%;
  max-width: 600px;
  background-color: white;
  padding: 50px;
  border-radius: 5px;
  margin: auto;
  border: 1px solid #e4e6f2;

  form {
    min-width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const FormTitle = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  color: #6371de;
  margin-bottom: 40px;
`;

const FormContent = styled.div`
  box-sizing: border-box;
`;

const Input = styled.input`
  height: 37px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: 1px solid gray;
`;

const MNItemGetStartedContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MNItemGetStarted = styled.button`
  width: 183px;
  height: 50px;
  background-color: #6371de;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  border: none;
  margin-top: 10px;
  cursor: pointer;
`;

const FormBottomLinkContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const FormBottomLinkMsg = styled.div``;

const FormBottomLinkLink = styled.span`
  margin-left: 5px;
  text-decoration: underline;
  color: #6371de;
  cursor: pointer;
`;

const ErrContainer = styled.div`
  height: 20px;
  margin-top: 15px;
`;

const ErrMsg = styled.div`
  color: red;
  text-align: center;
  font-size: 12px;
`;

const SigninForm = ({ signView, setSignView }) => {
  const { authUser, user, setUser, setLogged, setAssociateLogged } =
    useAuthContext();

  const [logError, setLogError] = useState("Wrong Username/Password!");

  const history = useHistory();

  useEffect(() => {
    if (signView === "signin" && authUser && user) {
      history.push("/admin-dashboard");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, user, signView]);

  // Listen to user info update (Firestore)
  // Query user info
  useEffect(() => {
    if (!authUser) {
      setUser(null);
      return;
    }

    if (authUser) {
      setLogged(true);
    }

    const unsubscribe = onSnapshot(
      doc(associatesCollection, authUser.uid),
      (doc) => {
        if (doc.exists()) {
          const userData = doc.data();

          setUser({
            id: doc.id,
            assigned: userData.assigned,
            email: userData.email,
            role: userData.role,
            name: userData.name,
          });
        }
      }
    );

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleLogError = () => {
    document.querySelector("#logError").classList.remove("hide");
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
      setAssociateLogged(true);
      if (user) history.push("/admin-dashboard");
    } catch (e) {
      if (e.message.includes("invalid") || e.message.includes("wrong")) {
        setLogError("Wrong Username/Password!");
        handleLogError();
      } else if (e.message.includes("user-not-found")) {
        setLogError("No Account Found!");
        handleLogError();
      } else {
        console.log(e.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContainer>
      <FormTitle>Login</FormTitle>
      <FormContent>
        <form onSubmit={handleSubmit}>
          <Input name="email" placeholder="Email*" type="email" />
          <Input name="password" placeholder="Password*" type="password" />
          <MNItemGetStartedContainer>
            <MNItemGetStarted type="submit">Login</MNItemGetStarted>
          </MNItemGetStartedContainer>
        </form>
      </FormContent>
      <FormBottomLinkContainer>
        <FormBottomLinkMsg>
          Don't have an account?{" "}
          <FormBottomLinkLink
            onClick={() => {
              setSignView("signup");
            }}
          >
            Sign up
          </FormBottomLinkLink>
        </FormBottomLinkMsg>
      </FormBottomLinkContainer>
      <ErrContainer>
        <ErrMsg id="logError" className="hide">
          {logError}
        </ErrMsg>
      </ErrContainer>
    </FormContainer>
  );
};

export default SigninForm;
