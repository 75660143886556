import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAuthContext } from "../../context/auth-context";
import { useViewUserDropdownContext } from "../../context/view-user-dropdown-context";

import styled from "styled-components";
import { IoPersonCircleSharp } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import UserDropDown from "./UserDropDown";

const MainNavContainer = styled.div`
  height: 60px;
  background-color: white;
`;

const MNContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(59, 61, 80, 0.16);
`;

const MNLeft = styled.div`
  height: 32px;
`;

const MNLogo = styled.div`
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const MNRight = styled.div`
  display: flex;
  height: 40px;
`;

const MNUser = styled.div`
  width: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-left: 10px;
  padding-right: 35px;
  color: #3b3d50;
  font-size: 19px;
  transform: translateY(-1px);
  opacity: 0.7;
`;

const DropdownIcon = styled.div`
  cursor: pointer;
`;

const UserColContents = styled.div`
  display: flex;
  transform: translateY(8px);
`;

const SubscribeLink = styled.div`
  color: #6371de;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  margin-right: 10px;
`;

const Icon = styled.div`
  font-size: 2rem;
  color: #c8c9ce;
  transform: translateY(-7.5px);
  margin-right: 7px;
`;

const Divider = styled.div`
  width: 1px;
  height: 21px;
  background: #e6e8f2;
  box-shadow: 0px 0px 20px rgba(59, 61, 80, 0.16);
  margin-right: 7px;
`;

const UserName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #3b3d50;
  opacity: 0.7;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 7px;
`;

const MainNav = () => {
  const { user } = useAuthContext();
  const { viewUserDropdown, setViewUserDropdown } =
    useViewUserDropdownContext();

  const [dashboardPath, setDashboardPath] = useState("");

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!user) return;

    if (
      user.role === "super-admin" ||
      user.role === "writer1" ||
      user.role === "writer2"
    ) {
      setDashboardPath("/admin-dashboard");
    } else if (user.role === "client") {
      setDashboardPath("/dashboard");
    } else {
      setDashboardPath("");
    }
  }, [user]);

  return (
    <MainNavContainer>
      <MNContent>
        <MNLeft>
          <MNLogo className="logo" onClick={() => history.push("/")}></MNLogo>
        </MNLeft>
        <MNRight>
          {
            <>
              <UserColContents>
                {user && user.role === "client" ? (
                  <>
                    <Icon>
                      {user && user.imageUrl ? (
                        <ProfileImage src={user.imageUrl} />
                      ) : (
                        <IoPersonCircleSharp />
                      )}
                    </Icon>
                    <UserName>{user.fullname}</UserName>
                  </>
                ) : location.pathname === "/admin-dashboard" ? (
                  <UserName>
                    {user && user.fullname ? user.fullname : undefined}
                  </UserName>
                ) : user ? (
                  <UserName
                    style={{ cursor: "pointer", height: "20px" }}
                    onClick={() => history.push(dashboardPath)}
                  >
                    Dashboard
                  </UserName>
                ) : undefined}
              </UserColContents>

              {user ? (
                <MNUser>
                  <DropdownIcon
                    on
                    onClick={() => setViewUserDropdown(!viewUserDropdown)}
                  >
                    {viewUserDropdown ? (
                      <IoMdArrowDropup className="mainNavIcons" />
                    ) : (
                      <IoMdArrowDropdown className="mainNavIcons" />
                    )}
                  </DropdownIcon>
                </MNUser>
              ) : undefined}
            </>
          }
        </MNRight>
      </MNContent>
      {viewUserDropdown ? <UserDropDown /> : undefined}
    </MainNavContainer>
  );
};

export default MainNav;
