import { getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut, deleteUser, updatePassword } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState, useEffect, useContext, createContext } from 'react'
import {
  getFirestore,
  collection,
  setDoc,
  getDoc,
  deleteDoc,
  serverTimestamp,
  query,
  where,
  doc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import {getStorage} from 'firebase/storage'

export const firebaseConfig = {
  apiKey: "AIzaSyDmzoHujtOfafD9Z6y3I4ZT91Xfk7hmJDA",
  authDomain: "contentdrive-6147b.firebaseapp.com",
  projectId: "contentdrive-6147b",
  storageBucket: "contentdrive-6147b.appspot.com",
  messagingSenderId: "790525391026",
  appId: "1:790525391026:web:96c9035fa9d9756e7899ab"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore()
export const functions = getFunctions(app)
export const usersCollection = collection(db, "users");
export const ordersCollection = collection(db, "orders");
export const orderIdCollection = collection(db, "order-id");
export const podsCollection = collection(db, "pods");
export const adminCollection = collection(db, "admin");
export const associatesCollection = collection(db, "associates");
export const AuthContext = createContext()
export const storage = getStorage(app)

export {
  getAuth,
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  where,
  onSnapshot,
  serverTimestamp,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  deleteUser,
  updatePassword,
  orderBy,
  query,
  httpsCallable,
};
