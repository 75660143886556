import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useOrdersContext } from "../../context/orders-context";
import { useAuthContext } from "../../context/auth-context";

const MainContainer = styled.div`
  width: 100%;
  min-width: 870px;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e6e8f2;
  border-top: 0;
  border-radius: 8px 0px 0px 0px;
`;

const LeftCol = styled.div`
  width: 316px;
  margin-right: 50px;
  border-radius: 8px 8px 8px 0px;
`;

const RightCol = styled.div`
  width: 100%;
`;

const RightColContent = styled.div`
  margin: auto;
  width: 500px;
  height: 485px;
  position: relative;
`;

const TypeTitleRow = styled.div`
  width: 300px;
  display: flex;
  :not(:last-child) {
    border-bottom: 1px solid #e6e8f2;
  }
`;

const MainTitle = styled.div`
  width: 316px;
  height: 60px;
  background: linear-gradient(90deg, #303a8b 0%, #323d8f 100%);
  border-radius: 8px 8px 0px 0px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TypeTitle = styled.div`
  font-family: "GT Walsheim Pro";
  font-size: 15px;
  color: #3b3d50;
  height: 70px;
  display: flex;
  align-items: center;

  &.business {
    height: 42px;
  }
`;

const TypeYellow = styled.div`
  width: 4px;
  height: 70px;
  background-color: yellow;
  margin-right: 10px;

  &.business {
    height: 42px;
  }
`;

const TypeGreen = styled.div`
  width: 4px;
  height: 70px;
  background-color: green;
  margin-right: 10px;

  &.business {
    height: 42px;
  }
`;

const TypeOrange = styled.div`
  width: 4px;
  height: 70px;
  background-color: orange;
  margin-right: 10px;

  &.business {
    height: 42px;
  }
`;

const TypeBlue = styled.div`
  width: 4px;
  height: 70px;
  background-color: blue;
  margin-right: 10px;

  &.business {
    height: 42px;
  }
`;

const TypeRed = styled.div`
  width: 4px;
  height: 70px;
  background-color: red;
  margin-right: 10px;

  &.business {
    height: 42px;
  }
`;

const TypePurple = styled.div`
  width: 4px;
  height: 70px;
  background-color: purple;
  margin-right: 10px;

  &.business {
    height: 42px;
  }
`;

const YellowBar = styled.div`
  width: 50px;
  background-color: yellow;
  position: absolute;
  bottom: 0;
  left: 20px;

  &.business {
    width: 20px;
  }
`;

const GreenBar = styled.div`
  width: 50px;
  background-color: green;
  position: absolute;
  bottom: 0;
  left: 90px;

  &.business {
    width: 20px;
    left: 60px;
  }
`;

const OrangeBar = styled.div`
  width: 50px;
  background-color: orange;
  position: absolute;
  bottom: 0;
  left: 160px;

  &.business {
    width: 20px;
    left: 100px;
  }
`;

const BlueBar = styled.div`
  width: 50px;
  background-color: blue;
  position: absolute;
  bottom: 0;
  left: 230px;

  &.business {
    width: 20px;
    left: 140px;
  }
`;

const RedBar = styled.div`
  width: 50px;
  background-color: Red;
  position: absolute;
  bottom: 0;
  left: 300px;

  &.business {
    width: 20px;
    left: 180px;
  }
`;

const PurpleBar = styled.div`
  width: 50px;
  background-color: purple;
  position: absolute;
  bottom: 0;
  left: 370px;

  &.business {
    width: 20px;
    left: 220px;
  }
`;

const TypeCount1 = styled.div`
  width: 50px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 20px;

  &.business {
    width: 20px;
  }
`;

const TypeCount2 = styled.div`
  width: 50px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 90px;

  &.business {
    width: 20px;
    left: 60px;
  }
`;

const TypeCount3 = styled.div`
  width: 50px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 160px;

  &.business {
    width: 20px;
    left: 100px;
  }
`;

const TypeCount4 = styled.div`
  width: 50px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 230px;

  &.business {
    width: 20px;
    left: 140px;
  }
`;

const TypeCount5 = styled.div`
  width: 50px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 300px;

  &.business {
    width: 20px;
    left: 180px;
  }
`;

const TypeCount6 = styled.div`
  width: 50px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 370px;

  &.business {
    width: 20px;
    left: 220px;
  }
`;

const MidBar1 = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 45px;

  &.business {
    left: 30px;
  }
`;

const MidBar2 = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 115px;

  &.business {
    left: 70px;
  }
`;

const MidBar3 = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 185px;

  &.business {
    left: 110px;
  }
`;

const MidBar4 = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 255px;

  &.business {
    left: 150px;
  }
`;

const MidBar5 = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 325px;

  &.business {
    left: 190px;
  }
`;

const MidBar6 = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 395px;

  &.business {
    left: 230px;
  }
`;

// ----business

const CaseStudyType = styled.div`
  width: 4px;
  height: 42px;
  background-color: yellow;
  margin-right: 10px;
`;

const MarketResearchType = styled.div`
  width: 4px;
  height: 42px;
  background-color: green;
  margin-right: 10px;
`;

const MarketTrendsType = styled.div`
  width: 4px;
  height: 42px;
  background-color: orange;
  margin-right: 10px;
`;

const PressReleaseType = styled.div`
  width: 4px;
  height: 42px;
  background-color: blue;
  margin-right: 10px;
`;

const CaseStudyBar = styled.div`
  width: 20px;
  background-color: yellow;
  position: absolute;
  bottom: 0;
  left: 260px;
`;

const MarketResearchBar = styled.div`
  width: 20px;
  background-color: green;
  position: absolute;
  bottom: 0;
  left: 300px;
`;

const MarketTrendsBar = styled.div`
  width: 20px;
  background-color: orange;
  position: absolute;
  bottom: 0;
  left: 340px;
`;

const PressReleaseBar = styled.div`
  width: 20px;
  background-color: blue;
  position: absolute;
  bottom: 0;
  left: 380px;
`;

const CaseStudyCount = styled.div`
  width: 20px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 260px;
`;

const MarketResearchCount = styled.div`
  width: 20px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 300px;
`;

const MarketTrendsCount = styled.div`
  width: 20px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 340px;
`;

const PressReleaseCount = styled.div`
  width: 20px;
  text-align: center;
  color: gray;
  font-size: 12px;
  position: absolute;
  bottom: 415px;
  left: 380px;
`;

const CaseStudyMidBar = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 270px;
`;

const MarketResearchMidBar = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 310px;
`;

const MarketTrendsMidBar = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 350px;
`;

const PressReleaseMidBar = styled.div`
  width: 1px;
  height: 410px;
  background-color: #e6e8f2;
  position: absolute;
  bottom: 0;
  left: 390px;
`;

const Charts = () => {
  const { orders } = useOrdersContext();
  const { user } = useAuthContext();

  const [typeCount, setTypeCount] = useState({
    Blog: 0,
    "Social Media Copy": 0,
    Email: 0,
    Article: 0,
    "Website Copy": 0,
    eBook: 0,
  });
  const [barsHeight, setBarsHeight] = useState({
    Blog: 1,
    "Social Media Copy": 1,
    Email: 1,
    Article: 1,
    "Website Copy": 1,
    eBook: 1,
  });

  useEffect(() => {
    if (!user) return;

    if (user.planName === "business") {
      setTypeCount({
        Blog: 0,
        "Social Media Copy": 0,
        Email: 0,
        Article: 0,
        "Website Copy": 0,
        eBook: 0,
        "Case Study": 0,
        "Market Research": 0,
        "Market Trends": 0,
        "Press Release": 0,
      });

      setBarsHeight({
        Blog: 1,
        "Social Media Copy": 1,
        Email: 1,
        Article: 1,
        "Website Copy": 1,
        eBook: 1,
        "Case Study": 1,
        "Market Research": 1,
        "Market Trends": 1,
        "Press Release": 1,
      });
    } else {
      setTypeCount({
        Blog: 0,
        "Social Media Copy": 0,
        Email: 0,
        Article: 0,
        "Website Copy": 0,
        eBook: 0,
      });

      setBarsHeight({
        Blog: 1,
        "Social Media Copy": 1,
        Email: 1,
        Article: 1,
        "Website Copy": 1,
        eBook: 1,
      });
    }
  }, [user]);

  useEffect(() => {
    if (orders.length < 1 || !user) return;

    let orderTypeCounts = {};

    if (user.planName === "business") {
      orderTypeCounts = {
        Blog: 0,
        "Social Media Copy": 0,
        Email: 0,
        Article: 0,
        "Website Copy": 0,
        eBook: 0,
        "Case Study": 0,
        "Market Research": 0,
        "Market Trends": 0,
        "Press Release": 0,
      };
    } else {
      orderTypeCounts = {
        Blog: 0,
        "Social Media Copy": 0,
        Email: 0,
        Article: 0,
        "Website Copy": 0,
        eBook: 0,
      };
    }

    orders.forEach((order) => {
      if (Object.keys(orderTypeCounts).includes(order.type)) {
        orderTypeCounts = {
          ...orderTypeCounts,
          [order.type]: orderTypeCounts[order.type] + 1,
        };
      }
    });

    setTypeCount(orderTypeCounts);
  }, [orders, user]);

  useEffect(() => {
    const fixedHeighest = 400;
    let tempHighest = 0;
    let tempHighestIndex = 0;
    const tempTypeArr = [];
    const tempCalculatedHeights = [];

    Object.entries(typeCount).forEach((item) => {
      tempTypeArr.push(item[1]);
    });

    tempHighest = Math.max.apply(null, tempTypeArr);
    tempHighestIndex = tempTypeArr.indexOf(Math.max.apply(null, tempTypeArr));

    if (tempHighest > fixedHeighest) {
      const tempDivider = tempHighest / fixedHeighest;

      for (let i = 0; i < tempTypeArr.length; i++) {
        if (i === tempHighestIndex) {
          tempCalculatedHeights.push(fixedHeighest);
        } else {
          tempCalculatedHeights.push(tempTypeArr[i] / tempDivider);
        }
      }
    } else {
      const tempDivider = fixedHeighest / tempHighest;

      for (let i = 0; i < tempTypeArr.length; i++) {
        if (i === tempHighestIndex) {
          tempCalculatedHeights.push(fixedHeighest);
        } else {
          tempCalculatedHeights.push(tempTypeArr[i] * tempDivider);
        }
      }
    }

    let calculatedHeights = {};

    if (user.planName === "business") {
      calculatedHeights = {
        Blog: tempCalculatedHeights[0] > 0 ? tempCalculatedHeights[0] : 3,
        "Social Media Copy":
          tempCalculatedHeights[1] > 0 ? tempCalculatedHeights[1] : 3,
        Email: tempCalculatedHeights[2] > 0 ? tempCalculatedHeights[2] : 3,
        Article: tempCalculatedHeights[3] > 0 ? tempCalculatedHeights[3] : 3,
        "Website Copy":
          tempCalculatedHeights[4] > 0 ? tempCalculatedHeights[4] : 3,
        eBook: tempCalculatedHeights[5] > 0 ? tempCalculatedHeights[5] : 3,

        "Case Study":
          tempCalculatedHeights[6] > 0 ? tempCalculatedHeights[6] : 3,
        "Market Research":
          tempCalculatedHeights[7] > 0 ? tempCalculatedHeights[7] : 3,
        "Market Trends":
          tempCalculatedHeights[8] > 0 ? tempCalculatedHeights[8] : 3,
        "Press Release":
          tempCalculatedHeights[9] > 0 ? tempCalculatedHeights[9] : 3,
      };
    } else {
      calculatedHeights = {
        Blog: tempCalculatedHeights[0] > 0 ? tempCalculatedHeights[0] : 3,
        "Social Media Copy":
          tempCalculatedHeights[1] > 0 ? tempCalculatedHeights[1] : 3,
        Email: tempCalculatedHeights[2] > 0 ? tempCalculatedHeights[2] : 3,
        Article: tempCalculatedHeights[3] > 0 ? tempCalculatedHeights[3] : 3,
        "Website Copy":
          tempCalculatedHeights[4] > 0 ? tempCalculatedHeights[4] : 3,
        eBook: tempCalculatedHeights[5] > 0 ? tempCalculatedHeights[5] : 3,
      };
    }

    setBarsHeight(calculatedHeights);
  }, [typeCount, user]);

  return (
    <>
      {user && (
        <MainContainer>
          {user.planName === "business" ? (
            <MainContent>
              <LeftCol>
                <MainTitle>Content States</MainTitle>
                <TypeTitleRow>
                  <TypeYellow className="business" />
                  <TypeTitle className="business">Blog</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeGreen className="business" />
                  <TypeTitle className="business">Social Media Copy</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeOrange className="business" />
                  <TypeTitle className="business">Email</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeBlue className="business" />
                  <TypeTitle className="business">Article</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeRed className="business" />
                  <TypeTitle className="business">Website Copy</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypePurple className="business" />
                  <TypeTitle className="business">eBook</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <CaseStudyType />
                  <TypeTitle className="business">Case Study</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <MarketResearchType />
                  <TypeTitle className="business">Market Research</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <MarketTrendsType />
                  <TypeTitle className="business">Market Trends</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <PressReleaseType />
                  <TypeTitle className="business">Press Release</TypeTitle>
                </TypeTitleRow>
              </LeftCol>
              <RightCol>
                <RightColContent>
                  <TypeCount1 className="business">
                    {typeCount["Blog"]}
                  </TypeCount1>
                  <TypeCount2 className="business">
                    {typeCount["Social Media Copy"]}
                  </TypeCount2>
                  <TypeCount3 className="business">
                    {typeCount["Email"]}
                  </TypeCount3>
                  <TypeCount4 className="business">
                    {typeCount["Article"]}
                  </TypeCount4>
                  <TypeCount5 className="business">
                    {typeCount["Website Copy"]}
                  </TypeCount5>
                  <TypeCount6 className="business">
                    {typeCount["eBook"]}
                  </TypeCount6>
                  <MidBar1 className="business" />
                  <MidBar2 className="business" />
                  <MidBar3 className="business" />
                  <MidBar4 className="business" />
                  <MidBar5 className="business" />
                  <MidBar6 className="business" />
                  <YellowBar
                    className="business"
                    style={{ height: `${barsHeight["Blog"]}px` }}
                  />
                  <GreenBar
                    className="business"
                    style={{ height: `${barsHeight["Social Media Copy"]}px` }}
                  />
                  <OrangeBar
                    className="business"
                    style={{ height: `${barsHeight["Email"]}px` }}
                  />
                  <BlueBar
                    className="business"
                    style={{ height: `${barsHeight["Article"]}px` }}
                  />
                  <RedBar
                    className="business"
                    style={{ height: `${barsHeight["Website Copy"]}px` }}
                  />
                  <PurpleBar
                    className="business"
                    style={{ height: `${barsHeight["eBook"]}px` }}
                  />

                  <CaseStudyCount>{typeCount["Case Study"]}</CaseStudyCount>
                  <MarketResearchCount>
                    {typeCount["Market Research"]}
                  </MarketResearchCount>
                  <MarketTrendsCount>
                    {typeCount["Market Trends"]}
                  </MarketTrendsCount>
                  <PressReleaseCount>
                    {typeCount["Press Release"]}
                  </PressReleaseCount>
                  <CaseStudyMidBar />
                  <MarketResearchMidBar />
                  <MarketTrendsMidBar />
                  <PressReleaseMidBar />
                  <CaseStudyBar
                    style={{ height: `${barsHeight["Case Study"]}px` }}
                  />
                  <MarketResearchBar
                    style={{ height: `${barsHeight["Market Research"]}px` }}
                  />
                  <MarketTrendsBar
                    style={{ height: `${barsHeight["Market Trends"]}px` }}
                  />
                  <PressReleaseBar
                    style={{ height: `${barsHeight["Press Release"]}px` }}
                  />
                </RightColContent>
              </RightCol>
            </MainContent>
          ) : (
            <MainContent>
              <LeftCol>
                <MainTitle>Content States</MainTitle>
                <TypeTitleRow>
                  <TypeYellow />
                  <TypeTitle>Blog</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeGreen />
                  <TypeTitle>Social Media Copy</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeOrange />
                  <TypeTitle>Email</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeBlue />
                  <TypeTitle>Article</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypeRed />
                  <TypeTitle>Website Copy</TypeTitle>
                </TypeTitleRow>

                <TypeTitleRow>
                  <TypePurple />
                  <TypeTitle>eBook</TypeTitle>
                </TypeTitleRow>
              </LeftCol>
              <RightCol>
                <RightColContent>
                  <TypeCount1>{typeCount["Blog"]}</TypeCount1>
                  <TypeCount2>{typeCount["Social Media Copy"]}</TypeCount2>
                  <TypeCount3>{typeCount["Email"]}</TypeCount3>
                  <TypeCount4>{typeCount["Article"]}</TypeCount4>
                  <TypeCount5>{typeCount["Website Copy"]}</TypeCount5>
                  <TypeCount6>{typeCount["eBook"]}</TypeCount6>
                  <MidBar1 />
                  <MidBar2 />
                  <MidBar3 />
                  <MidBar4 />
                  <MidBar5 />
                  <MidBar6 />
                  <YellowBar style={{ height: `${barsHeight["Blog"]}px` }} />
                  <GreenBar
                    style={{ height: `${barsHeight["Social Media Copy"]}px` }}
                  />
                  <OrangeBar style={{ height: `${barsHeight["Email"]}px` }} />
                  <BlueBar style={{ height: `${barsHeight["Article"]}px` }} />
                  <RedBar
                    style={{ height: `${barsHeight["Website Copy"]}px` }}
                  />
                  <PurpleBar style={{ height: `${barsHeight["eBook"]}px` }} />
                </RightColContent>
              </RightCol>
            </MainContent>
          )}
        </MainContainer>
      )}
    </>
  );
};

export default Charts;
