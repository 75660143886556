import React from "react";

import styled from "styled-components";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import CompanyNav from "../components/nav/CompanyNav";
import CurrentPlan from "../components/edit-subscription/main-cards/CurrentPlan";
import PlanFeatures from "../components/edit-subscription/main-cards/PlanFeatures";
import PaymentCard from "../components/edit-subscription/main-cards/PaymentCard";
import Invoices from "../components/edit-subscription/main-cards/Invoices";

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4px);
  margin-top: 20px;
`;

const PageLeftCol = styled.div`
  width: 300px;
  height: calc(100vh - 80px);
  position: relative;
`;

const PageRightCol = styled.div`
  width: calc(100% - 300px);
  margin: 15px;

  @media (max-width: 990px) {
    margin-left: 20px;
  }
`;

const PageContent = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  overflow-y: scroll;
`;

const ContentRow = styled.div`
  display: flex;
  margin-bottom: 10px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const ContentLeft = styled.div`
  width: calc(100% / 3 - 10px);
  margin-right: 10px;
`;

const ContentRight = styled.div`
  width: calc((100% / 3) * 2);
`;

const EditSubscription = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  setViewAccNav(true);
  setViewAdminNav(false);

  return (
    <PageContainer>
      <PageLeftCol>
        <CompanyNav />
      </PageLeftCol>
      <PageRightCol>
        <PageContent>
          <ContentRow>
            <ContentLeft>
              <CurrentPlan />
            </ContentLeft>
            <ContentRight>
              <PlanFeatures />
            </ContentRight>
          </ContentRow>
          <ContentRow>
            <ContentLeft>
              <PaymentCard />
            </ContentLeft>
            <ContentRight>
              <Invoices />
            </ContentRight>
          </ContentRow>
        </PageContent>
      </PageRightCol>
    </PageContainer>
  );
};

export default EditSubscription;
