import React, { useState, useEffect } from "react";

import styled from "styled-components";
import DeliveredContents from "../components/content-center/DeliveredContents";
import InProgressContents from "../components/content-center/InProgressContents";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import { orderTypes } from "../helper";
import { BsSearch } from "react-icons/bs";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 90%;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  background-color: white;
  padding: 20px 15px;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const FilterRow = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const FilterLeftCol = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterRightCol = styled.div`
  color: #8e8e93;
`;

const FilterLabel = styled.div`
  color: #767784;
  font-size: 15px;
  line-height: 17.17px;
  margin-right: 10px;
`;

const Dropdown = styled.select`
  width: 343px;
  height: 40px;
  background: #fafbfe;
  border: 1px solid #e4e6f2;
  box-sizing: border-box;
  border-radius: 6px;
  color: #767784;
  padding-left: 5px;
  padding-right: 5px;
`;

const SearchInput = styled.input`
  width: 343px;
  height: 40px;
  background: #fafbfe;
  border: 1px solid #e4e6f2;
  box-sizing: border-box;
  border-radius: 6px;
  color: #767784;
  padding-left: 15px;
  padding-right: 37px;
`;

const SearchIcon = styled.div`
  display: inline-block;
  transform: translate(-31px, 2px);
`;

const StatusBarContainer = styled.div`
  border: 1px solid #e4e6f2;
  box-sizing: border-box;
  padding: 2px;
  box-shadow: 0px 0px 22px rgba(59, 61, 80, 0.1);
  border-radius: 6px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StatusItem = styled.div`
  background: white;
  color: #6371de;
  width: calc(50% - 4px);
  height: 36px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.selectedStatus {
    background: #6371de;
    color: white;
  }
`;

const StatusDivider = styled.div`
  width: 3px;
  height: 28px;
  background: #e4e6f2;
  border-radius: 5px;
`;

const ContentCenter = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const [selectedStatus, setSelectedStatus] = useState("inprogress");
  const [filteredType, setFilteredType] = useState("All");
  const [searchedValue, setSearchValue] = useState("");

  setViewAccNav(true);
  setViewAdminNav(false);

  const handleInputChange = (e) => {
    setFilteredType(e.target.value);
  };

  const handlesearchvalue = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const storedStatus = localStorage.getItem("status");
    if (!storedStatus) return;

    setSelectedStatus(storedStatus);
    localStorage.removeItem("status");
  }, []);

  return (
    <MainContainer>
      <Heading>content center</Heading>

      <FilterRow>
        <FilterLeftCol>
          <FilterLabel>Filter by</FilterLabel>
          <Dropdown
            name="orderType"
            onChange={handleInputChange}
            value={filteredType}
          >
            {/* <option value="" selected="selected" disabled="disabled">
                Request Type
              </option> */}
            <option value="All">All</option>

            {orderTypes.map((item, i) => {
              return (
                <option key={i * 2} value={item}>
                  {item}
                </option>
              );
            })}
          </Dropdown>
        </FilterLeftCol>

        <FilterRightCol>
          <SearchInput
            type="text"
            name="requestnamesearch"
            onChange={handlesearchvalue}
            placeholder="Search"
            value={searchedValue}
          />
          <SearchIcon>
            <BsSearch />
          </SearchIcon>
        </FilterRightCol>
      </FilterRow>

      <StatusBarContainer>
        <StatusItem
          className={
            selectedStatus === "inprogress" ? "selectedStatus" : undefined
          }
          onClick={() => setSelectedStatus("inprogress")}
        >
          In Progress
        </StatusItem>
        <StatusDivider />
        <StatusItem
          className={
            selectedStatus === "delivered" ? "selectedStatus" : undefined
          }
          onClick={() => setSelectedStatus("delivered")}
        >
          Delivered
        </StatusItem>
      </StatusBarContainer>

      {selectedStatus === "inprogress" ? (
        <InProgressContents
          role="client"
          filteredType={filteredType}
          searchedValue={searchedValue}
          setFilteredType={setFilteredType}
          setSearchValue={setSearchValue}
        />
      ) : (
        <DeliveredContents
          role="client"
          filteredType={filteredType}
          searchedValue={searchedValue}
          setFilteredType={setFilteredType}
          setSearchValue={setSearchValue}
        />
      )}
    </MainContainer>
  );
};

export default ContentCenter;
