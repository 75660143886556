import React, { useEffect } from "react";

import ConfirmedPayment from "../components/create-subscription/ConfirmedPayment";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import { useAuthContext } from "../context/auth-context";
import { setDoc, doc, db } from "../firebase";

const SubscriptionConfirmation = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();
  const { user } = useAuthContext();

  setViewAccNav(false);
  setViewAdminNav(false);

  useEffect(() => {
    if (user) {
      const status = new URLSearchParams(window.location.search).get(
        "redirect_status"
      );

      if (status === "succeeded") {
        // Remove query string
        window.history.pushState({}, "", "/");

        // Update user's subsription status in Firestore
        return setDoc(
          doc(db, "users", user.id),
          {
            status: "subscribed",
          },
          { merge: true }
        );
      }
    }
  }, [user]);

  return <ConfirmedPayment />;
};

export default SubscriptionConfirmation;
