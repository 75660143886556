import React, { createContext, useContext, useState } from "react";

const SubStateContext = createContext(undefined);
const SubDispatchContext = createContext(undefined);

const SubContextProvider = ({ children }) => {
  const [sub, setSub] = useState({interval: '', price: ''});

  return (
    <SubStateContext.Provider value={sub}>
      <SubDispatchContext.Provider value={setSub}>
        {children}
      </SubDispatchContext.Provider>
    </SubStateContext.Provider>
  );
};

export default SubContextProvider;

export const useSubContext = () => {
  const sub = useContext(SubStateContext);
  const setSub = useContext(SubDispatchContext);

  if (sub === undefined || setSub === undefined)
    throw new Error("useSubContext must be used within the SubContextProvider");

  return { sub, setSub };
};
