import React from "react";

import styled from "styled-components";

import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";

const MainContainer = styled.div`
  width: 292px;
  height: 170px;
  border: 1px solid #e6e8f2;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  margin-bottom: 24px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #3b3d50;
  color: #3b3d50;
  opacity: 0.7;
  margin-bottom: 8px;
`;

const StarsContainer = styled.div`
  width: 197px;
  height: 50px;
  background: #f4f6ff;
  border-radius: 5px;
  padding: 13px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubTitleText = styled.div``;

const WhiteStar = styled.div`
  font-size: 25px;
  color: #767784;
  cursor: pointer;
`;

const YellowStar = styled.div`
  font-size: 25px;
  color: #ffc107;
  cursor: pointer;
`;

const Priority = ({ setPriority, activeStars, setActiveStars }) => {
  const handleStarClick = (num) => {
    let tempArr = [false, false, false, false, false];

    for (let i = 0; i <= num; i++) {
      tempArr.splice(i, 1, true);
    }

    setPriority(num + 1);
    setActiveStars(tempArr);
  };

  return (
    <MainContainer>
      <Title>Priority</Title>
      <SubTitle>
        <SubTitleText>Prioritize This Request</SubTitleText>
      </SubTitle>
      <StarsContainer>
        {activeStars[0] ? (
          <YellowStar onClick={() => handleStarClick(0)}>
            <FaStar />
          </YellowStar>
        ) : (
          <WhiteStar onClick={() => handleStarClick(0)}>
            <FaRegStar />
          </WhiteStar>
        )}
        {activeStars[1] ? (
          <YellowStar onClick={() => handleStarClick(1)}>
            <FaStar />
          </YellowStar>
        ) : (
          <WhiteStar onClick={() => handleStarClick(1)}>
            <FaRegStar />
          </WhiteStar>
        )}
        {activeStars[2] ? (
          <YellowStar onClick={() => handleStarClick(2)}>
            <FaStar />
          </YellowStar>
        ) : (
          <WhiteStar onClick={() => handleStarClick(2)}>
            <FaRegStar />
          </WhiteStar>
        )}
        {activeStars[3] ? (
          <YellowStar onClick={() => handleStarClick(3)}>
            <FaStar />
          </YellowStar>
        ) : (
          <WhiteStar onClick={() => handleStarClick(3)}>
            <FaRegStar />
          </WhiteStar>
        )}
        {activeStars[4] ? (
          <YellowStar onClick={() => handleStarClick(4)}>
            <FaStar />
          </YellowStar>
        ) : (
          <WhiteStar onClick={() => handleStarClick(4)}>
            <FaRegStar />
          </WhiteStar>
        )}
      </StarsContainer>
    </MainContainer>
  );
};

export default Priority;
