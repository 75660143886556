import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ListRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`;

const AudienceItem = styled.div`
  width: 100%;
  min-width: 340px;
  height: 200px;

  background: #fafbfe;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

`;

const Row = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  width: calc(50% - 5px);
  text-align: left;
  font-weight: bold;
  text-transform: capitalize;
`;

const Value = styled.div`
  height: 20px;
  width: calc(50% - 5px);
  overflow-y: hidden;
  text-transform: capitalize;
`;

const AgeItem = styled.span`
  margin-right: 5px;
`;

const EditOptionBtn = styled.div`
  width: calc(50% - 5px);
  height: 35px;
  background-color: #6371DE;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`;

const NewRequestOptionBtn = styled.div`
  width: calc(50% - 5px);
  height: 35px;
  background-color: #f17855;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`;

const BtnText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
`;

const SavedAudiences = ({ setCurrentView, setSavedAudienceData }) => {
  const { user } = useAuthContext();

  const [audList, setAudList] = useState([]);

  const history = useHistory()

  useEffect(() => {
    if (!user || !user.audiences) return;

    setAudList(user.audiences);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.audiences]);

  return (
    <ContentContainer>
      <ListRow>
        {audList &&
          audList.map((audItem) => {
            return (
              <AudienceItem key={audItem.id}>
                <Row>
                  <Title>Audience Name:</Title>
                  <Value>{audItem.audiencename}</Value>
                </Row>
                <Row>
                  <Title>Age:</Title>
                  <Value>
                    {audItem.age.map((item) => {
                      return <AgeItem key={item}>{item}</AgeItem>;
                    })}
                  </Value>
                </Row>
                <Row>
                  <Title>Industry:</Title>
                  <Value>{audItem.industry}</Value>
                </Row>
                <Row>
                  <Title>seniority:</Title>
                  <Value>{audItem.seniority}</Value>
                </Row>
                <Row>
                  <Title>departments:</Title>
                  <Value>{audItem.departments}</Value>
                </Row>
                <Row>
                  <Title>job title:</Title>
                  <Value>{audItem.jobtitle}</Value>
                </Row>


                <Row>
                  <EditOptionBtn
                    onClick={() => {
                      setSavedAudienceData(audItem);
                      setCurrentView("editAudience");
                    }}
                  >
                    <BtnText>Edit</BtnText>
                  </EditOptionBtn>
                  <NewRequestOptionBtn onClick={
                    () => {
                      window.sessionStorage.setItem('audienceId', audItem.id)
                      history.push('/newrequest')
                    }
                  }>
                    <BtnText>New Request</BtnText>
                  </NewRequestOptionBtn>
                </Row>
              </AudienceItem>
            );
          })}
      </ListRow>
    </ContentContainer>
  );
};

export default SavedAudiences;
