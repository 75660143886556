import React from "react";
import Loader from "react-loader-spinner";

const Spinner = ({ color = "white", height = 20, width = 20 }) => {
  return (
    <Loader
      type="Oval"
      color={color}
      height={height}
      width={width}
      timeout={20000}
      style={{ marginTop: "100px" }}
    />
  );
};

export default Spinner;
