import React from "react";

import styled from "styled-components";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const ItemDescription = styled.div`
  margin-right: 5px;
`;

const MissionVision = ({mission}) => {
  return (
    <MainContainer>
      <SecTitle>Company Mission/Vision</SecTitle>
      <ItemDescription>{mission}</ItemDescription>
    </MainContainer>
  );
};

export default MissionVision;
