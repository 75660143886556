import React, { useState, useEffect } from "react";

import styled from "styled-components";
import DeliveredContents from "./DeliveredContents";
import InProgressContents from "./InProgressContents";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
`;

const MainContent = styled.div`
  width: 100%;
`;

const Heading = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid black;

  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
`;


const StatusBarContainer = styled.div`
  border: 1px solid #e4e6f2;
  box-sizing: border-box;
  padding: 2px;
  box-shadow: 0px 0px 22px rgba(59, 61, 80, 0.1);
  border-radius: 6px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StatusItem = styled.div`
  background: white;
  color: #6371de;
  width: calc(50% - 4px);
  height: 36px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.selectedStatus {
    background: #6371de;
    color: white;
  }
`;

const StatusDivider = styled.div`
  width: 3px;
  height: 28px;
  background: #e4e6f2;
  border-radius: 5px;
`;


const CompanyOrders = ({company, setFilteredOrderId, setHubView}) => {
  const [selectedStatus, setSelectedStatus] = useState("inprogress");
  const [filteredType, setFilteredType] = useState("All");
  const [searchedValue, setSearchValue] = useState("");

  const handleInputChange = (e) => {
    setFilteredType(e.target.value);
  };

  const handlesearchvalue = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <MainContainer>
      <MainContent>
        <Heading>{company.company}</Heading>

      <StatusBarContainer>
        <StatusItem
          className={
            selectedStatus === "inprogress" ? "selectedStatus" : undefined
          }
          onClick={() => setSelectedStatus("inprogress")}
        >
          In Progress
        </StatusItem>
        <StatusDivider />
        <StatusItem
          className={
            selectedStatus === "delivered" ? "selectedStatus" : undefined
          }
          onClick={() => setSelectedStatus("delivered")}
        >
          Delivered
        </StatusItem>
      </StatusBarContainer>

      {selectedStatus === "inprogress" ? (
        <InProgressContents
          role="admin"
          filteredType={filteredType}
          searchedValue={searchedValue}
          setFilteredType={setFilteredType}
          setSearchValue={setSearchValue}
          user={company}
          setFilteredOrderId={setFilteredOrderId}
          setHubView={setHubView}
        />
      ) : (
        <DeliveredContents
          role="admin"
          filteredType={filteredType}
          searchedValue={searchedValue}
          setFilteredType={setFilteredType}
          setSearchValue={setSearchValue}
          user={company}
          setFilteredOrderId={setFilteredOrderId}
          setHubView={setHubView}
        />
      )}



</MainContent>
    </MainContainer>
  );
};

export default CompanyOrders;
