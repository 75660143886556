import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { getAuth, functions, httpsCallable } from "../../firebase";
import { BiSave } from "react-icons/bi";

const PageSection = styled.div`
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

const SecRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
`;

const SecRowLeft = styled.div``;

const SecCol = styled.div`
  text-align: left;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 10px;
`;

const CancelBtn = styled.div`
  width: 132px;
  height: 34px;
  font-size: 16px;
  color: #3b3d50;
  background: #e6e8f2;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const SaveBtn = styled.div`
  width: 184px;
  height: 34px;
  font-size: 16px;
  background: #6371de;
  font-size: 16px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrMsgContainer = styled.div`
  width: 100%;
  height: 8px;
`;

const ErrMsg = styled.div`
  font-size: 12px;
  color: red;
  font-weight: bold;
`;

const SuccessMsg = styled.div`
  font-size: 12px;
  color: #58c9ae;
  font-weight: bold;
`;

const Icon = styled.div`
  margin-right: 7px;
  transform: translateY(1px);
`;

const UpdatePassword = () => {
  const [pass, setPass] = useState({
    password: "",
    repeatpassword: "",
  });

  const [errMsg, setErrMsg] = useState(true);
  const [successMsg, setSuccessMsg] = useState(false);
  const [providerErr, setProviderErr] = useState(false);
  const [accProvider, setAccProvider] = useState("");

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPass({
      ...pass,
      [name]: value,
    });
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    user.providerData.forEach((profile) => {
      setAccProvider(profile.providerId);
    });
  });

  useEffect(() => {
    if (accProvider !== "google.com") return;

    setProviderErr(true);
  }, [accProvider]);

  useEffect(() => {
    if (pass.password.length < 1 || pass.repeatpassword.length < 1) {
      setErrMsg(false);
    } else if (pass.password !== pass.repeatpassword) {
      setErrMsg(true);
    } else {
      setErrMsg(false);
    }
  }, [pass]);

  const handleSubmit = async () => {
    if (pass.password.length < 1 || pass.repeatpassword.length < 1) return;
    if (pass.password !== pass.repeatpassword) return;

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) return;

    try {
      const updatePassword = httpsCallable(functions, "updatePassword");
      const result = await updatePassword({
        uid: user.uid,
        newpassword: pass.repeatpassword,
      });

      if (!result) {
        alert("Unexpected error occured");
      }

      setPass({
        password: "",
        repeatpassword: "",
      });

      setSuccessMsg(true);

      window.setTimeout(() => {
        setSuccessMsg(false);
      }, 2500);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setPass({
      password: "",
      repeatpassword: "",
    });
  };

  return (
    <>
      {providerErr ? (
        <PageSection>
          <ErrMsg>Unable to update your password!</ErrMsg>
          <ErrMsg>You are logged in using your social account!</ErrMsg>
        </PageSection>
      ) : (
        <PageSection id="pageContent">
          <SecRow>
            <SecCol>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={handleChange}
                value={pass.password}
              />
              <Input
                type="password"
                name="repeatpassword"
                id="repeatpassword"
                placeholder="Repeat Password"
                onChange={handleChange}
                value={pass.repeatpassword}
              />

              <ErrMsgContainer>
                {errMsg ? <ErrMsg>Password Doesn't Match!</ErrMsg> : undefined}
                {successMsg ? (
                  <SuccessMsg>Successfully Updated!</SuccessMsg>
                ) : undefined}
              </ErrMsgContainer>
            </SecCol>
            <SecCol>
              <SecRowLeft>
                <BtnContainer>
                  <SecCol>
                    <CancelBtn id="cancelBtn" onClick={handleCancel}>
                      Cancel
                    </CancelBtn>
                  </SecCol>
                  <SecCol>
                    <SaveBtn id="saveBtn" onClick={handleSubmit}>
                      <Icon>
                        <BiSave />
                      </Icon>
                      Save Changes
                    </SaveBtn>
                  </SecCol>
                </BtnContainer>
              </SecRowLeft>
            </SecCol>
          </SecRow>
        </PageSection>
      )}
    </>
  );
};

export default UpdatePassword;
