import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import styled from "styled-components";
import { IoHome } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import { IoBusinessSharp } from "react-icons/io5";
import { GiEmptyMetalBucket } from "react-icons/gi";
import { useAuthContext } from "../../context/auth-context";

const MainContainer = styled.div`
  background: #6371de;
  width: 44px;
  padding: 30px 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

const MainContent = styled.div`
  margin-top: 90px;
  width: 30px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.nonadmin {
    height: 160px;
  }
`;

const IconRow = styled.div`
  display: flex;
  width: 200px;
`;

const Label = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgreen};
  padding: 4px 12px 5px;
  color: white;
  border-radius: 25px;
  margin-left: 8px;
  transform: translateY(-2px);
  font-size: 0.8rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  display: none;
`;

const AdminNav = () => {
  const [currentPath, setCurrentPath] = useState("");
  // const [iconHovered, setIconHovered] = useState('false')

  const { user } = useAuthContext();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(location.pathname.split("/")[1]);
  }, [location]);

  const upIndex = () => {
    document.querySelector(".accNavContainer").classList.add("upIndex");
  };

  const downIndex = () => {
    document.querySelector(".accNavContainer").classList.remove("upIndex");
  };

  return (
    <MainContainer className="accNavContainer">
      <MainContent
        className={user && user.role !== "super-admin" ? "nonadmin" : ""}
      >
        <IconRow>
          <IoHome
            className="navIcons accNavIcons"
            onClick={() => history.push("/admin-dashboard")}
            onMouseEnter={upIndex}
            onMouseLeave={downIndex}
          />
          {/* <Label className="accNavLabels">Admin Dashboard</Label> */}
        </IconRow>
        <div
          className={`navIconBottom ${
            currentPath === "admin-dashboard" ? "currentpath" : undefined
          }`}
        ></div>

        {user && user.role === "super-admin" ? (
          <>
            <IconRow>
              <FaRegUserCircle
                className="navIcons accNavIcons"
                onClick={() => history.push("/associates")}
                onMouseEnter={upIndex}
                onMouseLeave={downIndex}
              />
              <Label className="accNavLabels">Associates</Label>
            </IconRow>
            <div
              className={`navIconBottom ${
                currentPath === "associates" ? "currentpath" : undefined
              }`}
            ></div>
            <IconRow>
              <GiEmptyMetalBucket
                className="navIcons accNavIcons"
                onClick={() => history.push("/pods-assignment")}
                onMouseEnter={upIndex}
                onMouseLeave={downIndex}
              />
              <Label className="accNavLabels">Pods Assignment</Label>
            </IconRow>
            <div
              className={`navIconBottom ${
                currentPath === "pods-assignment" ? "currentpath" : undefined
              }`}
            ></div>{" "}
          </>
        ) : undefined}

        <IconRow>
          <IoBusinessSharp
            className="navIcons accNavIcons"
            onClick={() => history.push("/companies-hub")}
            onMouseEnter={upIndex}
            onMouseLeave={downIndex}
          />
          <Label className="accNavLabels">Companies Hub</Label>
        </IconRow>
        <div
          className={`navIconBottom ${
            currentPath === "companies-hub" ? "currentpath" : undefined
          }`}
        ></div>
      </MainContent>
    </MainContainer>
  );
};

export default AdminNav;
