import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { getAuth, signOut } from "firebase/auth";
import { useAuthContext } from "../../context/auth-context";
import { useViewUserDropdownContext } from "../../context/view-user-dropdown-context";

const MainContainer = styled.div`
  width: calc(100% - 60px);
  margin-left: 60px;
  height: 300px;
`;

const MainContent = styled.div`
  width: 175px;
  height: 70px;
  position: fixed;
  right: 58px;
  top: 70px;
  z-index: 4;
  background-color: #6371de;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 5px;
  padding-left: 25px;
  box-sizing: border-box;

  &.short {
    height: 40px;
  }
`;

const MenuItem = styled.div`
  width: 100%;
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
`;

const UserDropDown = () => {
  const { setLogged, user } = useAuthContext();
  const { viewUserDropdown, setViewUserDropdown } =
    useViewUserDropdownContext();

  const history = useHistory();

  return (
    <MainContainer>
      <MainContent className={user.role !== "client" ? "short" : ""}>
        {user.role === "client" && (
          <MenuItem
            onClick={() => {
              setViewUserDropdown(!viewUserDropdown);
              history.push("/companyprofile");
            }}
          >
            Settings
          </MenuItem>
        )}
        {user.role !== "client" ? (
          <MenuItem
            onClick={() => {
              signOut(getAuth());
              setViewUserDropdown(!viewUserDropdown);
              setLogged(false);
              history.push("/private-sign");
            }}
          >
            Logout
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              signOut(getAuth());
              setViewUserDropdown(!viewUserDropdown);
              setLogged(false);
              history.push("/");
            }}
          >
            Logout
          </MenuItem>
        )}
      </MainContent>
    </MainContainer>
  );
};

export default UserDropDown;
