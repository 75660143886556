import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAuthContext } from "../context/auth-context";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import SubscriptionForm from "../components/create-subscription/SubscriptionForm";

const CreateSubscription = () => {
  const { user } = useAuthContext();
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const history = useHistory();

  useEffect(() => {
    if (!user) return;

    if (user.status === "subscribed") {
      history.push("/dashboard");
    }
  }, [user, history]);

  setViewAccNav(false);
  setViewAdminNav(false);

  return <SubscriptionForm />;
};

export default CreateSubscription;
