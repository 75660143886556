import React, { createContext, useContext, useState, useEffect } from "react";

import { onSnapshot, ordersCollection, orderIdCollection, orderBy, query } from "../firebase";

import { useAuthContext } from "./auth-context";

const OrdersContext = createContext(undefined);

function OrdersContextProvider({ children }) {
  const { authUser, user } = useAuthContext();

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [orderId, setOrderId] = useState();

  const createOrderObj = (doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    };
  };

  // Listen to orders update (Firestore)
  // Query Orders
  useEffect(() => {
    if (!authUser || !user) {
      setOrders([]);
      setOrdersCount(0);
      return;
    }

    onSnapshot(query(ordersCollection, orderBy("createdAt", "desc")), (snapshot) => {
      setOrdersCount(snapshot.docs.length);

      if (user.role === "client") {
        setOrders(
          snapshot.docs
            .map((doc) => createOrderObj(doc))
            .filter((orderItem) => orderItem.user === user.id)
        );
      } else {
        setOrders(snapshot.docs.map((doc) => createOrderObj(doc)));
      }
    });

    // Order ID
    onSnapshot(orderIdCollection, (snapshot) => {
      setOrderId(snapshot.docs[0].data().orderId);
    });
  }, [authUser, user]);

  return (
    <OrdersContext.Provider value={{ orders, setOrders, orderId }}>
      {children}
    </OrdersContext.Provider>
  );
}

export default OrdersContextProvider;

export function useOrdersContext() {
  const ordersContext = useContext(OrdersContext);

  if (ordersContext === undefined)
    throw new Error(
      "useOrdersContext must be used within OrdersContextProvider."
    );

  return { ...ordersContext };
}
