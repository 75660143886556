import React, { useState, useEffect } from "react";

import styled from "styled-components";
import MoodboardImageItem from "./MoodboardImageItem";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const MainContent = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const Moodboard = ({ imageItems }) => {
  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {
    if (!imageItems) return;

    const tempArr = Object.entries(imageItems);

    const tempImagesArr = [];

    tempArr.forEach((item) => {
      tempImagesArr.push(item[1]);
    });

    setMediaItems(tempImagesArr);
  }, [imageItems]);

  return (
    <MainContainer>
      <SecTitle>Brand Inspiration</SecTitle>
      <MainContent>
        {mediaItems.map((item) => {
          return <MoodboardImageItem key={item.imageName} mediaItem={item} />;
        })}
      </MainContent>
    </MainContainer>
  );
};

export default Moodboard;
