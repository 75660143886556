import React from "react";

import styled from "styled-components";

const LineItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
`;

const ItemDescription = styled.div`
  margin-right: 5px;
`;

const AddressEmail = ({ company }) => {
  return (
    <>
      {company.email ? (
        <LineItem>
          <ItemTitle>Email:</ItemTitle>
          <ItemDescription>{company.email}</ItemDescription>
        </LineItem>
      ) : undefined}

      {company.website ? (
        <LineItem>
          <ItemTitle>Website:</ItemTitle>
          <ItemDescription>{company.website}</ItemDescription>
        </LineItem>
      ) : undefined}

      {company.address && company.address.addressline1 ? (
        <LineItem>
          <ItemTitle>Address:</ItemTitle>
          <ItemDescription>{company.address.addressline1}</ItemDescription>
        </LineItem>
      ) : undefined}

      {company.address && company.address.addressline2 ? (
        <LineItem>
          <ItemTitle></ItemTitle>
          <ItemDescription>{company.address.addressline2}</ItemDescription>
        </LineItem>
      ) : undefined}

      {company.address ? (
        <LineItem>
          <ItemTitle></ItemTitle>
          <ItemDescription>{company.address.city},</ItemDescription>
          <ItemDescription>{company.address.state},</ItemDescription>
          <ItemDescription>{company.address.zipcode}</ItemDescription>
        </LineItem>
      ) : undefined}
    </>
  );
};

export default AddressEmail;
