import React, { useState, useEffect } from "react";

import { useAuthContext } from "../../context/auth-context";
import { usePodsContext } from "../../context/pods-context";
import { useOrdersContext } from "../../context/orders-context";

import styled from "styled-components";
import ProfileImage from "./company-details/ProfileImage";
import Moodboard from "./company-details/Moodboard";
import AddressEmail from "./company-details/AddressEmail";
import Audiences from "./company-details/Audiences";
import Plan from "./company-details/Plan";
import SocialMedia from "./company-details/SocialMedia";
import MissionVision from "./company-details/MissionVision";
import Branding from "./company-details/Branding";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
`;

const MainContent = styled.div`
  width: 100%;
`;

const Heading = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid black;

  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const LineItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
`;

const ItemDescription = styled.div`
  margin-right: 5px;
`;

const OrderCount = styled.div`
  cursor: pointer;
`;

const CompanyItemDetail = ({ company, setHubView }) => {
  const [assignedAssociates, setAssignedAssociates] = useState();
  const [assignedPod, setAssignedPod] = useState("");
  const [orderCount, setOrderCount] = useState(0);

  const { orders } = useOrdersContext();
  const { allAssociates } = useAuthContext();
  const { pods } = usePodsContext();

  useEffect(() => {
    if (!orders) return;

    setOrderCount(orders.filter((order) => order.user === company.id).length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    if (
      !company ||
      !company.assigned ||
      !company.assigned.associates ||
      company.assigned.associates.length < 1
    )
      return;

    const tempAssigned = [];

    company.assigned.associates.forEach((item) => {
      const tempAssociate = allAssociates.filter(
        (associate) => associate.id === item
      )[0].role;
      const capAssociate =
        tempAssociate.slice(0, 1).toUpperCase() + tempAssociate.slice(1);
      tempAssigned.push(capAssociate);
    });

    setAssignedAssociates(tempAssigned);
  }, [company, allAssociates]);

  useEffect(() => {
    if (!pods || !company || !company.assigned || !company.assigned.pod) return;

    const tempPod = pods.filter((pod) => pod.id === company.assigned.pod)[0]
      .title;

    const capPod = tempPod.slice(0, 1).toUpperCase() + tempPod.slice(1);

    setAssignedPod(capPod);
  }, [company, pods]);

  return (
    <MainContainer>
      <MainContent>
        {company.company ? <Heading>{company.company}</Heading> : undefined}

        {company && company.branding && company.branding.logo ? (
          <Section>
            <ProfileImage logoUrl={company.branding.logo.logoUrl} />
          </Section>
        ) : undefined}

        <Section>
          <AddressEmail company={company} />
        </Section>

        {assignedAssociates && (
          <LineItem>
            <ItemTitle>Assigned Associates:</ItemTitle>
            {assignedAssociates.map((associate) => {
              return (
                <ItemDescription key={associate}>{associate}</ItemDescription>
              );
            })}
          </LineItem>
        )}

        {company.assigned && company.assigned.pod ? (
          <LineItem>
            <ItemTitle>Assigned Pod:</ItemTitle>
            <ItemDescription>{assignedPod}</ItemDescription>
          </LineItem>
        ) : undefined}

        <LineItem>
          <ItemTitle>Order Count:</ItemTitle>
          {orderCount < 1 ? (
            <ItemDescription>{orderCount}</ItemDescription>
          ) : (
            <ItemDescription>
              <OrderCount
                onClick={() => {
                  setHubView("orders");
                }}
              >
                {orderCount}
              </OrderCount>
            </ItemDescription>
          )}
        </LineItem>

        {company.smAcc ? (
          <Section>
            <SocialMedia smAcc={company.smAcc} />
          </Section>
        ) : undefined}

        {company.branding &&
        company.branding.branding &&
        company.branding.fonts ? (
          <Section>
            <Branding
              branding={company.branding.branding}
              fonts={company.branding.fonts}
              colorCode={company.branding.colorCode}
            />
          </Section>
        ) : undefined}

        {company.branding && company.branding.mission ? (
          <Section>
            <MissionVision mission={company.branding.mission} />
          </Section>
        ) : undefined}

        {company.planName && company.interval && company.price ? (
          <Section>
            <Plan
              plan={company.planName}
              interval={company.interval}
              price={company.price}
            />
          </Section>
        ) : undefined}

        {company.audiences ? (
          <Section>
            <Audiences audiences={company.audiences} />
          </Section>
        ) : undefined}

        {company.moodBoard ? (
          <Section>
            <Moodboard imageItems={company.moodBoard} />
          </Section>
        ) : undefined}
      </MainContent>
    </MainContainer>
  );
};

export default CompanyItemDetail;
