import React from "react";

import styled from "styled-components";
import { formatPrice } from "../../../helper";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const LineItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
`;

const ItemDescription = styled.div`
  margin-right: 5px;
  text-transform: capitalize;
`;

const Plan = ({ plan, interval, price }) => {
  return (
    <MainContainer>
      <SecTitle>Plan</SecTitle>
      <MainContent>
        <LineItem>
          <ItemTitle>Job Title:</ItemTitle>
          <ItemDescription>{plan}</ItemDescription>
        </LineItem>

        <LineItem>
          <ItemTitle>Price:</ItemTitle>
          <ItemDescription>{`$${formatPrice(price)}`}</ItemDescription>
        </LineItem>

        <LineItem>
          <ItemTitle>Interval:</ItemTitle>
          <ItemDescription>{`${interval}ly`}</ItemDescription>
        </LineItem>
      </MainContent>
    </MainContainer>
  );
};

export default Plan;
