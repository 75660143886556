import { BrowserRouter as Router } from "react-router-dom";

import AuthContextProvider from "./context/auth-context";
import OrdersContextProvider from "./context/orders-context";
import PodsContextProvider from "./context/pods-context";
import LeadContextProvider from "./context/lead-context";
import SubContextProvider from "./context/sub-context";
import SubFormViewContextProvider from "./context/sub-form-view-context";
import NewOrderContextProvider from "./context/new-order-context";
import ViewAccNavContextProvider from "./context/view-acc-nav-context";
import ViewAdminNavContextProvider from "./context/view-admin-nav-context";
import ViewUserDropdownContextProvider from "./context/view-user-dropdown-context";

import Routes from "./routes/Routes";
import Layout from "./components/Layout";

import "./App.css";

function App() {
  return (
    <AuthContextProvider>
      <OrdersContextProvider>
        <PodsContextProvider>
          <LeadContextProvider>
            <SubFormViewContextProvider>
              <SubContextProvider>
                <NewOrderContextProvider>
                  <ViewAccNavContextProvider>
                    <ViewAdminNavContextProvider>
                      <ViewUserDropdownContextProvider>
                        <Router>
                          <Layout>
                            <Routes />
                          </Layout>
                        </Router>
                      </ViewUserDropdownContextProvider>
                    </ViewAdminNavContextProvider>
                  </ViewAccNavContextProvider>
                </NewOrderContextProvider>
              </SubContextProvider>
            </SubFormViewContextProvider>
          </LeadContextProvider>
        </PodsContextProvider>
      </OrdersContextProvider>
    </AuthContextProvider>
  );
}

export default App;
