import React, { createContext, useContext, useState } from "react";

const NewOrderStateContext = createContext(undefined);
const NewOrderDispatchContext = createContext(undefined);

const NewOrderContextProvider = ({ children }) => {
  const [newOrder, setNewOrder] = useState(null);

  return (
    <NewOrderStateContext.Provider value={newOrder}>
      <NewOrderDispatchContext.Provider value={setNewOrder}>
        {children}
      </NewOrderDispatchContext.Provider>
    </NewOrderStateContext.Provider>
  );
};

export default NewOrderContextProvider;

export const useNewOrderContext = () => {
  const newOrder = useContext(NewOrderStateContext);
  const setNewOrder = useContext(NewOrderDispatchContext);

  if (newOrder === undefined || setNewOrder === undefined)
    throw new Error(
      "useNewOrderContext must be used within the NewOrderContextProvider"
    );

  return { newOrder, setNewOrder };
};
