import React from "react";

import styled from "styled-components";
import SavedAudiences from "./SavedAudiences";
import { IoCaretBack } from "react-icons/io5";

const PageMainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  overflow: scroll;
  overflow-x: hidden;
background-color: white;
`;

const MainContainer = styled.div`
  width: 100%;
  text-align: left;
  padding: 24px;
  box-sizing: border-box;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 15px;
`;
const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const BackBtn = styled.div`
  width: 90px;
  height: 35px;
  background-color: #f17855;
  /* background-color: #6371de; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 20px;
`;

const BtnText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
`;

const BackIcon = styled.div`
display: inline-block;
transform: translateY(2px);
`

const SavedAudienceView = ({ setCurrentView, setSavedAudienceData }) => {
  return (
    <PageMainContainer>
      <MainContainer>
        <TopRow>
        <BackBtn onClick={() => setCurrentView("intro")}>
            <BtnText><BackIcon><IoCaretBack /></BackIcon>Back</BtnText>
          </BackBtn>
          <SecTitle>Saved audiences</SecTitle>
        </TopRow>

        <SavedAudiences
          setCurrentView={setCurrentView}
          setSavedAudienceData={setSavedAudienceData}
        />
      </MainContainer>
    </PageMainContainer>
  );
};

export default SavedAudienceView;
