import React, { createContext, useContext, useState } from "react";

const ViewAccNavStateContext = createContext(undefined);
const ViewAccNavDispatchContext = createContext(undefined);

const ViewAccNavContextProvider = ({ children }) => {
  const [viewAccNav, setViewAccNav] = useState(false);

  return (
    <ViewAccNavStateContext.Provider value={viewAccNav}>
      <ViewAccNavDispatchContext.Provider value={setViewAccNav}>
        {children}
      </ViewAccNavDispatchContext.Provider>
    </ViewAccNavStateContext.Provider>
  );
};

export default ViewAccNavContextProvider;

export const useViewAccNavContext = () => {
  const viewAccNav = useContext(ViewAccNavStateContext);
  const setViewAccNav = useContext(ViewAccNavDispatchContext);

  if (viewAccNav === undefined || setViewAccNav === undefined)
    throw new Error("useViewAccNavContext must be used within the ViewAccNavContextProvider");

  return { viewAccNav, setViewAccNav };
};
