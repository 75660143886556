import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import { setDoc, doc, usersCollection } from "../../firebase";

const MainContainer = styled.div`
  width: 100%;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const UpdateBtn = styled.div`
  background-color: #e6e8f2;
  width: 100%;
  height: 34px;
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
`;

const ProfileInformation = ({setViewUpdateModal}) => {
  const { user } = useAuthContext();

  const [tempData, setTempData] = useState({
    companyName: "",
    companyWebsite: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;

    setTempData({
      ...tempData,
      [name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const userRef = doc(usersCollection, user.id);
    await setDoc(
      userRef,
      {
        company: tempData.companyName,
        website: tempData.companyWebsite,
      },
      { merge: true }
    );
    setViewUpdateModal(true)
  };

  useEffect(() => {
    if (!user) return;

    setTempData({
      companyName: user.company,
      companyWebsite: user.website,
    });
  }, [user]);
  return (
    <MainContainer>
      <SecTitle>Profile Information</SecTitle>

      <Label htmlFor="companyname">Company Name</Label>
      <Input
        type="text"
        id="companyname"
        name="companyName"
        placeholder="Type Company Name"
        value={tempData.companyName}
        onChange={handleChange}
      />

      <Label htmlFor="companywebsite">Company Website</Label>
      <Input
        type="text"
        name="companyWebsite"
        id="companywebsite"
        placeholder="Type Company Website"
        value={tempData.companyWebsite}
        onChange={handleChange}
      />

      <UpdateBtn onClick={handleSubmit}>Update</UpdateBtn>
    </MainContainer>
  );
};

export default ProfileInformation;
