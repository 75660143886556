import React from "react";

import styled from "styled-components";

import ContentCenter from "./ContentCenter";
import ControlOverview from "./ControlOverview";
import NewRequestRow from "../dashboard-sections/NewRequestRow";
import Nav from "./Nav";
import TopNav from "./TopNav";

const PageMainLayout = styled.div`
  width: 100vw;
  min-height: calc(100vh - 170px);
  display: flex;
  justify-content: space-between;
`;

const PageLeft = styled.div``;

const PageRight = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageMainContainer = styled.div`
  width: 95%;
  text-align: center;
  height: calc(100vh - 120px);
`;

const PageTopContainer = styled.div`
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 2px;
`;

const PageBottomContainer = styled.div`
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
`;

const PageMainContent = styled.div`
  padding: 10px;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Col24 = styled.div`
  width: 24%;
`;

const Col74 = styled.div`
  width: 74%;
`;

const SignedOutDashboard = () => {
  return (
    <PageMainLayout>
      <PageLeft>
        <Nav />
      </PageLeft>
      <PageRight>
        <PageMainContainer>
      <TopNav />
          <PageTopContainer>
            <NewRequestRow />
          </PageTopContainer>
          <PageBottomContainer>
            <PageMainContent>
              <ContentRow>
                <Col74>
                  <ControlOverview />
                </Col74>
                <Col24>
                  <ContentCenter />
                </Col24>
              </ContentRow>
            </PageMainContent>
          </PageBottomContainer>
        </PageMainContainer>
      </PageRight>
    </PageMainLayout>
  );
};

export default SignedOutDashboard;
