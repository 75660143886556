import React, { useState } from "react";

import styled from "styled-components";
import NewAudienceView from "../components/audiences/NewAudienceView";
import AudienceIntro from "../components/audiences/AudienceIntro";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import SavedAudienceView from "../components/audiences/SavedAudienceView";
import EditAudienceView from "../components/audiences/EditAudienceView";

const PageContainer = styled.div`
  width: calc(100% - 30px);
  margin-top: 35px;
  margin-left: 5px;

  &:hover {
    z-index: 3;
  }
`;

const Audiences = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const [currentView, setCurrentView] = useState("intro");
  const [savedAudienceData, setSavedAudienceData] = useState();

  setViewAccNav(true);
  setViewAdminNav(false);

  const renderView = () => {
    if (currentView === "intro") {
      return <AudienceIntro setCurrentView={setCurrentView} />;
    } else if (currentView === "newAudience") {
      return <NewAudienceView setCurrentView={setCurrentView} />;
    } else if (currentView === "savedAudience") {
      return (
        <SavedAudienceView
          setCurrentView={setCurrentView}
          setSavedAudienceData={setSavedAudienceData}
        />
      );
    } else if (currentView === "editAudience") {
      return (
        <EditAudienceView
          setCurrentView={setCurrentView}
          savedAudienceData={savedAudienceData}
          setSavedAudienceData={setSavedAudienceData}
        />
      );
    }
  };

  return <PageContainer>{renderView()}</PageContainer>;
};

export default Audiences;
