import React from "react";

import styled from "styled-components";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";

const PageMainContainer = styled.div`
  width: 95%;
  background-color: white;
  margin-top: 40px;
  text-align: center;
  height: calc(100vh - 120px);
  padding: 20px;
`;

const PageNotFound = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  setViewAccNav(false);
  setViewAdminNav(false);

  return <PageMainContainer>PAGE NOT FOUND</PageMainContainer>;
};

export default PageNotFound;
