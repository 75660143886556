import React from "react";

import styled from "styled-components";
import { FiDownload } from "react-icons/fi";
import { MdOutlineReviews } from "react-icons/md";

const MainContainer = styled.div`
  width: 100%;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 8px;
`;

const Header = styled.div`
  background: linear-gradient(89.99deg, #161f62 0.01%, #6371de 99.98%);
  width: 100%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftCol = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
`;

const RightCol = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  background-color: #f17855;
  padding: 10px 24px;
  border-radius: 8px 8px 0px 8px;
  cursor: pointer;

  &:hover {
    background-color: #f38b6f;
    color: #333;
  }
`;

const SubHeader = styled.div`
  background: #e6e8f2;
  height: 60px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleSection = styled.div`
  width: 36%;
  font-weight: bold;
`;

const TypeSection = styled.div`
  width: 19%;
  font-weight: bold;
`;

const DateSection = styled.div`
  width: 19%;
  font-weight: bold;
`;

const ActionSection = styled.div`
  width: 22%;
  font-weight: bold;
`;

const OrdersSection = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const OrderItemRow = styled.div`
  height: 60px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(even) {
    background-color: #e4e7ff;
  }
`;

const OrderItemTitle = styled.div`
  width: 36%;
`;

const OrderItemType = styled.div`
  width: 19%;
`;

const OrderItemDate = styled.div`
  width: 19%;
`;

const OrderItemAction = styled.div`
  width: 22%;
`;

const OrderItemActionContent = styled.div`
  width: 100%;
  max-width: 220px;
  display: flex;
  justify-content: space-between;
`;

const ActionItem = styled.div`
  width: 34%;
  color: #3b3d50;
  font-size: 25px;

  .actionIcon {
    cursor: pointer;
  }
`;

const ControlOverview = () => {

  const orders = [
    {
      id: 1,
      title: 'How BMO Financial builds efficient multi-p...',
      type: 'Case Study',
      date: '11/09/2021',
    },
    {
      id: 2,
      title: 'How BMO Financial builds efficient multi-p...',
      type: 'Blog',
      date: '11/09/2021',
    },
    {
      id: 3,
      title: 'How BMO Financial builds efficient multi-p...',
      type: 'Email',
      date: '11/09/2021',
    }
  ]

  return (
    <>
      <MainContainer>
        <Header>
          <LeftCol>control overview</LeftCol>
          <RightCol>view all</RightCol>
        </Header>
        <SubHeader>
          <TitleSection>Title</TitleSection>
          <TypeSection>Type</TypeSection>
          <DateSection>Date</DateSection>
          <ActionSection>Actions</ActionSection>
        </SubHeader>

        <OrdersSection>
          {orders.map((order) => {
            return (
              <OrderItemRow key={order.id}>
                <OrderItemTitle>{order.title}</OrderItemTitle>
                <OrderItemType>{order.type}</OrderItemType>
                <OrderItemDate>
                  {order.date}
                </OrderItemDate>
                <OrderItemAction>
                  <OrderItemActionContent>
                    <ActionItem>
                      <MdOutlineReviews className="actionIcon" />
                    </ActionItem>
                    <ActionItem>
                      <FiDownload
                        className="actionIcon"
                      />
                    </ActionItem>
                  </OrderItemActionContent>
                </OrderItemAction>
              </OrderItemRow>
            );
          })}
        </OrdersSection>
      </MainContainer>
    </>
  );
};

export default ControlOverview;
