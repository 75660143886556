import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { BsCheckLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { useAuthContext } from "../../context/auth-context";
import { useSubContext } from "../../context/sub-context";
import { planPricing, formatPrice } from "../../helper";
import { functions, httpsCallable } from "../../firebase";
import PaymentForm from "./PaymentForm";
import Spinner from "../Spinner";

const stripePromise = loadStripe(
  "pk_test_51JLWFxBn4vAMMpLKbydJ9EPbm3hgM8r9wcSn5an5GB37wMmEurmvrhcaKdWNliVxq5TZcTvKEkGzS30shLlt9TgB00wSeBu6jg"
);

const ContentContainer = styled.div`
  width: 100%;
  height: 750px;
  background-color: white;
  text-align: center;
  padding: 24px;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
  text-align: left;
`;

const TopRow = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BottomRow = styled.div`
  width: 100%;
  height: 550px;
  overflow: scroll;
`;

const TopLeftCol = styled.div``;

const TopRightCol = styled.div``;

const IntervalContainer = styled.div`
  border: 1px solid #2632381a;
  width: 320px;
  height: 66px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntervalItem = styled.div`
  height: 58px;
  width: 152px;
  border-radius: 5px;
  background: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;

  &.selectedIntervalStyle {
    background-color: #6371de;
    color: white;
  }
`;

const ChangeSubscriptionBtn = styled.div`
  width: 235px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f17855;
  color: white;
  border-radius: 5px;
  cursor: pointer;
`;

const PlansContainer = styled.div`
  width: 90%;
  max-width: 1100px;
  min-width: 800px;
  height: 892px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 30px 0px #2632381a;
`;

const TableRow = styled.div`
  display: flex;
`;

const TableWO = styled.div`
  width: calc(100% / 3);
  height: 50px;
  display: flex;
  justify-content: center;
`;

const TableWOShort = styled.div`
  width: calc(100% / 3);
  height: 30px;
  display: flex;
  justify-content: center; ;
`;

const TableWRight = styled.div`
  width: calc(100% / 3);
  height: 50px;
  border-right: 1px solid #e9eaeb; ;
`;

const TableWRightShort = styled.div`
  width: calc(100% / 3);
  height: 30px;
  border-right: 1px solid #e9eaeb; ;
`;

const TableWRightBottom = styled.div`
  width: calc(100% / 3);
  height: 50px;
  border-right: 1px solid #e9eaeb;
  border-bottom: 1px solid #e9eaeb; ;
`;

const TableWBottom = styled.div`
  width: calc(100% / 3);
  height: 50px;
  border-bottom: 1px solid #e9eaeb; ;
`;

const TextCol = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #6371de;
`;

const SubPrice = styled.div`
  font-size: 40px;
  color: #6371de;
  font-weight: lighter;
  text-align: center;
`;

const SubFeatureTitle = styled.div`
  font-weight: bold;
  font-size: 40px;
  color: #3b3d50;
  opacity: 0.3;
  padding-left: 30px;
`;

const SubFeatureItem = styled.div`
  font-size: 16px;
  color: #3b3d50;
  padding: 15px;
  padding-left: 30px;
`;

const CheckMark = styled.div`
  text-align: center;
  margin-top: 15px;
  color: #58c9ae;
`;

const XMark = styled.div`
  text-align: center;
  margin-top: 15px;
  color: #e05780;
`;

const PlanSelectCircle = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #e9ebeb;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;

  &.selectedPlanStyle {
    background-color: #58c9ae;
    border: 5px solid #58c9ae;
  }
`;

const PlanCheckMark = styled.div`
  color: white;
  font-size: 33px;
  transform: translate(-1px, 6px);
`;

const Heading = styled.h1`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SubHeading = styled.div`
  margin-bottom: 50px;
`;

const ConfirmationContainer = styled.div`
  text-align: center;
  margin: auto;
  margin-top: 50px;
  width: 80%;
`;

const SummaryContainer = styled.div`
  width: 85%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(38, 50, 56, 0.1);
  border-radius: 5px;
  margin: auto;
  margin-bottom: 50px;
`;

const SummaryTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 60px;
  margin: auto;
  border-bottom: 1px solid #e9eaeb;
`;

const SummaryBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 60px;
  margin: auto;
`;
const SammurayTitle = styled.div`
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-left: 5px;
  margin-right: 5px;
`;

const SammurayDescription = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
  margin-left: 5px;
  margin-right: 5px;
`;

const SubscriptionForm = () => {
  const { user } = useAuthContext();
  const { sub, setSub } = useSubContext();

  // const [selectedInterval, setSelectedInterval] = useState("year");
  const [selectedPlan, setSelectedPlan] = useState({
    planName: "business",
    interval: "month",
  });
  const [subFormView, setSubFormView] = useState("subscription");
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);

  const handleIntervalChange = (type) => {
    setSelectedPlan({
      ...selectedPlan,
      interval: type,
    });
  };

  const handlePlanChange = (type) => {
    setSelectedPlan({
      ...selectedPlan,
      planName: type,
    });
  };

  const handleCancelSubscription = async (subscriptionId) => {
    // setCancelLoading(true);

    try {
      // 1. Call the cloud function, to get a client secret
      const cancelSubscription = httpsCallable(functions, "cancelSubscription");

      const subscriptionData = await cancelSubscription({
        subscriptionId,
      });

      return { subscription: subscriptionData.data.subscription };
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleConfirmPlan = async (sub) => {
    if (!user) return;

    try {
      // setLoading(true);

      // Call cloud function to create a subscription
      const subscribe = httpsCallable(functions, "createSubscription");
      const result = await subscribe({
        userId: user.id, // If firebase auth is implemented the userId will be obtained in the function directly, no need to send over from frontend
        sub,
      });

      if (!result) {
        alert("Unexpected error occured");
      }

      const data = result.data;

      setClientSecret(data.clientSecret);

      // setLoading(false);
      setSub(null);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const handleChangeSubscription = () => {
    if (
      selectedPlan.planName === user.planName &&
      `${user.interval}ly` === selectedPlan.interval &&
      !user.subscriptionId
    )
      return;

    setLoading(true);

    handleCancelSubscription(user.subscriptionId);
    setSub({
      interval: selectedPlan.interval,
      price: planPricing[selectedPlan.planName][`${selectedPlan.interval}ly`],
      planName: selectedPlan.planName,
    });

    const subData = {
      interval: selectedPlan.interval,
      price: planPricing[selectedPlan.planName][`${selectedPlan.interval}ly`],
      planName: selectedPlan.planName,
    };

    handleConfirmPlan(subData);

    setLoading(false);

    setSubFormView("paymentForm");
  };

  useEffect(() => {
    if (!user) return;

    setSelectedPlan({
      planName: user.planName,
      interval: user.interval,
    });
  }, [user]);

  return (
    <ContentContainer>
      {subFormView === "subscription" ? (
        <>
          <SecTitle>Manage subscription</SecTitle>
          <TopRow>
            <TopLeftCol>
              <IntervalContainer>
                <IntervalItem
                  className={
                    selectedPlan.interval === "month"
                      ? "selectedIntervalStyle"
                      : ""
                  }
                  onClick={() => handleIntervalChange("month")}
                >
                  Monthly
                </IntervalItem>
                <IntervalItem
                  className={
                    selectedPlan.interval === "year"
                      ? "selectedIntervalStyle"
                      : ""
                  }
                  onClick={() => handleIntervalChange("year")}
                >
                  Yearly (20% Off)
                </IntervalItem>
              </IntervalContainer>
            </TopLeftCol>
            <TopRightCol>
              <ChangeSubscriptionBtn
                disabled={loading}
                onClick={handleChangeSubscription}
              >
                {loading ? (
                  <Spinner color="grey" height={15} width={15} />
                ) : (
                  "Change Subscription"
                )}
              </ChangeSubscriptionBtn>
            </TopRightCol>
          </TopRow>

          <BottomRow>
            <PlansContainer>
              <TableRow>
                <TableWRight></TableWRight>
                <TableWRight></TableWRight>
                <TableWO></TableWO>
              </TableRow>
              <TableRow>
                <TableWRightShort></TableWRightShort>
                <TableWRightShort>
                  <PlanSelectCircle
                    className={
                      selectedPlan.planName === "standard"
                        ? "selectedPlanStyle"
                        : ""
                    }
                    onClick={() => handlePlanChange("standard")}
                  >
                    <PlanCheckMark>
                      <BsCheckLg />
                    </PlanCheckMark>
                  </PlanSelectCircle>
                </TableWRightShort>
                <TableWOShort>
                  <PlanSelectCircle
                    className={
                      selectedPlan.planName === "business"
                        ? "selectedPlanStyle"
                        : ""
                    }
                    onClick={() => handlePlanChange("business")}
                  >
                    <PlanCheckMark>
                      <BsCheckLg />
                    </PlanCheckMark>
                  </PlanSelectCircle>
                </TableWOShort>
              </TableRow>
              <TableRow>
                <TableWRight></TableWRight>
                <TableWRight></TableWRight>
                <TableWO></TableWO>
              </TableRow>

              <TableRow>
                <TableWRight>
                  <SubFeatureTitle>Included Contents</SubFeatureTitle>
                </TableWRight>
                <TableWRight>
                  <SubTitle>Standard</SubTitle>
                </TableWRight>
                <TableWO>
                  <SubTitle>Business</SubTitle>
                </TableWO>
              </TableRow>

              <TableRow>
                <TableWRight></TableWRight>
                <TableWRight>
                  <SubPrice>$85/Month</SubPrice>
                </TableWRight>
                <TableWO>
                  <SubPrice>$320/Month</SubPrice>
                </TableWO>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem></SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom></TableWRightBottom>
                <TableWBottom></TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Turnaround Time</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <TextCol>2-3 Business Days</TextCol>
                </TableWRightBottom>
                <TableWBottom>
                  <TextCol>2-5 Business Days</TextCol>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Blog</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Social Media</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Email</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Article</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Website Copy</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              {/* <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>1-2 Days Turnarounds </SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow> */}

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>eBook</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <XMark>
                    <FaTimes />
                  </XMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Case Study</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <XMark>
                    <FaTimes />
                  </XMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Market Research</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <XMark>
                    <FaTimes />
                  </XMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Market Trends</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <XMark>
                    <FaTimes />
                  </XMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRightBottom>
                  <SubFeatureItem>Press Release</SubFeatureItem>
                </TableWRightBottom>
                <TableWRightBottom>
                  <XMark>
                    <FaTimes />
                  </XMark>
                </TableWRightBottom>
                <TableWBottom>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWBottom>
              </TableRow>

              <TableRow>
                <TableWRight>
                  <SubFeatureItem>Design Package</SubFeatureItem>
                </TableWRight>
                <TableWRight>
                  <XMark>
                    <FaTimes />
                  </XMark>
                </TableWRight>
                <TableWO>
                  <CheckMark>
                    <BsCheckLg />
                  </CheckMark>
                </TableWO>
              </TableRow>
            </PlansContainer>
          </BottomRow>
        </>
      ) : subFormView === "paymentForm" && clientSecret ? (
        <>
          <ConfirmationContainer>
            <Heading>Payment Summary</Heading>
            <SubHeading>
              Please review the following details for this transaction.
            </SubHeading>

            <SummaryContainer>
              <SummaryTop>
                <SammurayTitle>Description</SammurayTitle>
                {!user.interval ? (
                  false
                ) : (
                  <SammurayDescription>{`${user.interval}ly`}</SammurayDescription>
                )}
              </SummaryTop>

              <SummaryBottom>
                <SammurayTitle>Total</SammurayTitle>
                {!user.price ? (
                  false
                ) : (
                  <SammurayDescription>{`$${formatPrice(
                    user.price
                  )}`}</SammurayDescription>
                )}
              </SummaryBottom>
            </SummaryContainer>

            <Heading>Billing Information</Heading>
            <SubHeading>Enter your payment details below.</SubHeading>

            <Elements
              options={{
                clientSecret,
                appearance: {
                  theme: "stripe",
                },
              }}
              stripe={stripePromise}
            >
              <PaymentForm />
            </Elements>
          </ConfirmationContainer>
        </>
      ) : (
        <>
          <ConfirmationContainer>
            <Heading>Payment Summary</Heading>
            <SubHeading>
              Please review the following details for this transaction.
            </SubHeading>

            <SummaryContainer>
              <SummaryTop>
                <SammurayTitle>Description</SammurayTitle>
                <SammurayDescription>{`${selectedPlan.interval}ly`}</SammurayDescription>
              </SummaryTop>

              <SummaryBottom>
                <SammurayTitle>Total</SammurayTitle>
                <SammurayDescription>{`$${formatPrice(
                  planPricing[selectedPlan.planName][
                    `${selectedPlan.interval}ly`
                  ]
                )}`}</SammurayDescription>
              </SummaryBottom>
            </SummaryContainer>
          </ConfirmationContainer>
          <Spinner color="grey" height={50} width={50} />
        </>
      )}
    </ContentContainer>
  );
};

export default SubscriptionForm;
