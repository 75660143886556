import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import { useOrdersContext } from "../../context/orders-context";

const MainContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7px;
  grid-row-gap: 7px;
`;

const OrderItemContainer = styled.div`
  width: 100%;
  background: #fafbfe;
  border: 1px solid #e4e6f2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  cursor: pointer;
`;

const ItemTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

const ItemTitleValue = styled.span`
  font-size: 15px;
  font-weight: normal;
`;

const DeliveredContents = ({
  filteredType,
  searchedValue,
  setFilteredType,
  setSearchValue,
  user,
  setFilteredOrderId,
  setHubView
}) => {
  const { orders } = useOrdersContext();

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [ordersToDisplay, setOrdersToDisplay] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (searchedValue.length > 0) setSearchValue("");
    if (filteredType === "All") {
      setOrdersToDisplay(filteredOrders);
    } else {
      setOrdersToDisplay(
        filteredOrders.filter((order) => order.type === filteredType)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredType, filteredOrders]);

  useEffect(() => {
    if (searchedValue.length === 0) return;
    if (searchedValue.length > 0) setFilteredType("All");
    if (!searchedValue && filteredType === "All") {
      setOrdersToDisplay(
        orders.filter((order) => order.status === "delivered")
      );
    } else {
      setOrdersToDisplay(
        filteredOrders.filter((order) =>
          order.requestname.includes(searchedValue)
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredOrders, orders]);

  useEffect(() => {
    if (!user || !orders) return;

    const tempUserOrders = orders.filter((order) => order.user === user.id)
    setFilteredOrders(
      tempUserOrders.filter((order) => order.status === "delivered")
        );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  return (
    <MainContainer>
      {ordersToDisplay.map((order) => {
        return (
          <OrderItemContainer
            key={order.id}
            onClick={() => {
              setFilteredOrderId(order.id)
              setHubView('orderDetail')
            }}
          >
            <ItemTitle>
            Company: <ItemTitleValue>{order.client}</ItemTitleValue>
            </ItemTitle>
            <ItemTitle>
            Request Name: <ItemTitleValue>{order.requestname}</ItemTitleValue>
            </ItemTitle>
            <ItemTitle>
              Request Type: <ItemTitleValue>{order.type}</ItemTitleValue>
            </ItemTitle>
            <ItemTitle>
              Date Created:{" "}
              <ItemTitleValue>
                {order.createdAt.toDate().toDateString()}
              </ItemTitleValue>
            </ItemTitle>
          </OrderItemContainer>
        );
      })}
    </MainContainer>
  );
};

export default DeliveredContents;
