import React, { createContext, useContext, useState } from "react";

const ViewAdminNavStateContext = createContext(undefined);
const ViewAdminNavDispatchContext = createContext(undefined);

const ViewAdminNavContextProvider = ({ children }) => {
  const [viewAdminNav, setViewAdminNav] = useState(false);

  return (
    <ViewAdminNavStateContext.Provider value={viewAdminNav}>
      <ViewAdminNavDispatchContext.Provider value={setViewAdminNav}>
        {children}
      </ViewAdminNavDispatchContext.Provider>
    </ViewAdminNavStateContext.Provider>
  );
};

export default ViewAdminNavContextProvider;

export const useViewAdminNavContext = () => {
  const viewAdminNav = useContext(ViewAdminNavStateContext);
  const setViewAdminNav = useContext(ViewAdminNavDispatchContext);

  if (viewAdminNav === undefined || setViewAdminNav === undefined)
    throw new Error(
      "useViewAdminNavContext must be used within the ViewAdminNavContextProvider"
    );

  return { viewAdminNav, setViewAdminNav };
};
