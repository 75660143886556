import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import CompanyNav from "../components/nav/CompanyNav";
import EditProfile from "../components/edit-profile/EditProfile";
import ProfileUpdated from "../components/updated-modal/ProfileUpdated";
import { useAuthContext } from "../context/auth-context";

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4px);
  margin-top: 20px;
`;

const PageLeftCol = styled.div`
  width: 300px;
  height: calc(100vh - 80px);
  position: relative;
`;

const PageRightCol = styled.div`
  width: calc(100% - 300px);
  margin: 15px;

  @media (max-width: 990px) {
    margin-left: 20px;
  }
`;

const CompanyProfile = () => {
  const [viewUpdateModal, setViewUpdateModal] = useState(false);

  const { user } = useAuthContext();
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  setViewAccNav(true);
  setViewAdminNav(false);

  useEffect(() => {
    if (!user || !viewUpdateModal) return;

    setTimeout(() => {
      setViewUpdateModal(false);
    }, 3000);
  }, [user, viewUpdateModal]);

  return (
    <PageContainer>
      {viewUpdateModal && <ProfileUpdated />}

      <PageLeftCol>
        <CompanyNav />
      </PageLeftCol>
      <PageRightCol>
        <EditProfile
          viewUpdateModal={viewUpdateModal}
          setViewUpdateModal={setViewUpdateModal}
        />
      </PageRightCol>
    </PageContainer>
  );
};

export default CompanyProfile;
