import React, { useState, useEffect } from "react";

import styled from "styled-components";
import AudienceItem from "./AudienceItem";

import {
  setDoc,
  serverTimestamp,
  doc,
  usersCollection,
  ordersCollection,
  orderIdCollection,
} from "../../firebase";

import { useAuthContext } from "../../context/auth-context";
import { useOrdersContext } from "../../context/orders-context";

const AudienceContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  width: 80%;
  min-width: 310px;
  max-width: 800px;
`;

const Heading = styled.h1`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SubHeading = styled.div`
  margin-bottom: 50px;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(38, 50, 56, 0.1);
  border-radius: 5px;
`;

const ErrContainer = styled.div`
  margin: auto;
  margin-top: -15px;
  margin-bottom: 15px;
  height: 20px;
`;

const ErrMsg = styled.div`
  margin: auto;
  color: red;

  &.hide {
    display: none;
  }
`;

const BtnContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubBtn = styled.div`
  width: 165px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const Audience = ({ newOrder, setNewOrder, setOrderView }) => {
  const { user } = useAuthContext();
  const { orders, orderId } = useOrdersContext();

  const [newAudience, setNewAudience] = useState({
    audiencename: "",
    age: [],
    departments: "",
    industry: "",
    jobtitle: "",
    seniority: "",
    id: +new Date(),
  });
  const [formErr, setFormErr] = useState(false);
  const [writer, setWriter] = useState("");

  const handleSaveAudience = async () => {
    if (
      !newAudience.audiencename ||
      newAudience.age.length == 0 ||
      !newAudience.departments ||
      !newAudience.industry ||
      !newAudience.jobtitle ||
      !newAudience.seniority
    ) {
      setFormErr(true);
    } else {
      setNewOrder({
        ...newOrder,
        audience: newAudience,
      });

      try {
        // save audience on user
        const userRef = doc(usersCollection, user.id);
        await setDoc(
          userRef,
          {
            audiences: [...user.audiences, newAudience],
          },
          { merge: true }
        );

        // create new order

        const orderRef = doc(ordersCollection);
        await setDoc(orderRef, {
          id: orderRef.id,
          user: user.id,
          orderNumber: orderId,
          client: user.company,
          requestname: newOrder.requestname,
          topic: newOrder.topic,
          description: newOrder.description,
          type: newOrder.orderType,
          submissions: [],
          audience: {
            age: newAudience.age,
            departments: newAudience.departments,
            industry: newAudience.industry,
            jobtitle: newAudience.jobtitle,
            seniority: newAudience.seniority,
            audiencename: newAudience.audiencename,
          },
          status: "inprogress",
          createdAt: serverTimestamp(),
        });

        // Update Order ID Collection

        const orderIdRef = doc(orderIdCollection, "id");
        await setDoc(orderIdRef, {
          orderId: orderId + 1,
        });

        setOrderView("confirmation");
      } catch (error) {
        alert(error.message);
      }
    }
  };

  useEffect(() => {
    if (formErr) {
      document.querySelector("#error").classList.remove("hide");
      setTimeout(() => {
        document.querySelector("#error").classList.add("hide");
        setFormErr(false);
      }, 3000);
    }
  }, [formErr]);

  useEffect(() => {
    if (orders.length % 2) {
      setWriter("writer1");
    } else {
      setWriter("writer2");
    }
  }, [orders]);

  return (
    <AudienceContainer>
      <Heading>Order Audience</Heading>
      <SubHeading>Add audience below.</SubHeading>

      <ContentContainer>
        <AudienceItem
          setNewOrder={setNewOrder}
          newAudience={newAudience}
          setNewAudience={setNewAudience}
        />

        <ErrContainer>
          <ErrMsg className="hide" id="error">
            All fields are required!
          </ErrMsg>
        </ErrContainer>

        <BtnContainer>
          <SubBtn onClick={handleSaveAudience}>Submit</SubBtn>
        </BtnContainer>
      </ContentContainer>
    </AudienceContainer>
  );
};

export default Audience;
