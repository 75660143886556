import React from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { BsCheckLg } from "react-icons/bs";

const ConfirmationContainer = styled.div`
  margin: auto;
  margin-top: 50px;
  width: 80%;
  min-width: 310px;
  max-width: 800px;
`;

const CheckMark = styled.div`
  color: #58c9ae;
  font-size: 3rem;
  border: 3px solid gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin: auto;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(38, 50, 56, 0.1);
`;

const Heading = styled.h1`
  margin-bottom: 50px;
`;

const BtnContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubBtn = styled.div`
  width: 165px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const PaymentUpdateConfirmation = () => {
  const history = useHistory();

  return (
    <ConfirmationContainer>
      <CheckMark>
        <BsCheckLg />
      </CheckMark>

      <Heading>Payment Method Updated!</Heading>

      <BtnContainer>
        <SubBtn onClick={() => history.push("/dashboard")}>
          Go to Dashboard
        </SubBtn>
      </BtnContainer>
    </ConfirmationContainer>
  );
};

export default PaymentUpdateConfirmation;
