import React from "react";
import { Switch, Route } from "react-router-dom";

import { useAuthContext } from "../context/auth-context";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import AdminDashboard from "../pages/AdminDashboard";
import Dashboard from "../pages/Dashboard";
import CreateSubscription from "../pages/CreateSubscription";
import PageNotFound from "../pages/PageNotFound";
import NewOrder from "../pages/NewOrder";
import SubscriptionConfirmation from "../pages/SubscriptionConfirmation";
import AdminOrderDetail from "../pages/AdminOrderDetail";
import ContentCenter from "../pages/ContentCenter";
import OrderDetail from "../components/content-center/OrderDetail";

import ProtectedRoutes from "./ProtectedRoutes";
import AdminRoutes from "./AdminRoutes";
import ClientRoutes from "./ClientRoutes";
import CompanyProfile from "../pages/CompanyProfile";
import PasswordReset from "../pages/PasswordReset";
import EditSubscription from "../pages/EditSubscription";
import ManageSubscription from "../pages/ManageSubscription";
import SubscriptionUpdateConfirmation from "../pages/SubscriptionUpdateConfirmation";
import Invoices from "../pages/Invoices";
import UpdatePayment from "../pages/UpdatePayment";
import UpdatePaymentConfirmation from "../pages/UpdatePaymentConfirmation";
import Audiences from "../pages/Audiences";
import NewRequest from "../pages/NewRequest";
import PodsAssignment from "../pages/PodsAssignment";
import Associates from "../pages/Associates";
import PrivateSignup from "../pages/PrivateSignup";
import CompaniesHub from "../pages/CompaniesHub";
import Test from "../pages/Test";

const Routes = () => {
  const { authUser, user } = useAuthContext();

  return (
    <Switch>
      <Route exact path="/test">
        <Test />
      </Route>

      <Route exact path="/">
        <Login />
      </Route>

      <Route exact path="/signup">
        <SignUp />
      </Route>

      <Route exact path="/login">
        <Login />
      </Route>

      <Route exact path="/private-sign">
        <PrivateSignup />
      </Route>

      {/* Client Routes */}
      <Route exact path="/dashboard">
        <ProtectedRoutes>
          <ClientRoutes>
            <Dashboard authUser={authUser} user={user} />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/createsubscription">
        <ProtectedRoutes>
          <ClientRoutes>
            <CreateSubscription authUser={authUser} user={user} />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/subscriptionconfirmation">
        <ProtectedRoutes>
          <ClientRoutes>
            <SubscriptionConfirmation />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/subscriptionupdateconfirmation">
        <ProtectedRoutes>
          <ClientRoutes>
            <SubscriptionUpdateConfirmation />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/invoices">
        <ProtectedRoutes>
          <ClientRoutes>
            <Invoices />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/neworder">
        <ProtectedRoutes>
          <ClientRoutes>
            <NewOrder />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/contentcenter">
        <ProtectedRoutes>
          <ClientRoutes>
            <ContentCenter />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/orderdetail/:id">
        <ProtectedRoutes>
          <ClientRoutes>
            <OrderDetail />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/companyprofile">
        <ProtectedRoutes>
          <ClientRoutes>
            <CompanyProfile />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/editsubscription">
        <ProtectedRoutes>
          <ClientRoutes>
            <EditSubscription />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/editsubscription/subscription">
        <ProtectedRoutes>
          <ClientRoutes>
            <ManageSubscription />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/updatepayment">
        <ProtectedRoutes>
          <ClientRoutes>
            <UpdatePayment />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/updatepaymentconfirmation">
        <ProtectedRoutes>
          <ClientRoutes>
            <UpdatePaymentConfirmation />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/updatepassword">
        <ProtectedRoutes>
          <ClientRoutes>
            <PasswordReset />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/audiences">
        <ProtectedRoutes>
          <ClientRoutes>
            <Audiences />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/newrequest">
        <ProtectedRoutes>
          <ClientRoutes>
            <NewRequest />
          </ClientRoutes>
        </ProtectedRoutes>
      </Route>

      {/* Admin Routes */}

      <Route exact path="/admin-dashboard">
        {/* <ProtectedRoutes>
          <AdminRoutes> */}
            <AdminDashboard />
          {/* </AdminRoutes>
        </ProtectedRoutes> */}
      </Route>

      <Route exact path="/order/:id">
        <ProtectedRoutes>
          <AdminRoutes>
            <AdminOrderDetail />
          </AdminRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/pods-assignment">
        <ProtectedRoutes>
          <AdminRoutes>
            <PodsAssignment />
          </AdminRoutes>
        </ProtectedRoutes>
      </Route>

      <Route exact path="/associates">
        {/* <ProtectedRoutes>
          <AdminRoutes> */}
            <Associates />
          {/* </AdminRoutes>
        </ProtectedRoutes> */}
      </Route>

      <Route exact path="/companies-hub">
        {/* <ProtectedRoutes>
          <AdminRoutes> */}
            <CompaniesHub />
          {/* </AdminRoutes>
        </ProtectedRoutes> */}
      </Route>

      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
