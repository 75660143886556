import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { podsCollection, usersCollection, doc, setDoc } from "../../firebase";

const ItemContainer = styled.div`
  width: 300px;
  min-height: 62px;
  border: 2px solid #6371de;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #6371de;
  margin-top: 3px;
`;

const PodSelectContainer = styled.div`
  width: 90%;
  margin: auto;
`;

const PodSelect = styled.select`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 2px;
  border: 2px solid #6371de;
`;

const BusinessItem = ({ pods, user }) => {
  const [assignedPod, setAssignedPod] = useState("");
  const [oldAssignedPod, setOldAssignedPod] = useState("");

  const handlePodSelect = (e) => {
    setAssignedPod(e.target.value);
  };

  const updateUserPod = async () => {
    // update pod with user

    // remove user from the old pod
    if (oldAssignedPod) {
      const oldPod = pods.filter((pod) => pod.id === oldAssignedPod)[0];

      oldPod.assigned.businesses = oldPod.assigned.businesses.filter(
        (business) => business.id !== user.id
      );

      const podRef = doc(podsCollection, oldAssignedPod);
      await setDoc(podRef, {
        ...oldPod,
      });
    }

    // add user to the new pod
    const newPod = pods.filter((pod) => pod.id === assignedPod)[0];

    const podRef = doc(podsCollection, assignedPod);
    await setDoc(
      podRef,
      {
        assigned: {
          businesses: [
            ...newPod.assigned.businesses,
            {
              id: user.id,
              title: user.company,
            },
          ],
        },
      },
      { merge: true }
    );

    // update pod id on user
    const userRef = doc(usersCollection, user.id);
    await setDoc(
      userRef,
      {
        assigned: {
          pod: assignedPod,
        },
      },
      { merge: true }
    );
  };

  useEffect(() => {
    if (assignedPod === oldAssignedPod) return;
    updateUserPod();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPod]);

  useEffect(() => {
    if (!user) return;

    setOldAssignedPod(user.assigned.pod);
    setAssignedPod(user.assigned.pod);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.assigned]);

  return (
    <ItemContainer>
      <ItemTitle>{user.company}</ItemTitle>

      <PodSelectContainer>
        <PodSelect
          name="business-select"
          id="business-select"
          onChange={handlePodSelect}
          value={assignedPod}
        >
          <option value="">-</option>

          {pods &&
            pods.map((pod) => {
              return (
                <option key={pod.id} value={pod.id}>
                  {pod.title}
                </option>
              );
            })}
        </PodSelect>
      </PodSelectContainer>
    </ItemContainer>
  );
};

export default BusinessItem;
