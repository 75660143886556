import React from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { useAuthContext } from "../../../context/auth-context";
import { formatPriceNoDecimal } from "../../../helper";

const MainContainer = styled.div`
  width: 100%;
  height: 460px;
  background-color: white;
  text-align: center;
  padding: 24px;
  box-sizing: border-box;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 7px;
`;

const PlanPriceRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 15px;
`;

const PlanPrice = styled.div`
  font-size: 30px;
`;

const PlanInterval = styled.div`
  font-size: 13px;
  text-transform: capitalize;
`;

const ManageSubscriptionBtn = styled.div`
  width: 90%;
  height: 50px;
  background: #6371de;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  margin: auto;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 98%;
  height: 2px;
  border-radius: 5px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  background-color: #e6e8f2;
  margin: 20px auto;
`;

const DetailSection = styled.div`
  text-align: left;
`;

const DetailTitle = styled.div`
  font-size: 16px;
  color: #3b3d50;
  margin-bottom: 7px;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #3b3d50;
  opacity: 0.5;
`;

const DetailCol = styled.div`
  text-transform: capitalize;
`;

const CurrentPlan = () => {
  const { user } = useAuthContext();

  const history = useHistory();

  return (
    <MainContainer>
      <SecTitle>{user.planName}</SecTitle>
      <PlanPriceRow>
        <PlanPrice>${formatPriceNoDecimal(user.price)}/</PlanPrice>
        <PlanInterval>{user.interval}</PlanInterval>
      </PlanPriceRow>

      <ManageSubscriptionBtn
        onClick={() => history.push("/editsubscription/subscription")}
      >
        Manage Subscription
      </ManageSubscriptionBtn>
      <Divider />

      <DetailSection>
        <DetailTitle>Account pLan</DetailTitle>
        <DetailRow>
          <DetailCol>{user.planName}</DetailCol>
          <DetailCol>{`$${formatPriceNoDecimal(user.price)}/${
            user.interval
          }`}</DetailCol>
        </DetailRow>
      </DetailSection>
      <Divider />

      <DetailSection>
        <DetailTitle>This month total</DetailTitle>
        <DetailRow>
          <DetailCol>Billing period total =</DetailCol>
          <DetailCol>{`$${formatPriceNoDecimal(user.price)}/${
            user.interval
          }`}</DetailCol>
        </DetailRow>
      </DetailSection>
    </MainContainer>
  );
};

export default CurrentPlan;
