import React, { useState, useEffect } from "react";

import styled from "styled-components";
import AddForm from "./AddForm";
import AssociatesList from "./AssociatesList";

const MainContainer = styled.div`
  width: calc(60% - 5px);
  min-width: 310px;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ItemTitle = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  color: #6371de;
  margin-bottom: 5px;
`;

const AddAssociates = () => {
  return (
    <MainContainer>
      <ItemTitle>Add Associates</ItemTitle>
      <MainContent>
        <AddForm />
        <AssociatesList />
      </MainContent>
    </MainContainer>
  );
};

export default AddAssociates;
