import React from "react";

import styled from "styled-components";
import { IoPersonCircleSharp } from "react-icons/io5";

const TopRow = styled.div`
  width: 100%;
  height: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 32px;
  box-sizing: border-box;
  margin-bottom: 2px;
`;

const Contents = styled.div`
  display: flex;
  width: 220px;
  justify-content: space-between;
  transform: translateY(10px);
`;

const SubscribeLink = styled.div`
  color: #6371de;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
`;

const Icon = styled.div`
  font-size: 2rem;
  color: #c8c9ce;
  transform: translateY(-7px);
`;

const Divider = styled.div`
  width: 1px;
  height: 21px;
  background: #e6e8f2;
  box-shadow: 0px 0px 20px rgba(59, 61, 80, 0.16);
`;

const UserName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #3b3d50;
  opacity: 0.7;
`;

const TopNav = () => {
  return (
    <TopRow>
      <Contents>
        <SubscribeLink>Subscription</SubscribeLink>
        <Divider />
        <Icon>
          <IoPersonCircleSharp />
        </Icon>

        <UserName>John Doe</UserName>
      </Contents>
    </TopRow>
  );
};

export default TopNav;
