import React, { useState, useRef } from "react";

import styled from "styled-components";
import { adminCollection, doc, setDoc } from "../../firebase";
import { useAuthContext } from "../../context/auth-context";

const MainContainer = styled.div`
  width: 100%;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextInput = styled.input`
  border-radius: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  width: calc(50% - 20px);
`;

const SubmitBtn = styled.div`
  width: 200px;
  height: 35px;
  background-color: #6371de;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`;

const SubmitBtnText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
`;

const AddForm = () => {
  const [roleValues, setRoleValues] = useState({
    email: "",
    role: "",
  });

  const { adminDocs } = useAuthContext();

  const emailInputRef = useRef();
  const roleInputRef = useRef();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setRoleValues({
      ...roleValues,
      [name]: value,
    });
  };

  const handleAddRole = async () => {
    if (!roleValues.email || !roleValues.role) return;

    const adminRolesDocs = adminDocs[0].roles;

    if (
      adminRolesDocs.filter((role) => role.email === roleValues.email).length >
        0 ||
      adminRolesDocs.filter((role) => role.role === roleValues.role).length > 0
    )
      return;

    const rolesRef = doc(adminCollection, "roles");
    await setDoc(
      rolesRef,
      {
        roles: [
          ...adminRolesDocs,
          {
            email: roleValues.email,
            role: roleValues.role,
            status: "pending",
            createdAt: new Date(),
          },
        ],
      },
      { merge: true }
    );

    setRoleValues({
      email: "",
      role: "",
    });
  };

  return (
    <MainContainer>
      <MainContent>
        <TextInput
          type="email"
          placeholder="Email"
          name="email"
          onChange={handleChange}
          value={roleValues.email}
          ref={emailInputRef}
        />
        <TextInput
          type="text"
          placeholder="Role"
          name="role"
          onChange={handleChange}
          value={roleValues.role}
          ref={roleInputRef}
        />
        <SubmitBtn onClick={handleAddRole}>
          <SubmitBtnText>Add</SubmitBtnText>
        </SubmitBtn>
      </MainContent>
    </MainContainer>
  );
};

export default AddForm;
