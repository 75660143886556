import React from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { BsCheckLg } from "react-icons/bs";
import { useAuthContext } from "../../context/auth-context";
import { useSubContext } from "../../context/sub-context";
import { formatPrice } from "../../helper";

const ConfirmationContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  width: 80%;
  min-width: 310px;
  max-width: 800px;
`;

const CheckMark = styled.div`
  color: #58c9ae;
  font-size: 3rem;
  border: 3px solid gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin: auto;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(38, 50, 56, 0.1);
`;

const Heading = styled.h1`
  margin-bottom: 50px;
`;

const SummaryContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(38, 50, 56, 0.1);
  border-radius: 5px;
  margin-bottom: 30px;
`;

const SummaryTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 60px;
  margin: auto;
  border-bottom: 1px solid #e9eaeb;
`;

const SummaryBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 60px;
  margin: auto;
`;
const SammurayTitle = styled.div`
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-left: 5px;
  margin-right: 5px;
`;

const SammurayDescription = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
  margin-left: 5px;
  margin-right: 5px;
`;

const BtnContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SubBtn = styled.div`
  width: 165px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const ConfirmedPayment = () => {
  const { sub } = useSubContext();
  const {user} = useAuthContext()

  const history = useHistory();

  return (
    <ConfirmationContainer>
      <CheckMark>
        <BsCheckLg />
      </CheckMark>

      <Heading>Subscribed!</Heading>

      <SummaryContainer>
        <SummaryTop>
          <SammurayTitle>Description</SammurayTitle>
          <SammurayDescription>{`${user.interval}ly`}</SammurayDescription>
        </SummaryTop>

        <SummaryBottom>
          <SammurayTitle>Total</SammurayTitle>
          <SammurayDescription>{`$${formatPrice(
            user.price
          )}`}</SammurayDescription>
        </SummaryBottom>
      </SummaryContainer>

      <BtnContainer>
        <SubBtn onClick={() => history.push("/neworder")}>
          Place an Order
        </SubBtn>
        <SubBtn onClick={() => history.push("/dashboard")}>
          Go to Dashboard
        </SubBtn>
      </BtnContainer>
    </ConfirmationContainer>
  );
};

export default ConfirmedPayment;
