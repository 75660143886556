import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import { setDoc, doc, usersCollection } from "../../firebase";

const MainContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const SecRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const SecCol = styled.div`
  text-align: left;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const UpdateBtn = styled.div`
  background-color: #e6e8f2;
  width: 100%;
  height: 34px;
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
`;

const AddressInformation = ({setViewUpdateModal}) => {
  const { user } = useAuthContext();

  const [address, setAddress] = useState({
    addressline1: "",
    addressline2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });

  useEffect(() => {
    if (!user) return;

    if (!user.address) return;

    setAddress(user.address);
  }, [user]);

  const handleAddressChange = (e) => {
    const name = e.target.name;

    setAddress({
      ...address,
      [name]: e.target.value,
    });
  };

  const handleSubmitAddress = async () => {
    const userRef = doc(usersCollection, user.id);
    await setDoc(
      userRef,
      {
        address: {
          addressline1: address.addressline1,
          addressline2: address.addressline2,
          city: address.city,
          state: address.state,
          zipcode: address.zipcode,
          country: address.country,
        },
      },
      { merge: true }
    );
    setViewUpdateModal(true)
  };

  return (
    <MainContainer>
      <SecTitle>address information</SecTitle>

      <SecRow>
        <SecCol>
          <Label htmlFor="addressline1">Address Line 1</Label>
          <Input
            type="text"
            id="addressline1"
            name="addressline1"
            placeholder="Type Address Line 1"
            onChange={handleAddressChange}
            value={address.addressline1}
          />

          <Label htmlFor="addressline2">Address Line 2</Label>
          <Input
            type="text"
            id="addressline2"
            name="addressline2"
            placeholder="Type Address Line 2"
            onChange={handleAddressChange}
            value={address.addressline2}
          />

          <Label htmlFor="city">City</Label>
          <Input
            type="text"
            id="city"
            name="city"
            placeholder="Type City"
            onChange={handleAddressChange}
            value={address.city}
          />
        </SecCol>
        <SecCol>
          <Label htmlFor="state">State</Label>
          <Input
            type="text"
            id="state"
            name="state"
            placeholder="Type State"
            onChange={handleAddressChange}
            value={address.state}
          />

          <Label htmlFor="zipcode">ZipCode</Label>
          <Input
            type="text"
            id="zipcode"
            name="zipcode"
            placeholder="Type ZipCode"
            onChange={handleAddressChange}
            value={address.zipcode}
          />

          <Label htmlFor="country">Country</Label>
          <Input
            type="text"
            id="country"
            name="country"
            placeholder="Type Country"
            onChange={handleAddressChange}
            value={address.country}
          />

          <UpdateBtn onClick={handleSubmitAddress}>Update</UpdateBtn>
        </SecCol>
      </SecRow>
    </MainContainer>
  );
};

export default AddressInformation;
