import React, { useState } from "react";

import styled from "styled-components";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import SignupForm from "../components/private-signup/SignupForm";
import Signout from "../components/private-signup/Signout";
import SigninForm from "../components/private-signup/SigninForm";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 90%;
  height: calc(100vh - 130px);
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  background-color: white;
  padding: 20px 15px;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 40px;
`;

const PrivateSignup = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const [signView, setSignView] = useState("signout");

  setViewAccNav(false);
  setViewAdminNav(false);

  return (
    <MainContainer>
      <Heading>Associates {signView === "signup" ? "Signup" : "Login"}</Heading>

      {signView === "signout" ? (
        <Signout setSignView={setSignView} />
      ) : undefined}
      {signView === "signup" ? (
        <SignupForm setSignView={setSignView} />
      ) : undefined}
      {signView === "signin" ? (
        <SigninForm signView={signView} setSignView={setSignView} />
      ) : undefined}
    </MainContainer>
  );
};

export default PrivateSignup;
