import React from "react";

import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 8px;
`;

const Header = styled.div`
  background: #6371de;
  width: 100%;
  height: 60px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
`;

const SubHeader = styled.div`
  background: #e6e8f2;
  height: 60px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

const StatusSection = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const StatusItemRow = styled.div`
  height: 60px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusDot = styled.div`
  width: 24%;
  max-width: 20px;
`;

const StatusName = styled.div`
  width: 50%;
  font-size: 15px;
  text-align: left;
`;

const StatusNumber = styled.div`
  width: 24%;
  font-weight: bold;
  font-size: 15px;
  text-align: right;
`;

const Blue = styled.div`
  color: #35a6f8;
`;

const BlueDot = styled.div`
  background-color: #35a6f8;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const Green = styled.div`
  color: #58c9ae;
`;

const GreenDot = styled.div`
  background-color: #58c9ae;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const ContentCenter = () => {
  return (
    <MainContainer>
      <Header>Content Center</Header>
      <SubHeader>Content Requests</SubHeader>
      <StatusSection>
        <StatusItemRow>
          <StatusDot>
            <BlueDot></BlueDot>
          </StatusDot>
          <StatusName>In Progress</StatusName>
          <StatusNumber>
            <Blue>6</Blue>
          </StatusNumber>
        </StatusItemRow>

        <StatusItemRow>
          <StatusDot>
            <GreenDot></GreenDot>
          </StatusDot>
          <StatusName>Delivered</StatusName>
          <StatusNumber>
            <Green>3</Green>
          </StatusNumber>
        </StatusItemRow>
      </StatusSection>
    </MainContainer>
  );
};

export default ContentCenter;
