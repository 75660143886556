import React from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import {
  associatesCollection,
  usersCollection,
  doc,
  setDoc,
} from "../../firebase";
import { FaTimes } from "react-icons/fa";

const Content = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #e2e1e1;
  padding: 5px;
  box-sizing: border-box;
`;

const ItemName = styled.div``;

const DeleteIconContainer = styled.div`
  color: red;
  cursor: pointer;
`;

const AssociateCompanyItem = ({ name, associateId, companyId }) => {
  const { allUsers, allAssociates } = useAuthContext();

  const handleRemoveItem = async () => {
    const currentAssociate = allAssociates.filter(
      (associate) => associate.id === associateId
    )[0];
    currentAssociate.assigned = currentAssociate.assigned.filter(
      (company) => company !== companyId
    );

    const currentCompany = allUsers.filter((user) => user.id === companyId)[0];
    currentCompany.assigned.associates =
      currentCompany.assigned.associates.filter(
        (associate) => associate !== associateId
      );

    const userRef = doc(usersCollection, companyId);
    await setDoc(
      userRef,
      {
        assigned: {
          pod: currentCompany.assigned.pod,
          associates: currentCompany.assigned.associates,
        },
      },
      { merge: true }
    );

    const associateRef = doc(associatesCollection, associateId);
    await setDoc(
      associateRef,
      {
        assigned: currentAssociate.assigned,
      },
      { merge: true }
    );
  };

  return (
    <Content>
      <ItemName>{name}</ItemName>

      <DeleteIconContainer>
        <FaTimes onClick={handleRemoveItem} />
      </DeleteIconContainer>
    </Content>
  );
};

export default AssociateCompanyItem;
