import React from "react";

import styled from "styled-components";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import CompanyNav from "../components/nav/CompanyNav.jsx";
import AllInvoices from "../components/invoices/AllInvoices";

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4px);
  margin-top: 20px;
`;

const PageLeftCol = styled.div`
  width: 300px;
  height: calc(100vh - 80px);
  position: relative;
`;

const PageRightCol = styled.div`
  width: calc(100% - 300px);
  margin: 15px;

  @media (max-width: 990px) {
    margin-left: 20px;
  }
`;

const Invoices = () => {
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  setViewAccNav(true);
  setViewAdminNav(false);

  return (
    <PageContainer>
      <PageLeftCol>
        <CompanyNav />
      </PageLeftCol>
      <PageRightCol>
        <AllInvoices />
      </PageRightCol>
    </PageContainer>
  );
};

export default Invoices;
