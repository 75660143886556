import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

export default function PaymentUpdateForm() {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  // Confirm the setup that we created in App.jsx
  async function handleSubmit(e) {
    e.preventDefault()

    if (!stripe || !elements) return

    setLoading(true)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/updatepaymentconfirmation', // URL that stripe will return customer back, it should be the page that you display message to inform customer that the update is successful
      },
    })

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      } else {
        setMessage('An unexpected error occured.')
      }
    }

    setLoading(false)
  }

  return (
    <div className='checkout'>
      <form id='payment-form' onSubmit={handleSubmit}>
        <PaymentElement id='payment-element' />

        <button disabled={loading || !stripe || !elements} id='submit'>
          <span id='button-text'>
            {loading ? <div className='spinner' id='spinner'></div> : 'Confirm'}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id='payment-message'>{message}</div>}
      </form>
    </div>
  )
}
