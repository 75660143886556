import React, { useState } from "react";

import styled from "styled-components";
import RequestDescription from "../components/new-request/RequestDescription";
import OrderSubmitted from "../components/updated-modal/OrderSubmitted";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";

const PageContainer = styled.div`
  width: calc(100% - 30px);
  margin-top: 35px;
  margin-left: 5px;

  &:hover {
    z-index: 3;
  }
`;

const NewRequest = () => {
  const [viewRequestModal, setViewRequestModal] = useState(false);

  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  setViewAccNav(true);
  setViewAdminNav(false);

  return (
    <PageContainer>
      {viewRequestModal && (
        <OrderSubmitted setViewRequestModal={setViewRequestModal} />
      )}
      <RequestDescription setViewRequestModal={setViewRequestModal} />
    </PageContainer>
  );
};

export default NewRequest;
