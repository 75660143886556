import React, { useState, useEffect } from "react";

import styled from "styled-components";
import {
  orderIdCollection,
  ordersCollection,
  doc,
  setDoc,
  serverTimestamp,
  storage,
} from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { useAuthContext } from "../../context/auth-context";
import { useOrdersContext } from "../../context/orders-context";
import { orderTypes, businessOrderTypes } from "../../helper";
import Priority from "./Priority";
import SupportingDocs from "./SupportingDocs";

const PageMainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  overflow: scroll;
  overflow-x: hidden;
`;

const MainContainer = styled.div`
  width: 100%;
  background-color: white;
  text-align: left;
  padding: 24px;
  box-sizing: border-box;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const ContentContainer = styled.div`
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  @media (max-width: 999px) {
    grid-template-columns: 1fr;
  }
`;

const LeftCol = styled.div`
  width: 100%;

  @media (max-width: 999px) {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e6e8f2;
  }
`;

const RightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1000px) {
    padding-top: 34px;
    padding-bottom: 20px;
  }
`;

const RightColTop = styled.div`
  max-width: 630px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 999px) {
    height: 170px;
    flex-direction: row;
  }

  @media (min-width: 1400px) {
    flex-direction: row;
  }
`;

const RightColBottom = styled.div``;

const LabelItem = styled.label`
  display: inline-block;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
`;

const InputItem = styled.input`
  height: 35px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
  color: #3b3d50;
`;

const SelectInput = styled.select`
  height: 35px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  color: #3b3d50;
  cursor: pointer;
`;

const DescriptionInput = styled.textarea`
  height: 240px;
  width: 100%;
  padding: 15px 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  color: #3b3d50;
`;

const ErrContainer = styled.div`
  margin: auto;
  margin-top: -15px;
  margin-bottom: 15px;
  height: 20px;
  text-align: left;
`;

const ErrMsg = styled.div`
  margin: auto;
  color: red;

  &.hide {
    display: none;
  }
`;

const BtnContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: left;
`;

const SubBtn = styled.div`
  /* width: 165px; */
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6371de;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  :hover {
    border: 1px solid #6371de;
    background-color: white;
    color: black;
  }
`;

const RequestDescription = ({ setViewRequestModal }) => {
  const { user } = useAuthContext();
  const { orders, orderId } = useOrdersContext();

  const [savedAudiences, setSavedAudiences] = useState([]);
  const [orderAudience, setOrderAudience] = useState();
  const [orderDescription, setOrderDescription] = useState({
    requestname: "",
    orderType: "",
    topic: "",
    description: "",
  });
  const [priority, setPriority] = useState(1);
  const [activeStars, setActiveStars] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const [fileName, setFileName] = useState("");
  const [formErr, setFormErr] = useState(false);
  const [writer, setWriter] = useState("");
  const [progress, setProgress] = useState(0);

  const savedAudienceId = window.sessionStorage.getItem("audienceId");

  const handleInputChange = (e) => {
    setOrderDescription({
      ...orderDescription,
      [e.target.name]: e.target.value,
    });
  };

  const handleAudienceChange = (e) => {
    if (!savedAudiences || savedAudiences.length < 1) return;

    const selectedAudience = savedAudiences.filter(
      (item) => item.id === parseInt(e.target.value)
    );
    setOrderAudience(selectedAudience[0]);
  };

  const handleSavedAudienceId = (audId) => {
    if (!audId) return;

    const selectedAudience = savedAudiences.filter(
      (item) => item.id === parseInt(audId)
    );
    setOrderAudience(selectedAudience[0]);
    document.querySelector("#audienceselect").value = selectedAudience[0].id;
  };

  const resetValues = () => {
    setOrderAudience();
    setOrderDescription({
      requestname: "",
      orderType: "",
      topic: "",
      description: "",
    });
    setActiveStars([true, false, false, false, false]);
    document.querySelector("#audienceselect").value = "";
  };

  const uploadSupportingDoc = (orderRef) => {
    const target = document.querySelector("#supportingdocfile");

    const file = target.files[0];

    if (!file) return;

    const name =
      file.name.split(".")[0] +
      "-" +
      new Date().getTime() +
      "." +
      file.name.split(".")[1];

    const storageRef = ref(storage, `/files/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          setProgress(0);
          document.querySelector("#supportingdocfile").value = "";

          // add URL to the order

          await setDoc(
            orderRef,
            {
              submissions: [
                {
                  submittedBy: "client",
                  type: "file",
                  name: name,
                  url: url,
                  uploadNote: "Supporting Docs",
                  submittedAt: new Date(),
                },
              ],
            },
            { merge: true }
          );

          setFileName("");
        });
      }
    );
  };

  const handleSubmitOrder = async () => {
    if (
      !orderDescription.requestname ||
      !orderDescription.topic ||
      !orderDescription.description ||
      !orderDescription.orderType ||
      !orderAudience
    ) {
      setFormErr(true);
    } else {
      try {
        // create new order

        const orderRef = doc(ordersCollection);
        await setDoc(orderRef, {
          id: orderRef.id,
          user: user.id,
          orderNumber: orderId,
          client: user.company,
          requestname: orderDescription.requestname,
          topic: orderDescription.topic,
          description: orderDescription.description,
          type: orderDescription.orderType,
          assigned: writer,
          submissions: [],
          audience: {
            id: orderAudience.id,
            age: orderAudience.age,
            departments: orderAudience.departments,
            industry: orderAudience.industry,
            jobtitle: orderAudience.jobtitle,
            seniority: orderAudience.seniority,
            audiencename: orderAudience.audiencename,
          },
          priority,
          status: "inprogress",
          createdAt: serverTimestamp(),
        });

        // Upload Supporting Docs

        uploadSupportingDoc(orderRef);

        // Update Order ID Collection

        const orderIdRef = doc(orderIdCollection, "id");
        await setDoc(orderIdRef, {
          orderId: orderId + 1,
        });

        resetValues();
        setViewRequestModal(true);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  useEffect(() => {
    if (!user || !user.audiences) return;

    setSavedAudiences(user.audiences);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.audiences]);

  useEffect(() => {
    if (formErr) {
      document.querySelector("#error").classList.remove("hide");
      setTimeout(() => {
        document.querySelector("#error").classList.add("hide");
        setFormErr(false);
      }, 3000);
    }
  }, [formErr]);

  useEffect(() => {
    if (orders.length % 2) {
      setWriter("writer1");
    } else {
      setWriter("writer2");
    }
  }, [orders]);

  useEffect(() => {
    if (!savedAudienceId || !savedAudiences || savedAudiences.length < 1)
      return;
    handleSavedAudienceId(savedAudienceId);
    window.sessionStorage.removeItem("audienceId");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAudienceId, savedAudiences]);

  return (
    <PageMainContainer>
      <MainContainer>
        <SecTitle>New Request</SecTitle>

        <ContentContainer>
          <LeftCol>
            <LabelItem htmlFor="requestname">Request Name</LabelItem>
            <InputItem
              name="requestname"
              onChange={handleInputChange}
              value={orderDescription.requestname}
            />

            <LabelItem htmlFor="orderType">Type</LabelItem>
            <SelectInput
              name="orderType"
              onChange={handleInputChange}
              value={orderDescription.orderType}
            >
              <option value="" selected="selected" disabled="disabled">
                -
              </option>

              {/* {orderTypes.map((item, i) => {
                return (
                  <option key={i * 2} value={item}>
                    {item}
                  </option>
                );
              })} */}

              {user.planName === "business"
                ? businessOrderTypes.map((item, i) => {
                    return (
                      <option key={i * 2} value={item}>
                        {item}
                      </option>
                    );
                  })
                : orderTypes.map((item, i) => {
                    return (
                      <option key={i * 2} value={item}>
                        {item}
                      </option>
                    );
                  })}
            </SelectInput>

            <LabelItem htmlFor="audience">Audience</LabelItem>
            <SelectInput
              id="audienceselect"
              name="audience"
              onChange={handleAudienceChange}
            >
              <option value="" selected="selected" disabled="disabled">
                -
              </option>

              {savedAudiences &&
                savedAudiences.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.audiencename}
                    </option>
                  );
                })}
            </SelectInput>

            <LabelItem htmlFor="topic">Topic</LabelItem>
            <InputItem
              name="topic"
              onChange={handleInputChange}
              value={orderDescription.topic}
            />

            <LabelItem htmlFor="description">Description</LabelItem>
            <DescriptionInput
              name="description"
              onChange={handleInputChange}
              value={orderDescription.description}
            />
          </LeftCol>
          <RightCol>
            <RightColTop>
              <SupportingDocs fileName={fileName} setFileName={setFileName} />
              <Priority
                setPriority={setPriority}
                activeStars={activeStars}
                setActiveStars={setActiveStars}
              />
            </RightColTop>

            <RightColBottom>
              <ErrContainer>
                <ErrMsg className="hide" id="error">
                  All fields are required!
                </ErrMsg>
              </ErrContainer>

              <BtnContainer>
                <SubBtn onClick={handleSubmitOrder}>Submit</SubBtn>
              </BtnContainer>
            </RightColBottom>
          </RightCol>
        </ContentContainer>
      </MainContainer>
    </PageMainContainer>
  );
};

export default RequestDescription;
