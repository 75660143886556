import React from "react";

import styled from "styled-components";
import AddressInformation from "./AddressInformation";
import MissionVision from "./MissionVision";
import MoodBoard from "./MoodBoard";
import ProfileImage from "./ProfileImage";
import ProfileInformation from "./ProfileInformation";
import SocialMedia from "./SocialMedia";

const PageMainContainer = styled.div`
  width: 100%;
  height: 760px;
  overflow: scroll;
  overflow-x: hidden;
`;

const PageSection = styled.div`
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

const SecRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
`;

const SecCol = styled.div`
  text-align: left;
`;

const EditProfile = ({ setViewUpdateModal }) => {
  return (
    <PageMainContainer>
      <PageSection>
        <SecRow>
          <SecCol>
            <ProfileImage setViewUpdateModal={setViewUpdateModal} />
          </SecCol>
          <SecCol>
            <ProfileInformation setViewUpdateModal={setViewUpdateModal} />
          </SecCol>
        </SecRow>
      </PageSection>
      <PageSection>
        <AddressInformation setViewUpdateModal={setViewUpdateModal} />
      </PageSection>
      <PageSection>
        <SocialMedia setViewUpdateModal={setViewUpdateModal} />
      </PageSection>

      <PageSection>
        <MissionVision setViewUpdateModal={setViewUpdateModal} />
      </PageSection>

      <PageSection>
        <MoodBoard setViewUpdateModal={setViewUpdateModal} />
      </PageSection>
    </PageMainContainer>
  );
};

export default EditProfile;
