import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { useAuthContext } from "../../context/auth-context";
import {
  associatesCollection,
  usersCollection,
  doc,
  setDoc,
} from "../../firebase";
import AssociateCompanyItem from "./AssociateCompanyItem";

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmailCol = styled.div`
  width: calc(50% - 5px);
`;

const RoleCol = styled.div`
  width: calc(30% - 5px);
  text-align: center;
`;

const StatusCol = styled.div`
  width: 20%;
  text-align: right;
`;

const CompanyContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  border: 2px solid #6371de;
  border-radius: 5px;
`;

const CompanyTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
`;

const CTRTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

const CTRArrow = styled.div`
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
`;

const CompaniesContent = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 5px 10px;

  &.hide {
    display: none;
  }
`;

const CCLeft = styled.div`
  width: calc(50% - 5px);
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;

const CCRight = styled.div`
  width: calc(50% - 5px);
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;

const SearchBox = styled.input`
  width: 100%;
`;

const SearchResult = styled.div`
  margin-top: 5px;
`;

const SearchResultText = styled.div`
  display: inline;
  cursor: pointer;
`;

const Company = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const AssociatesListItem = ({ role }) => {
  const [containerOpen, setContainerOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);
  const [currentAssociate, setCurrentAssociate] = useState();
  const [assignedCompanies, setAssignedCompanies] = useState([]);

  const { allUsers, allAssociates } = useAuthContext();

  const handleSearchValChange = (e) => {
    setSearchVal(e.target.value);

    const tempResult = allUsers.filter((item) =>
      item.company.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setSearchedResults(tempResult);
  };

  const handleAddBusiness = async (company) => {
    const usersAssignedObj = company.assigned;
    if (usersAssignedObj.associates.includes(role.id)) return;

    const tempAssignedObj = {
      ...usersAssignedObj,
      associates: [...usersAssignedObj.associates, role.id],
    };

    const userRef = doc(usersCollection, company.id);
    await setDoc(
      userRef,
      {
        assigned: tempAssignedObj,
      },
      { merge: true }
    );

    const associateRef = doc(associatesCollection, role.id);
    await setDoc(
      associateRef,
      {
        assigned: [...currentAssociate.assigned, company.id],
      },
      { merge: true }
    );

    setSearchVal("");
  };

  useEffect(() => {
    if (role.status === "pending" || !allAssociates || allAssociates.length < 1)
      return;

    const tempAssociate = allAssociates.filter(
      (associate) => associate.id === role.id
    );
    setCurrentAssociate(tempAssociate[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAssociates]);

  useEffect(() => {
    if (!currentAssociate || !allUsers) return;

    const assignedCompaniesArr = currentAssociate.assigned;
    const tempFilteredCompanies = [];

    for (let i = 0; i < assignedCompaniesArr.length; i++) {
      const filteredItem = allUsers.filter(
        (user) => user.id === assignedCompaniesArr[i]
      )[0];
      tempFilteredCompanies.push(filteredItem);
    }

    setAssignedCompanies(tempFilteredCompanies);
  }, [currentAssociate, allUsers]);

  return (
    <MainContainer>
      <MainContent>
        <EmailCol>{role.email}</EmailCol>
        <RoleCol>{role.role}</RoleCol>
        <StatusCol>{role.status}</StatusCol>
      </MainContent>
      {role.status !== "pending" ? (
        <CompanyContainer>
          <CompanyTopRow>
            <CTRTitle>Companies</CTRTitle>
            <CTRArrow onClick={() => setContainerOpen(!containerOpen)}>
              {containerOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </CTRArrow>
          </CompanyTopRow>
          <CompaniesContent className={containerOpen ? "hide" : ""}>
            <CCLeft>
              <SearchBox
                type="text"
                value={searchVal}
                onChange={handleSearchValChange}
              />

              {searchVal.length > 0 &&
                searchedResults.map((item) => {
                  return (
                    <SearchResult key={item.id}>
                      <SearchResultText onClick={() => handleAddBusiness(item)}>
                        {item.company}
                      </SearchResultText>
                    </SearchResult>
                  );
                })}
            </CCLeft>
            <CCRight>
              {assignedCompanies &&
                assignedCompanies.map((item) => {
                  return (
                    <AssociateCompanyItem
                      key={item.id}
                      name={item.company}
                      associateId={role.id}
                      companyId={item.id}
                    />
                  );
                })}
            </CCRight>
          </CompaniesContent>
        </CompanyContainer>
      ) : undefined}
    </MainContainer>
  );
};

export default AssociatesListItem;
