import React from "react";

import styled from "styled-components";

const MainContainer = styled.div`
  width: 292px;
  height: 170px;
  border: 1px solid #e6e8f2;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  margin-bottom: 24px;
`;

const SubTitle = styled.div`
  height: 22px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  color: #3b3d50;
  color: #3b3d50;
  opacity: 0.7;
  margin-bottom: 8px;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label``;

const UploadBtn = styled.div`
  width: 244px;
  height: 50px;
  font-size: 16px;
  color: #3b3d50;
  background: #e6e8f2;
  border-radius: 5px;
  padding: 16px 74px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: #d7d9e4;
  }
`;

const SubTitleText = styled.div``;

const SupportingDocs = ({ fileName, setFileName }) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    setFileName(file.name);
  };

  return (
    <MainContainer>
      <Title>Supporting Docs</Title>
      <SubTitle>
        <SubTitleText>{fileName ? fileName : "No File Uploaded!"}</SubTitleText>
      </SubTitle>

      <FileInput
        type="file"
        id="supportingdocfile"
        onChange={handleFileChange}
      />
      <FileInputLabel htmlFor="supportingdocfile">
        <UploadBtn>Upload File</UploadBtn>
      </FileInputLabel>
    </MainContainer>
  );
};

export default SupportingDocs;
