import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import { setDoc, doc, usersCollection } from "../../firebase";

const MainContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const SecTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 20px;
`;

const SecRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const SecCol = styled.div`
  text-align: left;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const UpdateBtn = styled.div`
  background-color: #e6e8f2;
  width: 100%;
  height: 34px;
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
`;

const SocialMedia = ({setViewUpdateModal}) => {
  const { user } = useAuthContext();

  const [smAcc, setSMAcc] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    linkedin: "",
    tiktok: "",
  });

  useEffect(() => {
    if (!user) return;

    if (!user.smAcc) return;

    setSMAcc(user.smAcc);
  }, [user]);

  const handleSMChange = (e) => {
    const name = e.target.name;

    setSMAcc({
      ...smAcc,
      [name]: e.target.value,
    });
  };

  const handleSubmitSM = async () => {
    const userRef = doc(usersCollection, user.id);
    await setDoc(
      userRef,
      {
        smAcc: {
          facebook: smAcc.facebook,
          instagram: smAcc.instagram,
          twitter: smAcc.twitter,
          youtube: smAcc.youtube,
          linkedin: smAcc.linkedin,
          tiktok: smAcc.tiktok,
        },
      },
      { merge: true }
    );
    setViewUpdateModal(true)
  };

  return (
    <MainContainer>
      <SecTitle>Social Media Accounts</SecTitle>

      <SecRow>
        <SecCol>
          <Label htmlFor="facebook">Facebook</Label>
          <Input
            type="text"
            id="facebook"
            name="facebook"
            placeholder="@username"
            onChange={handleSMChange}
            value={smAcc.facebook}
          />

          <Label htmlFor="instagram">Instagram</Label>
          <Input
            type="text"
            id="instagram"
            name="instagram"
            placeholder="@username"
            onChange={handleSMChange}
            value={smAcc.instagram}
          />

          <Label htmlFor="twitter">Twitter</Label>
          <Input
            type="text"
            id="twitter"
            name="twitter"
            placeholder="@username"
            onChange={handleSMChange}
            value={smAcc.twitter}
          />
        </SecCol>
        <SecCol>
          <Label htmlFor="youtube">YouTube</Label>
          <Input
            type="text"
            id="youtube"
            name="youtube"
            placeholder="@username"
            onChange={handleSMChange}
            value={smAcc.youtube}
          />

          <Label htmlFor="linkedin">Linkedin</Label>
          <Input
            type="text"
            id="linkedin"
            name="linkedin"
            placeholder="@username"
            onChange={handleSMChange}
            value={smAcc.linkedin}
          />

          <Label htmlFor="tiktok">TikTok</Label>
          <Input
            type="text"
            id="tiktok"
            name="tiktok"
            placeholder="@username"
            onChange={handleSMChange}
            value={smAcc.tiktok}
          />

          <UpdateBtn onClick={handleSubmitSM}>Update</UpdateBtn>
        </SecCol>
      </SecRow>
    </MainContainer>
  );
};

export default SocialMedia;
