import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";

import styled from "styled-components";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import { useAuthContext } from "../context/auth-context";
import CompanyItem from "../components/companies-hub/CompanyItem";
import CompanyItemDetail from "../components/companies-hub/CompanyItemDetail";
import CompanyOrders from "../components/companies-hub/CompanyOrders";
import AdminOrderDetail from "../components/companies-hub/AdminOrderDetail.jsx";

const MainContainer = styled.div`
  margin-top: 50px;
  width: 95%;
  min-height: calc(100vh - 130px);
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  background-color: white;
  padding: 20px 15px;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 7px;
  border-bottom: 1px solid black;

  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #6371de;
  margin-bottom: 40px;
`;

const NameCol = styled.div`
  width: calc(38% - 5px);
`;

const EmailCol = styled.div`
  width: calc(47% - 5px);
`;

const OrderCountCol = styled.div`
  width: 15%;
  text-align: right;
`;

const TextContent = styled.div`
  cursor: pointer;
  display: inline;
`;

const CompaniesHub = () => {
  const [hubView, setHubView] = useState("hub");
  const [filteredCompany, setFilteredCompany] = useState(null);
  const [filteredOrderId, setFilteredOrderId] = useState(null);

  const { user, allUsers } = useAuthContext();
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const location = useLocation();

  setViewAccNav(false);
  setViewAdminNav(true);

  useEffect(() => {
    if (!user) return;

    if (user.role === "client")
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: {
              from: location.pathname,
            },
          }}
        />
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <MainContainer>
      <Heading>
        <TextContent
          onClick={() => {
            setHubView("hub");
            setFilteredCompany(null);
          }}
        >
          Companies Hub
        </TextContent>
      </Heading>

      {hubView === "hub" ? (
        <>
          <MainContent>
            <NameCol>Name</NameCol>
            <EmailCol>Email</EmailCol>
            <OrderCountCol>Order Count</OrderCountCol>
          </MainContent>
          {allUsers &&
            allUsers.map((item) => {
              return (
                <CompanyItem
                  key={item.id}
                  company={item}
                  setFilteredCompany={setFilteredCompany}
                  setHubView={setHubView}
                />
              );
            })}{" "}
        </>
      ) : undefined}

      {hubView === "detail" ? (
        <CompanyItemDetail company={filteredCompany} setHubView={setHubView} />
      ) : undefined}

      {hubView === "orders" ? (
        <CompanyOrders
          setFilteredOrderId={setFilteredOrderId}
          company={filteredCompany}
          setHubView={setHubView}
        />
      ) : undefined}

      {hubView === "orderDetail" ? (
        <AdminOrderDetail
          orderId={filteredOrderId}
          company={filteredCompany}
          currentAssociateId={user.id}
          setHubView={setHubView}
        />
      ) : undefined}
    </MainContainer>
  );
};

export default CompaniesHub;
