import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useOrdersContext } from "../../context/orders-context";

const Content = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #e2e1e1;
  padding: 5px;
  box-sizing: border-box;
`;

const NameCol = styled.div`
  width: calc(38% - 5px);
`;

const EmailCol = styled.div`
  width: calc(47% - 5px);
`;

const OrderCountCol = styled.div`
  width: 15%;
  text-align: right;
`;

const TextContent = styled.div`
  cursor: pointer;
  /* display: inline; */
`;

const CompanyItem = ({ company, setFilteredCompany, setHubView }) => {
  const [orderCount, setOrderCount] = useState(0);

  const { orders } = useOrdersContext();

  useEffect(() => {
    if (!orders) return;

    setOrderCount(orders.filter((order) => order.user === company.id).length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  return (
    <Content>
      <NameCol>
        <TextContent
          onClick={() => {
            setHubView("detail");
            setFilteredCompany(company);
          }}
        >
          {company.company}
        </TextContent>
      </NameCol>
      <EmailCol>{company.email}</EmailCol>
      <OrderCountCol>
        {orderCount < 1 ? (
          <TextContent>{orderCount}</TextContent>
        ) : (
          <TextContent
            onClick={() => {
              setHubView("orders");
              setFilteredCompany(company);
            }}
          >
            {orderCount}
          </TextContent>
        )}
      </OrderCountCol>
    </Content>
  );
};

export default CompanyItem;
