import React from "react";
import styled from "styled-components";

import { IoHome } from "react-icons/io5";
import { IoIosPaper, IoIosPeople } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { BiNetworkChart } from "react-icons/bi";
import { AiFillSetting } from "react-icons/ai";
import { RiExchangeDollarLine } from "react-icons/ri";
import { MdPayment } from "react-icons/md";
import { FiHelpCircle } from "react-icons/fi";
import { GoDiffAdded } from "react-icons/go";

const AccountNavContainer = styled.div`
  background: #6371de;
  width: 44px;
  padding: 30px 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

const ANContent = styled.div`
  margin-top: 90px;
  width: 30px;
  height: 630px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ANIconRow = styled.div`
  display: flex;
  width: 200px;
`;

const ANLabel = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgreen};
  padding: 4px 12px 5px;
  color: white;
  border-radius: 25px;
  margin-left: 8px;
  transform: translateY(-2px);
  font-size: 0.8rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  display: none;
`;

const Nav = () => {
  return (
    <AccountNavContainer>
      <ANContent>
        <ANIconRow>
          <IoHome className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Home</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <IoIosPaper
            className="navIcons accNavIcons"
          />
          <ANLabel className="accNavLabels">Content Center</ANLabel>
        </ANIconRow>
        <div
          className='navIconBottom'
        ></div>
        <ANIconRow>
          <FaRegUserCircle className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Company Profile</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <IoIosPeople className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Audiences</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <BiNetworkChart className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Integration</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <AiFillSetting className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Account Settings</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <RiExchangeDollarLine className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Subscription</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <MdPayment className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Billing & Payment</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <FiHelpCircle className="navIcons accNavIcons" />
          <ANLabel className="accNavLabels">Help Center</ANLabel>
        </ANIconRow>
        <div className="navIconBottom"></div>
        <ANIconRow>
          <GoDiffAdded
            className="navIcons accNavIcons"
          />
          <ANLabel className="accNavLabels">New Request</ANLabel>
        </ANIconRow>
        <div
          className='navIconBottom'
        ></div>
      </ANContent>
    </AccountNavContainer>
  );
};

export default Nav;
