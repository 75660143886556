import React, { useState } from "react";

import OrderDescription from "../components/new-order/OrderDescription";
import Audience from "../components/new-order/Audience";
import { useNewOrderContext } from "../context/new-order-context";
import OrderConfirmation from "../components/new-order/OrderConfirmation";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";

const NewOrder = () => {
  const { newOrder, setNewOrder } = useNewOrderContext();
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const [orderView, setOrderView] = useState("description");

  setViewAccNav(true);
  setViewAdminNav(false);

  return (
    <>
      {orderView === "description" ? (
        <OrderDescription
          setNewOrder={setNewOrder}
          setOrderView={setOrderView}
        />
      ) : orderView === "audience" ? (
        <Audience
          newOrder={newOrder}
          setNewOrder={setNewOrder}
          setOrderView={setOrderView}
        />
      ) : orderView === "confirmation" ? (
        <OrderConfirmation />
      ) : undefined}
    </>
  );
};

export default NewOrder;
