import React from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: #f3f3f338;
`;

const MainContent = styled.div`
  position: fixed;
  top: calc(100vh / 2 - 70px);
  left: calc(100vw / 2 - 205px);
  margin-top: 20px;
  padding: 20px;
  margin: auto;
  background-color: #e9e9e9;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
`;

const TopRow = styled.div`
  margin-bottom: 20px;
`;

const BottomRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const LeftCol = styled.div`
  margin-right: 10px;
  background-color: #6371de;
  width: 180px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`;

const RightCol = styled.div`
  background-color: #f17855;
  width: 180px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`;

const OrderSubmitted = ({ tilte, setViewRequestModal }) => {
  const history = useHistory();

  return (
    <MainContainer>
      <MainContent>
        <TopRow>Request Submitted!</TopRow>

        <BottomRow>
          <LeftCol onClick={() => history.push("/contentcenter")}>
            Content Center
          </LeftCol>
          <RightCol onClick={() => setViewRequestModal(false)}>
            New Request
          </RightCol>
        </BottomRow>
      </MainContent>
    </MainContainer>
  );
};

export default OrderSubmitted;
