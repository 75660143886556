import React, { useState, useEffect } from "react";

import styled from "styled-components";
import Spinner from "../components/Spinner";
import CompanyNav from "../components/nav/CompanyNav";
import { useAuthContext } from "../context/auth-context";
import { useViewAccNavContext } from "../context/view-acc-nav-context";
import { useViewAdminNavContext } from "../context/view-admin-nav-context";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { httpsCallable, functions } from "../firebase";
import PaymentUpdateForm from "../components/update-subscription/PaymentUpdateForm";

const stripePromise = loadStripe(
  "pk_test_51JLWFxBn4vAMMpLKbydJ9EPbm3hgM8r9wcSn5an5GB37wMmEurmvrhcaKdWNliVxq5TZcTvKEkGzS30shLlt9TgB00wSeBu6jg"
);

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4px);
  margin-top: 20px;
`;

const PageLeftCol = styled.div`
  width: 300px;
  height: calc(100vh - 80px);
  position: relative;
`;

const PageRightCol = styled.div`
  width: calc(100% - 300px);
  margin: 15px;

  @media (max-width: 990px) {
    margin-left: 20px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  text-align: center;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Heading = styled.h1`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SubHeading = styled.div`
  margin-bottom: 50px;
`;

const UpdatePayment = () => {
  const { user } = useAuthContext();
  const { setViewAccNav } = useViewAccNavContext();
  const { setViewAdminNav } = useViewAdminNavContext();

  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);

  setViewAccNav(true);
  setViewAdminNav(false);

  useEffect(() => {
    const handleCreateSetupIntent = async () => {
      try {
        setLoading(true);

        // Call cloud function to setup payment intent
        const setupIntents = httpsCallable(functions, "setupIntents");
        const result = await setupIntents({
          // Get stripe customer id from the database (firestore)
          customerId: user.stripeCustomerId,
        });

        if (!result) {
          alert("Unexpected error occured");
        }

        const data = result.data;

        setClientSecret(data.clientSecret);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    handleCreateSetupIntent();
  }, []);

  return (
    <PageContainer>
      <PageLeftCol>
        <CompanyNav />
      </PageLeftCol>
      <PageRightCol>
        <ContentContainer>
          <Heading>Add Billing Information</Heading>
          <SubHeading>
            Please, be sure you will charge from this card.
          </SubHeading>

          {clientSecret ? (
            <Elements
              options={{
                clientSecret,
                appearance: {
                  theme: "stripe",
                },
              }}
              stripe={stripePromise}
            >
              <PaymentUpdateForm />
            </Elements>
          ) : (
            <LoadingContainer>
              <Spinner color="grey" height={50} width={50} />
            </LoadingContainer>
          )}
        </ContentContainer>
      </PageRightCol>
    </PageContainer>
  );
};
export default UpdatePayment;
