import React, { useState, useEffect } from "react";

import styled from "styled-components";

const ItemContainer = styled.div`
  width: 300px;
  min-height: 62px;
  border: 2px solid #6371de;
  margin-bottom: 10px;
`;

const ItemTitle = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #6371de;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const BusinessTitle = styled.div`
  width: 100%;
  margin: 0 10px 5px;
  font-size: 12px;
  font-weight: bold;
`;

const PodItem = ({ pod }) => {
  const [podBusinesses, setPodBusinesses] = useState();

  useEffect(() => {
    if (!pod) return;

    setPodBusinesses(pod.assigned.businesses);
  }, [pod]);

  return (
    <ItemContainer>
      <ItemTitle>{pod.title}</ItemTitle>
      {podBusinesses &&
        podBusinesses.map((bus) => {
          return <BusinessTitle key={bus.id}>{bus.title}</BusinessTitle>;
        })}
    </ItemContainer>
  );
};

export default PodItem;
