import React, { createContext, useContext, useState } from "react";

const SubFormViewStateContext = createContext(undefined);
const SubFormViewDispatchContext = createContext(undefined);

const SubFormViewContextProvider = ({ children }) => {
  const [subFormView, setSubFormView] = useState('subscriptionDetail');

  return (
    <SubFormViewStateContext.Provider value={subFormView}>
      <SubFormViewDispatchContext.Provider value={setSubFormView}>
        {children}
      </SubFormViewDispatchContext.Provider>
    </SubFormViewStateContext.Provider>
  );
};

export default SubFormViewContextProvider;

export const useSubFormViewContext = () => {
  const subFormView = useContext(SubFormViewStateContext);
  const setSubFormView = useContext(SubFormViewDispatchContext);

  if (subFormView === undefined || setSubFormView === undefined)
    throw new Error("useSubFormViewContext must be used within the SubFormViewContextProvider");

  return { subFormView, setSubFormView };
};
