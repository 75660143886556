import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useAuthContext } from "../../context/auth-context";
import { storage, setDoc, doc, usersCollection } from "../../firebase";
import { googleFontsNames } from "../../helper";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "@firebase/storage";
import { HiPlus } from "react-icons/hi";

const MainContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const SecRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const SecCol = styled.div`
  text-align: left;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const FontNameBox = styled.div`
  display: block;
  width: 100%;
  height: 35px;
  padding: 7px 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const SelectInput = styled.select`
  display: block;
  width: 100%;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
  color: #3b3d50;
  cursor: pointer;
`;

const FontFileInput = styled.input`
  display: none;
`;

const FontFileLable = styled.label``;

const LogoFileInput = styled.input`
  display: none;
`;

const LogoFileLable = styled.label`
  display: block;
  margin-top: 15px;
  width: 150px;
  height: 150px;
  position: relative;
`;

const ImgOverlay = styled.div`
  width: 150px;
  height: 150px;
  position: absolute;
  background-color: black;
  opacity: 0.7;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 2px gray;
`;

const OverlayIcon = styled.div`
  font-size: 4rem;
  color: white;
  transform: translateY(3px);
`;

const LogoImage = styled.img`
  width: 150px;
  height: 150px;
  cursor: pointer;
`;

const TextBox = styled.textarea`
  display: block;
  width: 100%;
  height: 140px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e8f2;
  box-sizing: border-box;
  border-radius: 5px;
`;

const UpdateBtn = styled.div`
  background-color: #e6e8f2;
  width: 100%;
  height: 34px;
  font-size: 16px;
  line-height: 18px;
  color: #3b3d50;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;

  @media (max-width: 990px) {
    margin-top: 0px;
  }
`;

const MissionVision = ({setViewUpdateModal}) => {
  const { user } = useAuthContext();

  const [progress, setProgress] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [mission, setMission] = useState("");

  const [fonts, setFonts] = useState({
    titlefont: {
      fontName: "",
      fontUrl: "",
    },
    bodyfont: {
      fontName: "",
      fontUrl: "",
    },
  });

  const [colorCode, setColorCode] = useState("");

  const [currentFont, setCurrentFont] = useState({
    titlefont: {
      fontName: "",
      fontUrl: "",
    },
    bodyfont: {
      fontName: "",
      fontUrl: "",
    },
  });

  const [currentBranding, setCurrentBranding] = useState({
    brandingName: "-",
    brandingUrl: "",
  });

  const [currentLogo, setCurrentLogo] = useState(
    "https://firebasestorage.googleapis.com/v0/b/contentdrive-6147b.appspot.com/o/images%2Fno-image.png?alt=media&token=6d67d7c9-d3d9-45cb-9da7-98ee9f47f4f5"
  );

  // Handle Mission

  const handleMissionChange = (e) => {
    setMission(e.target.value);
  };

  const handleMissionSubmit = async () => {
    const userRef = doc(usersCollection, user.id);
    await setDoc(
      userRef,
      {
        branding: {
          mission: mission,
        },
      },
      { merge: true }
    );
    setViewUpdateModal(true)
  };

  // handle Color Code

  const handleColorChange = (e) => {
    setColorCode(e.target.value);
  };

  const handleColorUpdate = async (item) => {
    const userRef = doc(usersCollection, user.id);

    await setDoc(
      userRef,
      {
        branding: {
          colorCode,
        },
      },
      { merge: true }
    );
    setViewUpdateModal(true)
  };

  // Handle Fonts

  const handleFontChange = (e) => {
    const name = e.target.name;

    setFonts({
      ...fonts,
      [name]: {
        fontName: e.target.value,
      },
    });
  };

  const handleFontUpdate = async (item) => {
    const userRef = doc(usersCollection, user.id);

    if (
      user.branding.fonts &&
      user.branding.fonts[item] &&
      user.branding.fonts[item].fontUrl
    ) {
      const oldFileRef = ref(
        storage,
        `fonts/${user.branding.fonts[item].fontName}`
      );

      // Delete the file
      deleteObject(oldFileRef)
        .then(async () => {
          await setDoc(
            userRef,
            {
              branding: {
                fonts: {
                  [item]: {
                    fontName: fonts[item].fontName,
                    fontUrl: "",
                  },
                },
              },
            },
            { merge: true }
          );
          setViewUpdateModal(true)
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    } else {
      await setDoc(
        userRef,
        {
          branding: {
            fonts: {
              [item]: {
                fontName: fonts[item].fontName,
                fontUrl: "",
              },
            },
          },
        },
        { merge: true }
      );
      setViewUpdateModal(true)
    }
  };

  // Handle Logo

  const handleImageChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];

    if (!file) return;

    uploadImage(file, name);
  };

  const uploadImage = (file, name) => {
    if (!file || !user) return;

    const filename =
      file.name.split(".")[0] +
      "-" +
      new Date().getTime() +
      "." +
      file.name.split(".")[1];

    const storageRef = ref(storage, `/logos/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    if (!user.branding.logo) {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgress(prog);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            setProgress(0);
            document.querySelector(`#${name}`).value = "";

            // add URL to the branding

            const userRef = doc(usersCollection, user.id);

            await setDoc(
              userRef,
              {
                branding: {
                  logo: {
                    logoUrl: url,
                    logoName: filename,
                  },
                },
              },
              { merge: true }
            );
            setViewUpdateModal(true)
          });
        }
      );
    } else {
      // Create a reference to the file to delete
      const oldImageRef = ref(storage, `logos/${user.branding.logo.logoName}`);

      // Delete the file
      deleteObject(oldImageRef)
        .then(() => {
          // File deleted successfully
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              setProgress(prog);
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                setProgress(0);
                document.querySelector(`#${name}`).value = "";

                // add URL to the user

                const userRef = doc(usersCollection, user.id);
                await setDoc(
                  userRef,
                  {
                    branding: {
                      logo: {
                        logoUrl: url,
                        logoName: filename,
                      },
                    },
                  },
                  { merge: true }
                );
                setViewUpdateModal(true)
              });
            }
          );
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    }
  };

  // Handle Branding

  const handleBrandingChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];

    if (!file) return;

    uploadBranding(file, name);
  };

  const uploadBranding = (file, name) => {
    if (!file || !user) return;

    const filename =
      file.name.split(".")[0] +
      "-" +
      new Date().getTime() +
      "." +
      file.name.split(".")[1];

    const storageRef = ref(storage, `/branding/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    if (!user.branding.branding) {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgress(prog);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            setProgress(0);
            document.querySelector(`#${name}`).value = "";

            // add URL to the branding

            const userRef = doc(usersCollection, user.id);

            await setDoc(
              userRef,
              {
                branding: {
                  branding: {
                    brandingUrl: url,
                    brandingName: filename,
                  },
                },
              },
              { merge: true }
            );
            setViewUpdateModal(true)
          });
        }
      );
    } else {
      // Create a reference to the file to delete
      const oldFileRef = ref(
        storage,
        `branding/${user.branding.branding.brandingName}`
      );

      // Delete the file
      deleteObject(oldFileRef)
        .then(() => {
          // File deleted successfully
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              setProgress(prog);
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                setProgress(0);
                document.querySelector(`#${name}`).value = "";

                // add URL to the user

                const userRef = doc(usersCollection, user.id);
                await setDoc(
                  userRef,
                  {
                    branding: {
                      branding: {
                        brandingUrl: url,
                        brandingName: filename,
                      },
                    },
                  },
                  { merge: true }
                );
                setViewUpdateModal(true)
              });
            }
          );
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    }
  };

  // Handle Font File

  const handleFontFileChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];

    if (!file || file.type !== "font/ttf") return;

    uploadFontFile(file, name);
  };

  const uploadFontFile = (file, name) => {
    if (!file || !user) return;

    const filename =
      file.name.split(".")[0] +
      "-" +
      new Date().getTime() +
      "." +
      file.name.split(".")[1];

    const fontItem = name === "titlefontfile" ? "titlefont" : "bodyfont";

    const storageRef = ref(storage, `/fonts/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    if (
      !user.branding.fonts ||
      !user.branding.fonts[fontItem] ||
      (user.branding.fonts[fontItem] && !user.branding.fonts[fontItem].fontUrl)
    ) {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgress(prog);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            setProgress(0);
            document.querySelector(`#${name}`).value = "";

            // add URL to the fonts

            const userRef = doc(usersCollection, user.id);

            await setDoc(
              userRef,
              {
                branding: {
                  fonts: {
                    [fontItem]: {
                      fontName: filename,
                      fontUrl: url,
                    },
                  },
                },
              },
              { merge: true }
            );
            setViewUpdateModal(true)
          });
        }
      );
    } else {
      // Create a reference to the file to delete
      const oldFileRef = ref(
        storage,
        `fonts/${user.branding.fonts[fontItem].fontName}`
      );

      // Delete the file
      deleteObject(oldFileRef)
        .then(() => {
          // File deleted successfully
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              setProgress(prog);
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                setProgress(0);
                document.querySelector(`#${name}`).value = "";

                // add URL to the user

                const userRef = doc(usersCollection, user.id);
                await setDoc(
                  userRef,
                  {
                    branding: {
                      fonts: {
                        [fontItem]: {
                          fontName: filename,
                          fontUrl: url,
                        },
                      },
                    },
                  },
                  { merge: true }
                );
                setViewUpdateModal(true)
              });
            }
          );
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (!user || !user.branding || !user.branding.branding) return;

    setCurrentBranding({
      brandingName: user.branding.branding.brandingName,
      brandingUrl: user.branding.branding.brandingUrl,
    });
  }, [user]);

  useEffect(() => {
    if (!user || !user.branding || !user.branding.fonts) return;

    let tempFontsObj = {
      titlefont: {
        fontName: "-",
        fontUrl: "",
      },
      bodyfont: {
        fontName: "-",
        fontUrl: "",
      },
    };

    if (user.branding.fonts.titlefont) {
      if (user.branding.fonts.titlefont.fontName) {
        tempFontsObj.titlefont.fontName =
          user.branding.fonts.titlefont.fontName;
      }

      if (user.branding.fonts.titlefont.fontUrl) {
        tempFontsObj.titlefont.fontUrl = user.branding.fonts.titlefont.fontUrl;
      }
    }

    if (user.branding.fonts.bodyfont) {
      if (user.branding.fonts.bodyfont.fontName) {
        tempFontsObj.bodyfont.fontName = user.branding.fonts.bodyfont.fontName;
      }

      if (user.branding.fonts.bodyfont.fontUrl) {
        tempFontsObj.bodyfont.fontUrl = user.branding.fonts.bodyfont.fontUrl;
      }
    }

    setCurrentFont(tempFontsObj);
  }, [user]);

  useEffect(() => {
    if (!user || !user.branding || !user.branding.colorCode) return;

    setColorCode(user.branding.colorCode);
  }, [user]);

  useEffect(() => {
    if (!user || !user.branding || !user.branding.mission) return;

    setMission(user.branding.mission);
  }, [user]);

  useEffect(() => {
    if (!user || !user.branding || !user.branding.logo) return;

    setCurrentLogo(user.branding.logo.logoUrl);
  }, [user]);

  return (
    <MainContainer>
      <Label htmlFor="companymission">Company Mission/Vision</Label>
      <TextBox
        id="companymission"
        name="companymission"
        placeholder="Type Company Mission/Vision"
        value={mission}
        onChange={handleMissionChange}
      />
      <UpdateBtn onClick={handleMissionSubmit}>Update</UpdateBtn>

      <SecRow>
        <SecCol>
          <Label htmlFor="titlefont">Title Font</Label>
          <FontNameBox>{currentFont.titlefont.fontName}</FontNameBox>
          <SelectInput
            id="titlefont"
            name="titlefont"
            value={fonts.titlefont.fontName}
            onChange={handleFontChange}
          >
            <option value="" selected="selected" disabled="disabled">
              Google Fonts
            </option>

            {googleFontsNames.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </SelectInput>

          <UpdateBtn onClick={() => handleFontUpdate("titlefont")}>
            Update
          </UpdateBtn>

          <FontFileInput
            type="file"
            id="titlefontfile"
            name="titlefontfile"
            onChange={handleFontFileChange}
          />
          <FontFileLable htmlFor="titlefontfile">
            <UpdateBtn>Upload (*.ttf)</UpdateBtn>
          </FontFileLable>
        </SecCol>
        <SecCol>
          <Label htmlFor="bodyfont">Body Font</Label>
          <FontNameBox>{currentFont.bodyfont.fontName}</FontNameBox>
          <SelectInput
            id="bodyfont"
            name="bodyfont"
            value={fonts.bodyfont.fontName}
            onChange={handleFontChange}
          >
            <option value="" selected="selected" disabled="disabled">
              Google Fonts
            </option>

            {googleFontsNames.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </SelectInput>

          <UpdateBtn onClick={() => handleFontUpdate("bodyfont")}>
            Update
          </UpdateBtn>

          <FontFileInput
            type="file"
            id="bodyfontfile"
            name="bodyfontfile"
            onChange={handleFontFileChange}
          />
          <FontFileLable htmlFor="bodyfontfile">
            <UpdateBtn>Upload (*.ttf)</UpdateBtn>
          </FontFileLable>
        </SecCol>
      </SecRow>

      <SecRow>
        <SecCol>
          <Label htmlFor="colorcode">Company Color Codes</Label>
          <Input
            id="colorcode"
            name="colorcode"
            placeholder="Type Color Code"
            value={colorCode}
            onChange={handleColorChange}
          />

          <UpdateBtn onClick={handleColorUpdate}>Update</UpdateBtn>
        </SecCol>

        <SecCol>
          <Label>Branding Book</Label>
          <FontNameBox>{currentBranding.brandingName}</FontNameBox>
          <FontFileInput
            onChange={handleBrandingChange}
            type="file"
            id="brandingfile"
            name="brandingfile"
          />
          <FontFileLable htmlFor="brandingfile">
            <UpdateBtn>Upload (*.pdf)</UpdateBtn>
          </FontFileLable>
        </SecCol>
      </SecRow>

      <SecRow>
        <SecCol>
          <Label>Logo</Label>

          <LogoFileInput
            type="file"
            id="logofile"
            name="logofile"
            onChange={handleImageChange}
          />
          <LogoFileLable
            htmlFor="logofile"
            onMouseEnter={() => setOverlay(true)}
            onMouseLeave={() => setOverlay(false)}
          >
            <LogoImage src={currentLogo} />
            {overlay ? (
              <ImgOverlay>
                <OverlayIcon>
                  <HiPlus />
                </OverlayIcon>
              </ImgOverlay>
            ) : undefined}
          </LogoFileLable>
        </SecCol>
      </SecRow>
    </MainContainer>
  );
};

export default MissionVision;
