import React, { createContext, useContext, useState } from "react";

const LeadStateContext = createContext(undefined);
const LeadDispatchContext = createContext(undefined);

const LeadContextProvider = ({ children }) => {
  const [lead, setLead] = useState(null);

  return (
    <LeadStateContext.Provider value={lead}>
      <LeadDispatchContext.Provider value={setLead}>
        {children}
      </LeadDispatchContext.Provider>
    </LeadStateContext.Provider>
  );
};

export default LeadContextProvider;

export const useLeadContext = () => {
  const lead = useContext(LeadStateContext);
  const setLead = useContext(LeadDispatchContext);

  if (lead === undefined || setLead === undefined)
    throw new Error(
      "useLeadContext must be used within the LeadContextProvider"
    );

  return { lead, setLead };
};
